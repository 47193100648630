import React, { useState, useEffect, MouseEvent, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { Row, Col } from 'uiComponents/flex'
import { SingleSelect, RichTextInput, TextInput, NumberInput, SingleSelectOption } from 'uiComponents/input'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import Infotip from 'uiComponents/infotip'
import { MessageKind } from 'uiComponents/messages'
import { InfoCard } from 'uiComponents/cards'
import { Tabs } from 'uiComponents/navigation/tabs'
import { RegularText, Bold, SecondaryText, Body } from 'uiComponents/typography'
import { UploadInput } from 'uiComponents/input/upload'
import { ImageUploadResponse } from 'http/imagesService'
import {
    Locale,
    ArticleCrudData,
    ArticleBusinessType,
    CMS_KEY_PLACEHOLDER,
    ProductDisplayType,
} from 'admin/articleService'
import { PricingType } from 'products/articleConfigurationService'
import { DeleteNote, UploadExplanation, ImageUrlCol, ImgLink, LEFT_SIDE_SPAN, RIGHT_SIDE_SPAN } from './common'
import Feature from 'features/feature'
import { CategoriesSelection } from './categoriesSelection'
import { CopyIcon } from 'uiComponents/buttons/copyIcon'
import { FormTogglerCol, FormToggler } from 'uiComponents/input/toggle'
import {
    SectionTitle,
    PreviewBox,
    PreviewText,
    PreviewNumber,
    RedCross,
    Link,
    MinMaxCol,
    getPricingTypeOptions,
    getBusinessTypeOptions,
    displayTypeOptions,
    PreviewDescription,
    extractContentFromHTML,
} from './utils'
import { ComboboxWithParentStyling } from '../../uiComponents/input/combobox'
import { TaxConfiguration } from 'settings/accountSettings/taxConfigurations/types'
import { TaxSelect } from '../../settings/accountSettings/taxConfigurations/taxSelect'

interface GeneralInfoProps {
    accountSlug: string
    updateData: (productData: ArticleCrudData) => void
    productData: ArticleCrudData
    locales: Locale[]
    activeLocaleIndex: number
    setActiveLocaleIndex: (index: number) => void
    localesWithMissingName: string[]
    localesWithMissingDescription: string[]
    showBookingLimitsError: boolean
    missingBusinessType: boolean
    resetMissingBusinessType: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    onWideImageUpload: (file: File) => Promise<any>
    onSquareImageUpload: (file: File) => Promise<any>
    onWideImageUploadSuccess: (response: ImageUploadResponse) => void
    onSquareImageUploadSuccess: (response: ImageUploadResponse) => void
    viewMode: 'flat' | 'nested' | ''
    dateChangeFeatureEnabled: boolean
    membershipsFeatureEnabled: boolean
    productTreeOptions: SingleSelectOption[]
    missingDateChangeProducts: boolean
    minMaxTicketsError: boolean
    loadingTaxConfigurations: boolean
    taxConfigurations: TaxConfiguration[] | null
    defaultTaxConfigurationUuid: string | null
    onExternalNavigate?: (event: MouseEvent, destination: string) => void
}

function GeneralInfo(props: GeneralInfoProps) {
    const {
        accountSlug,
        updateData,
        productData,
        locales,
        activeLocaleIndex,
        setActiveLocaleIndex,
        localesWithMissingName,
        localesWithMissingDescription,
        showBookingLimitsError,
        missingBusinessType,
        resetMissingBusinessType,
        replaceMessages,
        removeAllMessages,
        onWideImageUpload,
        onSquareImageUpload,
        onWideImageUploadSuccess,
        onSquareImageUploadSuccess,
        dateChangeFeatureEnabled,
        membershipsFeatureEnabled,
        productTreeOptions,
        missingDateChangeProducts,
        minMaxTicketsError,
        loadingTaxConfigurations,
        taxConfigurations,
        defaultTaxConfigurationUuid,
        onExternalNavigate,
    } = props

    const [editMode, setEditMode] = useState<boolean>(false)
    const [descriptionInvalidAmountOfCharsError, setDescriptionInvalidAmountOfCharsError] = useState<boolean>(false)

    const name = productData.name?.text[locales[activeLocaleIndex].code] || ''
    const description = productData.description?.text[locales[activeLocaleIndex].code] || ''
    const canModifyPricingType = productData.articleValidationErrors?.length
        ? productData.articleValidationErrors.includes('pricing_settings_not_configured')
        : true

    const newData = useMemo(() => cloneDeep(productData), [productData])

    useEffect(() => {
        resetMissingBusinessType()
        if (!membershipsFeatureEnabled && productData.businessType === 'membership') {
            newData.businessType = null
            newData.pricingType = 'rtp'
            updateData(newData)
        }
    }, [productData])

    const updatePreviewDescription = (description: string) => {
        const element = document.getElementById('product-description-preview')
        if (!!element) {
            element.innerHTML = description || 'Add product description...'
        }
    }

    function onCategoriesChange(ids: string[]) {
        newData.productsListIds = ids
        updateData(newData)
        setEditMode(false)
    }

    function onNameChange(event: React.ChangeEvent<HTMLInputElement>, locale: Locale) {
        newData.name.text[locale.code] = event.target.value
        updateData(newData)
    }

    function onDescriptionChange(value: string, locale: Locale) {
        setDescriptionInvalidAmountOfCharsError(false)
        if (newData.imageType === 'square_image') {
            setDescriptionInvalidAmountOfCharsError(extractContentFromHTML(value).length >= 120)
        }

        if (newData.description) {
            newData.description.text[locale.code] = value
        } else {
            newData.description = {
                key: CMS_KEY_PLACEHOLDER,
                text: {
                    [locale.code]: value,
                },
            }
        }

        updatePreviewDescription(value)
    }

    function onPricingTypeChange(value: PricingType) {
        newData.pricingType = value
        updateData(newData)
    }

    function onBusinessTypeChange(value: ArticleBusinessType) {
        if (
            dateChangeFeatureEnabled &&
            productData.businessType === 'service_date_change' &&
            value !== productData.businessType &&
            !!productData.dateChangedArticles.length
        ) {
            replaceMessages(
                'type_error',
                'error',
                'Please remove all attached date change products before changing the product type.',
            )
            return
        }
        newData.businessType = value
        if (value === 'membership') {
            newData.pricingType = 'payment_plan'
        } else {
            newData.pricingType = 'rtp'
        }
        updateData(newData)
        resetMissingBusinessType()
    }

    function onDateChangeProductsChange(value: string[]) {
        removeAllMessages()
        newData.dateChangedArticles = value
        updateData(newData)
    }

    function onDisplayTypeChange(value: ProductDisplayType) {
        newData.displayType = value
        updateData(newData)
    }

    function onPriorityChange(event: React.ChangeEvent<HTMLInputElement>) {
        newData.priority = event.target.value === '' ? null : +event.target.value
        updateData(newData)
    }

    function onPriorityBlur() {
        if (productData.priority !== null) {
            return
        }
        newData.priority = 1
        updateData(newData)
    }

    function onTaxConfigurationChange(value: string | null) {
        newData.taxConfigurationUuid = value
        updateData(newData)
    }

    function onRedeemableChange(value: boolean) {
        newData.redeemable = value
        updateData(newData)
    }

    function onAPFEnabledChange(value: boolean) {
        newData.apfEnabled = value
        updateData(newData)
    }

    function removeWideArticleImage() {
        newData.image = null
        updateData(newData)
    }

    function removeSquareArticleImage() {
        newData.squareImage = null
        updateData(newData)
    }

    function onActiveToggle(value: boolean) {
        newData.enabled = value
        updateData(newData)
    }

    function onBookingLimitsChange(
        event: React.ChangeEvent<HTMLInputElement>,
        field: 'bookingHorizon' | 'releaseTime',
    ) {
        newData[field] = event.target.value === '' ? null : +event.target.value
        updateData(newData)
    }

    function onCardOpenChange(value: boolean) {
        newData.cardOpenByDefault = value
        updateData(newData)
    }

    function onMinChange(value: string) {
        newData.minTickets = value === '' ? null : +value
        updateData(newData)
    }

    function onMaxChange(value: string) {
        newData.maxTickets = value === '' ? null : +value
        updateData(newData)
    }

    const businessTypeOptions = getBusinessTypeOptions(dateChangeFeatureEnabled, membershipsFeatureEnabled)
    const pricingTypeOptions = getPricingTypeOptions(productData.businessType)
    const pricingIsSet =
        !productData.articleValidationErrors?.includes('pricing_settings_not_configured') &&
        !productData.articleValidationErrors?.includes('ouroboros_payment_plans_not_configured')
    const validityIsSet = !productData.articleValidationErrors?.includes('validity_not_configured')
    const articleMustBeSingleUse = productData.articleValidationErrors?.includes('article_must_be_single_use')

    const getDisabledButtonInfotipText = () => {
        if (!pricingIsSet && !validityIsSet) {
            return 'You need to configure pricing and validity settings before publishing.'
        } else if (!pricingIsSet) {
            return 'You need to configure pricing settings before publishing.'
        } else if (!validityIsSet) {
            return 'You need to configure validity settings before publishing.'
        } else if (articleMustBeSingleUse) {
            return 'Scan limit must be set to "Once" if group scanning is enabled for the parent category.'
        }
        return ''
    }

    return (
        <>
            <Row style={{ marginBottom: '1rem' }}>
                <FormTogglerCol span={LEFT_SIDE_SPAN}>
                    <FormItemName>Publish or unpublish the product</FormItemName>
                    <DisabledItemHoverInfotip
                        active={!productData.canBeEnabled}
                        infotipText={getDisabledButtonInfotipText()}
                        width="22em"
                    >
                        <FormToggler
                            id="enabled"
                            isOn={productData.enabled}
                            onClick={onActiveToggle}
                            disabled={!productData.canBeEnabled}
                        />
                    </DisabledItemHoverInfotip>
                </FormTogglerCol>
                <Col span={RIGHT_SIDE_SPAN}>
                    {!!productData.multipleProductLists && (
                        <div style={{ width: '20rem' }}>
                            <InfoCard
                                active
                                small
                                type="info"
                                cardHeader="Product belongs to multiple categories"
                                cardText="Enabling / disabling this product will affect multiple categories."
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col span={LEFT_SIDE_SPAN}>
                    <FormItemName>
                        Internal ID:
                        <strong style={{ marginLeft: '1rem' }}>{productData.numericId}</strong>
                        <CopyIcon text={productData.numericId} style={{ marginLeft: '1rem' }} />
                    </FormItemName>
                </Col>
                <Col span={RIGHT_SIDE_SPAN} />
            </Row>
            <Row style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                <Col span={LEFT_SIDE_SPAN}>
                    <CategoriesSelection
                        open={!!editMode}
                        accountSlug={accountSlug}
                        onOutsideClick={() => setEditMode(false)}
                        setProductCategories={onCategoriesChange}
                        selectedCategories={productData.productsListIds || []}
                    />
                </Col>
                <Col span={RIGHT_SIDE_SPAN} />
            </Row>
            <Row style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                <Col span={LEFT_SIDE_SPAN}>
                    <SectionTitle>Translations</SectionTitle>
                    <Tabs
                        titles={locales.map((locale) => locale.name)}
                        setActive={setActiveLocaleIndex}
                        activeTabIndex={activeLocaleIndex}
                    >
                        {locales.map((locale, index) => {
                            return (
                                <div key={index}>
                                    <FormItem htmlFor="product-name" style={{ position: 'relative', marginBottom: 24 }}>
                                        <FormItemName>Product name</FormItemName>
                                        <TextInput
                                            id={`product-name-${[locale.code]}`}
                                            name="product-name"
                                            type="text"
                                            maxLength={60}
                                            value={newData.name?.text[locale.code] || ''}
                                            status={localesWithMissingName.includes(locale.code) ? 'error' : 'normal'}
                                            placeholder="Add product name..."
                                            onChange={(event) => onNameChange(event, locale)}
                                            onBlur={() => updateData(newData)}
                                            locale={locale.code}
                                            block
                                        />
                                    </FormItem>
                                    {localesWithMissingName.includes(locale.code) && (
                                        <ValidationMessage
                                            className="validation-message-visible"
                                            style={{ top: '-1.8em' }}
                                        >
                                            This field is required
                                        </ValidationMessage>
                                    )}
                                    <FormItem htmlFor="product-description" style={{ position: 'relative' }}>
                                        <FormItemName>
                                            Product description (optional)
                                            <Infotip pointer="left" maxWidth="25em">
                                                This is the description of the product that will appear in the checkout.
                                                Keep it short to prevent that your list of products becomes too long.
                                            </Infotip>
                                        </FormItemName>
                                        <RichTextInput
                                            id={`product-description-${[locale.code]}`}
                                            onChange={(value) => onDescriptionChange(value, locale)}
                                            onBlur={() => updateData(newData)}
                                            value={newData.description?.text[locale.code] || ''}
                                            maxLength={300}
                                            showCharsLeft
                                            status={
                                                localesWithMissingDescription.includes(locale.code)
                                                    ? 'error'
                                                    : descriptionInvalidAmountOfCharsError
                                                    ? 'warning'
                                                    : 'normal'
                                            }
                                            withColor
                                            withLink
                                        />
                                    </FormItem>
                                    {localesWithMissingDescription.includes(locale.code) && (
                                        <ValidationMessage
                                            className="validation-message-visible"
                                            style={{ top: '-1.8em' }}
                                        >
                                            All translations of the description are required.
                                        </ValidationMessage>
                                    )}
                                    {descriptionInvalidAmountOfCharsError && (
                                        <ValidationMessage
                                            status="processing"
                                            className="validation-message-visible"
                                            style={{ top: '-1.8em' }}
                                        >
                                            Max 120 characters for square images exceeded. Text beyond this limit may be
                                            hidden at checkout.
                                        </ValidationMessage>
                                    )}
                                </div>
                            )
                        })}
                    </Tabs>
                </Col>
                <Col span={RIGHT_SIDE_SPAN}>
                    <PreviewBox>
                        <Row>
                            <Col span={9}>
                                <PreviewText>
                                    <RegularText>
                                        <Bold>{!name ? 'Add product name...' : name}</Bold>
                                    </RegularText>
                                </PreviewText>
                            </Col>
                            <Col span={3}>
                                <PreviewNumber>
                                    <span>
                                        0<RedCross>+</RedCross>
                                    </span>
                                </PreviewNumber>
                            </Col>
                        </Row>
                        {!!productData.image && (
                            <div>
                                <img src={productData.image} width="100%" />
                            </div>
                        )}
                        <PreviewDescription
                            id="product-description-preview"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </PreviewBox>
                </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
                <Col span={LEFT_SIDE_SPAN}>
                    <FormItem htmlFor="product-business-type" style={{ position: 'relative' }}>
                        <FormItemName>Product type</FormItemName>
                        <SingleSelect
                            id="product-business-type"
                            options={businessTypeOptions}
                            selected={productData.businessType}
                            height="2.5rem"
                            noSelectOption="No type selected"
                            onSelect={onBusinessTypeChange}
                            style={{ marginBottom: '0.5rem' }}
                            status={missingBusinessType ? 'error' : 'normal'}
                        />
                        {missingBusinessType && (
                            <ValidationMessage className="validation-message-visible">
                                Product type is required
                            </ValidationMessage>
                        )}
                    </FormItem>
                </Col>
                <Col />
            </Row>
            <Feature name="DateChangeProductCRUDUpdate" accountSlug={accountSlug}>
                {productData.businessType === 'service_date_change' && (
                    <Row style={{ marginBottom: '1rem' }}>
                        <Col span={LEFT_SIDE_SPAN}>
                            <ComboboxWithParentStyling
                                name="date-change-products"
                                label={
                                    <>
                                        Date change products
                                        <Infotip pointer="left" maxWidth="20em">
                                            The products that are assigned to this Date change item.
                                        </Infotip>
                                    </>
                                }
                                nested
                                multiple
                                value={productData.dateChangedArticles}
                                onChange={onDateChangeProductsChange}
                                id="date-change-products"
                                options={productTreeOptions}
                                placeholder="Select products"
                                status={
                                    missingDateChangeProducts && !productData.dateChangedArticles.length
                                        ? 'error'
                                        : 'normal'
                                }
                                error={
                                    missingDateChangeProducts && !productData.dateChangedArticles.length
                                        ? 'Please select date change products'
                                        : undefined
                                }
                            />
                        </Col>
                        <Col />
                    </Row>
                )}
            </Feature>
            <Row style={{ marginBottom: '1rem' }}>
                <Col span={LEFT_SIDE_SPAN}>
                    <FormItem htmlFor="display" style={{ position: 'relative' }}>
                        <FormItemName>
                            Product display type for TAP
                            <Infotip pointer="left" maxWidth="20em">
                                This property determines how TAP application shows products.
                            </Infotip>
                        </FormItemName>
                        <SingleSelect
                            id="product-display-type"
                            options={displayTypeOptions}
                            selected={productData.displayType}
                            height="2.5rem"
                            noSelectOption="Select display type"
                            onSelect={onDisplayTypeChange}
                        />
                    </FormItem>
                </Col>
                <Col />
            </Row>
            {productData.reservedArticleName && productData.reservedArticleId && (
                <Row style={{ marginBottom: '1rem' }}>
                    <Col span={12}>
                        <Link>
                            Reservation product:&nbsp;
                            <a href={`/account/${accountSlug}/products/crud/${productData.reservedArticleId}`}>
                                {productData.reservedArticleName}
                            </a>
                        </Link>
                    </Col>
                </Row>
            )}
            <Row style={{ marginBottom: '1rem' }}>
                <Col span={LEFT_SIDE_SPAN}>
                    <FormItem htmlFor="product-pricing-type" style={{ position: 'relative' }}>
                        <FormItemName>Product pricing type</FormItemName>
                        <SingleSelect
                            id="product-pricing-type"
                            options={pricingTypeOptions}
                            selected={productData.pricingType}
                            height="2.5rem"
                            disabled={!canModifyPricingType}
                            noSelectOption="Select pricing type"
                            onSelect={onPricingTypeChange}
                        />
                    </FormItem>
                </Col>
                <Col span={RIGHT_SIDE_SPAN}>
                    <FormItem htmlFor="product-priority" style={{ position: 'relative' }}>
                        <FormItemName>
                            Product priority
                            <Infotip pointer="left" maxWidth="25em">
                                This number determines the order of products in the checkout. The product with the
                                lowest number will be displayed on top.
                            </Infotip>
                        </FormItemName>
                        <NumberInput
                            id="product-priority"
                            integerOnly
                            value={productData.priority === null ? '' : productData.priority}
                            min={1}
                            max={1000}
                            placeholder="1"
                            onChange={onPriorityChange}
                            onBlur={onPriorityBlur}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row style={{ marginBottom: '1.5rem' }}>
                <Col span={LEFT_SIDE_SPAN}>
                    <TaxSelect
                        title="Product tax"
                        taxConfigurationUuid={productData.taxConfigurationUuid || null}
                        onTaxConfigurationChange={onTaxConfigurationChange}
                        accountSlug={accountSlug}
                        loadingTaxConfigurations={loadingTaxConfigurations}
                        taxConfigurations={taxConfigurations}
                        defaultTaxConfigurationUuid={defaultTaxConfigurationUuid}
                        onExternalNavigate={onExternalNavigate}
                    />
                </Col>
                <Col />
            </Row>
            <Row style={{ marginBottom: '1.5rem', position: 'relative', zIndex: 1 }}>
                <Col span={LEFT_SIDE_SPAN}>
                    <Row>
                        <Col span={6}>
                            <FormItem htmlFor="product-booking-horizon">
                                <FormItemName>
                                    Booking horizon
                                    <Infotip pointer="left" maxWidth="28em">
                                        Number of days from today the product will be available to buy in the checkout.
                                        Use this to prevent customers booking too far in advance.
                                        <div>Leave blank to disable.</div>
                                    </Infotip>
                                </FormItemName>
                                <NumberInput
                                    id="product-booking-horizon"
                                    integerOnly
                                    value={productData.bookingHorizon === null ? '' : productData.bookingHorizon}
                                    min={1}
                                    max={1000}
                                    onChange={(event) => onBookingLimitsChange(event, 'bookingHorizon')}
                                    placeholder="-"
                                    status={showBookingLimitsError ? 'error' : 'normal'}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem htmlFor="product-release-time">
                                <FormItemName>
                                    Release time
                                    <Infotip pointer="left" maxWidth="30em">
                                        Number of days from today the product will not be available to buy in the
                                        checkout. Use this to set up a product that will be available for purchase in
                                        the future.
                                        <div>Leave blank to disable.</div>
                                    </Infotip>
                                </FormItemName>
                                <NumberInput
                                    id="product-release-time"
                                    integerOnly
                                    value={productData.releaseTime === null ? '' : productData.releaseTime}
                                    min={1}
                                    max={1000}
                                    onChange={(event) => onBookingLimitsChange(event, 'releaseTime')}
                                    placeholder="-"
                                    status={showBookingLimitsError ? 'error' : 'normal'}
                                />
                            </FormItem>
                        </Col>
                        <ValidationMessage
                            className={showBookingLimitsError ? 'validation-message-visible' : ''}
                            style={{ top: '0.5rem', marginBottom: '-2.5rem' }}
                        >
                            Booking horizon must be greater than release time, otherwise the category will never be
                            available.
                        </ValidationMessage>
                    </Row>
                </Col>
            </Row>
            <Row style={{ position: 'relative', zIndex: 1 }}>
                <Col span={LEFT_SIDE_SPAN}>
                    <FormItemName style={{ marginBottom: '1rem' }}>
                        Checkout is set to show
                        <strong>
                            &nbsp;
                            {productData.imageType === 'square_image' ? 'square' : 'wide'}
                            &nbsp;
                        </strong>
                        product images
                        <Infotip pointer="left">If you wish to change that, please contact Convious support.</Infotip>
                    </FormItemName>
                    <FormItem htmlFor="product-image" style={{ position: 'relative' }}>
                        <FormItemName>Wide product image</FormItemName>
                        <UploadInput
                            type="img"
                            fileSizeLimit={1}
                            onUpload={onWideImageUpload}
                            handleUploadSuccessResponse={onWideImageUploadSuccess}
                            inputId="product-image"
                            replaceTopMessages={replaceMessages}
                            removeAllMessages={removeAllMessages}
                        />
                        <UploadExplanation>
                            <Body size={3}>Image size should be 450x140</Body>
                        </UploadExplanation>
                    </FormItem>
                    {!!productData.image && (
                        <>
                            <FormItemName>Current wide image url:</FormItemName>
                            <Row>
                                <ImageUrlCol span={9}>
                                    <ImgLink href={productData.image} target="_blank">
                                        {productData.image}
                                    </ImgLink>
                                </ImageUrlCol>
                                <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <DeleteNote onClick={removeWideArticleImage} style={{ margin: '0' }}>
                                        <SecondaryText>x Remove</SecondaryText>
                                    </DeleteNote>
                                </Col>
                            </Row>
                        </>
                    )}
                    <FormItem htmlFor="product-square-image" style={{ position: 'relative', marginTop: '1rem' }}>
                        <FormItemName>Square product image</FormItemName>
                        <UploadInput
                            type="img"
                            fileSizeLimit={1}
                            onUpload={onSquareImageUpload}
                            handleUploadSuccessResponse={onSquareImageUploadSuccess}
                            parseErrorMessage
                            inputId="product-square-image"
                            replaceTopMessages={replaceMessages}
                            removeAllMessages={removeAllMessages}
                        />
                        <UploadExplanation>
                            <Body size={3}>Image should be square, e.g. 300x300</Body>
                        </UploadExplanation>
                    </FormItem>
                    {!!productData.squareImage && (
                        <>
                            <FormItemName>Current square image url:</FormItemName>
                            <Row>
                                <ImageUrlCol span={9}>
                                    <ImgLink href={productData.squareImage} target="_blank">
                                        {productData.squareImage}
                                    </ImgLink>
                                </ImageUrlCol>
                                <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <DeleteNote onClick={removeSquareArticleImage} style={{ margin: '0' }}>
                                        <SecondaryText>x Remove</SecondaryText>
                                    </DeleteNote>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
                <Col span={RIGHT_SIDE_SPAN} />
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <FormTogglerCol span={LEFT_SIDE_SPAN}>
                    <FormItemName>
                        Expand by default
                        <Infotip pointer="left" maxWidth="28em">
                            Expanding a product card will show the description, options, and/or image.
                        </Infotip>
                    </FormItemName>
                    <FormToggler
                        id="card-open-by-default"
                        isOn={productData.cardOpenByDefault}
                        onClick={onCardOpenChange}
                    />
                </FormTogglerCol>
                <Col span={RIGHT_SIDE_SPAN} />
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <FormTogglerCol span={LEFT_SIDE_SPAN}>
                    <FormItemName>Redeemable</FormItemName>
                    <FormToggler id="redeemable" isOn={productData.redeemable} onClick={onRedeemableChange} />
                </FormTogglerCol>
                <Col span={RIGHT_SIDE_SPAN} />
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <FormTogglerCol span={LEFT_SIDE_SPAN}>
                    <FormItemName>After Payment Form enabled</FormItemName>
                    <FormToggler id="apfEnabled" isOn={productData.apfEnabled || false} onClick={onAPFEnabledChange} />
                </FormTogglerCol>
                <Col span={RIGHT_SIDE_SPAN} />
            </Row>
            <Row>
                <Col span={LEFT_SIDE_SPAN}>
                    <FormItem htmlFor="product-minmax" style={{ position: 'relative', marginTop: '1rem' }}>
                        <FormItemName style={{ marginBottom: '.75rem' }}>
                            Tickets per customer
                            <Infotip pointer="left" maxWidth="25em">
                                <Body size={1} color="white">
                                    Limit the amount of tickets per buyer.
                                </Body>
                                <Body size={3} color="white">
                                    Default values: (Min:1; Max:10;)
                                </Body>
                            </Infotip>
                        </FormItemName>
                        <Row>
                            <MinMaxCol span={4}>
                                <Body size={2}>Minimum:</Body>
                                <NumberInput
                                    id="product-min"
                                    integerOnly
                                    value={productData.minTickets === null ? '' : productData.minTickets}
                                    min={0}
                                    max={1000}
                                    placeholder="1"
                                    onChange={(event) => onMinChange(event.target.value)}
                                    style={{ width: '4rem', marginLeft: '.5rem' }}
                                    status={
                                        minMaxTicketsError &&
                                        (!productData.minTickets ||
                                            (!!productData.maxTickets &&
                                                productData.minTickets > productData.maxTickets))
                                            ? 'error'
                                            : 'normal'
                                    }
                                />
                            </MinMaxCol>
                            <MinMaxCol span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                <Body size={2}>Maximum:</Body>
                                <NumberInput
                                    id="product-max"
                                    integerOnly
                                    value={productData.maxTickets === null ? '' : productData.maxTickets}
                                    min={0}
                                    max={1000}
                                    placeholder="10"
                                    onChange={(event) => onMaxChange(event.target.value)}
                                    style={{ width: '4rem', marginLeft: '.5rem' }}
                                    status={
                                        minMaxTicketsError &&
                                        (!productData.maxTickets ||
                                            (!!productData.minTickets &&
                                                productData.minTickets > productData.maxTickets))
                                            ? 'error'
                                            : 'normal'
                                    }
                                />
                            </MinMaxCol>
                            <ValidationMessage
                                className={
                                    minMaxTicketsError &&
                                    (!productData.minTickets ||
                                        !productData.maxTickets ||
                                        productData.minTickets > productData.maxTickets)
                                        ? 'validation-message-visible'
                                        : ''
                                }
                                style={{ top: '0.5rem', marginBottom: '-2.5rem' }}
                            >
                                Both values must be at least 1. Min value cannot be less than max.
                            </ValidationMessage>
                        </Row>
                    </FormItem>
                </Col>
                <Col span={RIGHT_SIDE_SPAN} />
            </Row>
        </>
    )
}

export default GeneralInfo
