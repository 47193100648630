import React, { FC } from 'react'
import { useLazyGetCartQuery, usePatchCartMutation } from 'orders/reduxQueries'
import Comment from 'uiComponents/comment/comment'
import { useComments } from 'uiComponents/comment/useComments'

type CartCommentProps = {
    cartId: string
}

const CartComment: FC<CartCommentProps> = ({ cartId }) => {
    const [comment, { isFetching, handleSetComment }] = useComments({
        type: 'cart',
        uuid: cartId,
        loadQuery: useLazyGetCartQuery,
        patchMutation: usePatchCartMutation,
    })

    return <Comment comment={comment} isFetching={isFetching} onHandleSetComment={handleSetComment} />
}

export default CartComment
