import { calculateOrderPartnerRefundFee, calculateRefundFee } from 'orders/utils/calculateOrderRefund'
import { OrderRefundDetails, RefundOrderItems, RefundTicket } from '../types'

export const calculateTotalRefundFee = (orders: OrderRefundDetails[], selectedBookingFeesUuids: string[]) => {
    const total = orders.reduce((sum: number, order) => {
        return (
            sum +
            calculateOrderPartnerRefundFee({ order: order }) +
            getOrderBookingFeeRefundFee(order, selectedBookingFeesUuids)
        )
    }, 0)

    return Math.round(total * 100) / 100
}

export const getTicketTotalBookingFee = (ticket: RefundTicket, selectedBookingFees: string[]) => {
    return ticket.bookingFees
        .filter((bookingFee) => selectedBookingFees.includes(bookingFee.uuid))
        .reduce((sum, bookingFee) => sum + Number(bookingFee.calculatedAmountInclTax), 0)
}

export const getOrderBookingFeesTotalAmount = (orderItem: RefundOrderItems, selectedBookingFees: string[]) => {
    return orderItem.refundTickets.reduce(
        (sum, ticket) => sum + Number(getTicketTotalBookingFee(ticket, selectedBookingFees)),
        0,
    )
}

export const getOrderBookingFeeRefundFee = (order: OrderRefundDetails, selectedBookingFeesUuids: string[]) => {
    const bookingFee = order.refundOrderItems.reduce((sum, orderItem) => {
        return sum + getOrderBookingFeesTotalAmount(orderItem, selectedBookingFeesUuids)
    }, 0)
    return calculateRefundFee(
        Math.round(bookingFee * 100) / 100,
        Number(order.partnerRefundFee),
        order.partnerRefundFeeType,
    )
}

export const getTotalRefundedAmount = (
    orders: OrderRefundDetails[],
    partnerRefundFee: boolean,
    selectedBookingFeesUuids: string[],
) => {
    const total = orders.reduce((sum: number, order) => {
        const partnerFee = partnerRefundFee
            ? calculateOrderPartnerRefundFee({ order: order }) +
              getOrderBookingFeeRefundFee(order, selectedBookingFeesUuids)
            : 0
        const bookingFee = order.refundOrderItems.reduce((sum, orderItem) => {
            return sum + getOrderBookingFeesTotalAmount(orderItem, selectedBookingFeesUuids)
        }, 0)
        return Number(order.totalDiscountedPriceInclTax) + sum - partnerFee + bookingFee
    }, 0)

    return Math.round(total * 100) / 100
}

export const filterNonRefundableOrders = (orders: OrderRefundDetails[]) => {
    return orders.filter((order) => !['partially_canceled', 'cancelled'].includes(order.status) && order.canRefund)
}

export const getSelectedBookingFees = (orders: OrderRefundDetails[]) => {
    return orders.reduce((bookingFees: string[], order) => {
        order.refundOrderItems.forEach((orderItem) => {
            orderItem.refundTickets.forEach((ticket) => {
                ticket.bookingFees.forEach((bookingFee) => {
                    if (!bookingFees.includes(bookingFee.uuid)) {
                        bookingFees.push(bookingFee.uuid)
                    }
                })
            })
        })
        return bookingFees
    }, [])
}
