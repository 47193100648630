import { CancelRefundFooterProps, CancelRefundHeaderProps, OrderRefundDetails } from '../types'
import { CancelRefundTableData, ICancelRefundTableRow } from '../components/cancelRefundTable/types'
import { RefundFeeType } from 'settings/accountSettings/contract/managementService'
import { BookingFeeRowData, getBookingFeesRowsData } from '../utils'

export const mapHeaderProps = (isCancellation: boolean, onClose: () => void): CancelRefundHeaderProps => {
    return {
        title: isCancellation ? 'Cancel orders' : 'Refund orders',
        subtitle: `You are about to ${isCancellation ? 'cancel' : 'refund'} the following orders:`,
        onClose,
    }
}

interface TableDataMapperOptions {
    orders: OrderRefundDetails[]
    selectedOrders: string[]
    totalAmount: number
    partnerRefundFeeAmount: number
    partnerRefundFeeEnabled: boolean
    partnerRefundFeeSelected: boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    partnerRefundFee?: string
    partnerRefundFeeType?: RefundFeeType | null
    bookingFeesEnabled: boolean
    selectedBookingFees: string[]
    confirmMode: boolean
}

export const prepareTableData = ({
    orders,
    selectedOrders,
    totalAmount,
    partnerRefundFeeAmount,
    partnerRefundFeeEnabled,
    partnerRefundFeeSelected,
    formatCurrencyString,
    partnerRefundFee,
    partnerRefundFeeType,
    bookingFeesEnabled,
    selectedBookingFees,
    confirmMode,
}: TableDataMapperOptions): CancelRefundTableData => {
    const sections = []
    const orderRows: ICancelRefundTableRow[] = []
    const bookingFeeRowsData: BookingFeeRowData[] = []

    for (const order of orders) {
        for (const ticket of order.refundOrderItems) {
            for (const item of ticket.refundTickets) {
                const isSelected = selectedOrders.includes(order.id)

                getBookingFeesRowsData(bookingFeeRowsData, item, isSelected, ticket.product, undefined,ticket.isBundle)
            }
        }
        const orderSelected = selectedOrders.includes(order.id)
        if (confirmMode && !orderSelected) {
            continue
        }

        orderRows.push({
            id: order.id,
            rowType: 'order',
            name: `Order ${order.number}`,
            description: `${formatCurrencyString(
                Number(order.totalDiscountedPriceExclTax).toFixed(2),
                order.account,
            )} subtotal`,
            amount: Number(order.totalDiscountedPriceInclTax),
            selected: orderSelected,
            orderItemId: '',
            disabled: !order.canRefund,
        })
    }

    const ticketsSection = {
        id: 'itemsSection',
        rows: orderRows,
        borderBottom: true,
    }

    sections.push(ticketsSection)

    if (bookingFeesEnabled && bookingFeeRowsData.length > 0) {
        const bookingFeeRows: ICancelRefundTableRow[] = []

        for (const bookingFee of bookingFeeRowsData) {
            const isBookingFeeSelected = selectedBookingFees.includes(bookingFee.id)

            if (!isBookingFeeSelected && confirmMode) {
                continue
            }

            bookingFeeRows.push({
                id: bookingFee.id,
                rowType: 'bookingFee',
                name: bookingFee.name,
                description: `${bookingFee.amountOfSelectedProducts}/${bookingFee.amountOfProducts} products selected`,
                amount: bookingFee.amount,
                selected: isBookingFeeSelected,
                orderItemId: '',
                infoTip: `* ${bookingFee.productName}`,
                disabled: bookingFee.amountOfSelectedProducts === 0,
            })
        }

        const bookingFeeSection = {
            id: 'bookingFeeSection',
            rows: bookingFeeRows,
            borderTop: true,
            borderBottom: true,
        }

        sections.push(bookingFeeSection)
    }

    if (partnerRefundFeeEnabled) {
        const refundFeeSection = {
            id: 'refundFeeSection',
            rows: [
                {
                    id: '',
                    rowType: 'refundFee',
                    name: 'Visitor refund fee',
                    description:
                        partnerRefundFeeType === 'percentage' ? `${partnerRefundFee}% on products total` : 'Flat rate',
                    amount: partnerRefundFeeAmount,
                    selected: partnerRefundFeeSelected,
                    orderItemId: '',
                    infoTip: 'To change this, go to Settings > Account.',
                    disabled: true,
                },
            ],
        }

        sections.push(refundFeeSection)
    }

    const totalAmountSection = {
        id: 'totalSection',
        rows: [
            {
                id: 'total',
                rowType: 'total',
                name: 'Total',
                description: '',
                amount: totalAmount,
                selected: false,
                orderItemId: '',
            },
        ],
    }
    sections.push(totalAmountSection)

    return {
        sections,
    }
}

interface FooterPropsMapperOptions {
    accountSlug: string
    confirmMode: boolean
    isCancellation: boolean
    selectedOrders: string[]
    refunding: boolean
    onCancel: () => void
    onNext: () => void
    totalAmount: number
    refundDisallowed: boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
}

export const mapFooterProps = ({
    accountSlug,
    confirmMode,
    isCancellation,
    selectedOrders,
    refunding,
    onCancel,
    onNext,
    totalAmount,
    refundDisallowed,
    formatCurrencyString,
}: FooterPropsMapperOptions): CancelRefundFooterProps => {
    const confirmMessage = (() => {
        if (confirmMode) {
            if (isCancellation) {
                return `You are about to cancel "${selectedOrders.length}" orders. All selected orders will be cancelled this cannot be undone!`
            }
            return `You are about to refund "${selectedOrders.length}" orders for  "${formatCurrencyString(
                Number(totalAmount).toFixed(2),
                accountSlug,
            )}". All selected orders will be refunded this cannot be undone!`
        }

        if (selectedOrders.length >= 20) {
            return 'A maximum of 20 orders can be processed at one time'
        }
        return
    })()

    const nextButtonCaption = confirmMode ? (isCancellation ? 'Cancel orders' : 'Refund orders') : 'Review'

    return {
        confirmMessage,
        cancelButtonCaption: confirmMode ? 'Back to editing' : 'Cancel',
        onCancel,
        nextButtonCaption,
        onNext,
        nextButtonDisabled: refundDisallowed || selectedOrders.length === 0 || selectedOrders.length > 20 || refunding,
    }
}
