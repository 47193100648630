import * as React from 'react'
import { State } from 'store'
import { connect } from 'react-redux'
import { Account } from 'auth/state'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Location, History } from 'history'
import TransactionsPage from 'orders/transactions'
import OrdersDetailsPage from 'orders/details'
import PaypalDisputesPage from 'orders/paypalDisputes'
import ChannelsPage from 'channels'
import { Navigation } from 'navigation'
import { dateRangeFromQuery, dateRangeToQuery, DateRange } from 'dateRanges'
import { Sorting, Pagination } from 'uiComponents/table'
import { OrdersService } from 'orders/ordersService'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { PaypalService } from 'orders/paypalService'
import { BookingCodesService } from 'venue/bookingCodes/bookingCodesService'
import InlineCheckout from 'orders/inlineCheckout'
import { defaultPageData } from './schema'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { usePermission } from 'admin/hocs'
import { User } from 'auth/state'
import { ArticleService } from 'admin/articleService'
import Orders from './orders'
import { RedeemModeDialog } from './redeemModeDialog'
import Redeem from './redeem'
import ExportOrders from './orders/export'
import { ORDER_PATHS } from './paths'
import Cart_OLD from './cart_old'
import Cart from './cart'
import EditCart from './cart/editCart'
import OrderDetails from './orders/orderDetails'
import { FeatureProps, withFeatures } from 'features'
import SentEmailsPage from 'sentEmails/sentEmailsPage'
import CapacityReportPage from 'orders/capacityReport'
import Feature from 'features/feature'

interface OrdersPageParams {
    accountSlug: string
}

interface OrdersListProps extends FeatureProps {
    user: User
    accounts: Account[]
    history: History
    location: Location
    navigation: Navigation
    ordersService: OrdersService
    loginService: LoginService
    loggingService: LoggingService
    paypalService: PaypalService
    bookingCodesService: BookingCodesService
    match: RouteMatch<OrdersPageParams>
    hasFeature: (feature: string, accountSlug: string) => boolean
    backofficeEndpoint: string
    articleService: ArticleService
}

const OrdersListPage: React.FC<OrdersListProps> = ({
    match,
    navigation,
    user,
    accounts,
    history,
    location,
    hasFeature,
    ordersService,
    loginService,
    loggingService,
    paypalService,
    bookingCodesService,
    backofficeEndpoint,
    articleService,
}) => {
    const { hasPermission } = usePermission()
    const accountSlug = match.params.accountSlug
    const query = navigation.query()
    const sortDirection = query.sortDirection === 'asc' ? 'asc' : 'desc'
    const sort: Sorting = {
        prop: query.sortBy,
        direction: sortDirection,
    }
    const pagination: Pagination = {
        page: isNaN(Number(query.page)) ? defaultPageData.currentPage : Number(query.page),
        pageSize: isNaN(Number(query.pageSize)) ? defaultPageData.pageSize : Number(query.pageSize),
    }
    const dateRange = dateRangeFromQuery(query, 'today')
    const dateRangeType = query.dateRangeType === 'visit' ? 'visit' : 'sale'
    const activeAccount = accounts.find((x) => x.slug === accountSlug) || accounts[0]
    const hideCheckoutRoute = !!user?.resellerId && !activeAccount.activeResellerContract

    const CartComponent = hasFeature('show_new_cart_page', accountSlug) ? Cart : Cart_OLD

    const onSortChanged = (sorting: Sorting) => {
        navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
        })
    }

    const onPaginationChanged = (newPagination: Pagination) => {
        navigation.addQueryWithReplace({
            page: newPagination.page.toString(),
            pageSize: newPagination.pageSize.toString(),
        })
    }

    const onDateRangeChanged = (range: DateRange) => {
        navigation.addQueryWithReplace({
            ...dateRangeToQuery(range),
            page: '1',
        })
    }

    const onDateRangeTypeChanged = (rangeType: DateRangeType) => {
        navigation.addQueryWithReplace({
            dateRangeType: rangeType,
            page: '1',
        })
    }

    return (
        <Switch location={location}>
            {hasFeature('show_sent_emails', accountSlug) && (
                <Route path={ORDER_PATHS.fullPaths.cartSentEmailsPage} render={() => <SentEmailsPage type="cart" />} />
            )}
            <Route path={ORDER_PATHS.fullPaths.cartPage}>
                <CartComponent>
                    <Switch>
                        <Route path={ORDER_PATHS.fullPaths.cartEditPage} component={EditCart} />
                    </Switch>
                </CartComponent>
            </Route>
            <Route path={ORDER_PATHS.fullPaths.ordersPage}>
                <Orders>
                    <Switch>
                        <Route
                            path={ORDER_PATHS.fullPaths.redeemMode}
                            render={({ location }) => (
                                <RedeemModeDialog
                                    onCancel={() => {
                                        const pathname = location.pathname.replace(
                                            `/${ORDER_PATHS.internalPaths.orderPath.redeemMode}`,
                                            '',
                                        )
                                        history.push(`${pathname}${location.search}`)
                                    }}
                                />
                            )}
                        />
                        <Route path={ORDER_PATHS.fullPaths.orderRedeem} component={Redeem} />
                        <Route path={ORDER_PATHS.fullPaths.ordersExport} component={ExportOrders} />
                    </Switch>
                </Orders>
            </Route>
            <Route path="/account/:accountSlug/orders/:id/details" render={() => <OrderDetails />} />
            {hasFeature('oldOrdersPages', accountSlug) && (
                <Route
                    path="/account/:accountSlug/orders/transactions"
                    render={() => (
                        <TransactionsPage
                            history={history}
                            accountSlug={accountSlug}
                            search={query.q}
                            sort={sort}
                            onSortChanged={onSortChanged}
                            pagination={pagination}
                            onPaginationChanged={onPaginationChanged}
                            dateRange={dateRange}
                            onDateRangeChanged={onDateRangeChanged}
                            dateRangeType={dateRangeType}
                            onDateRangeTypeChange={onDateRangeTypeChanged}
                            ordersService={ordersService}
                            loginService={loginService}
                            loggingService={loggingService}
                            backofficeEndpoint={backofficeEndpoint}
                        />
                    )}
                />
            )}
            {hasFeature('oldOrdersPages', accountSlug) && (
                <Route
                    path={ORDER_PATHS.pagePaths.orderDetailsPath}
                    render={() => (
                        <OrdersDetailsPage
                            history={history}
                            accountSlug={accountSlug}
                            search={query.q}
                            sort={sort}
                            onSortChanged={onSortChanged}
                            pagination={pagination}
                            onPaginationChanged={onPaginationChanged}
                            dateRange={dateRange}
                            onDateRangeChanged={onDateRangeChanged}
                            dateRangeType={dateRangeType}
                            onDateRangeTypeChange={onDateRangeTypeChanged}
                            ordersService={ordersService}
                            loginService={loginService}
                            loggingService={loggingService}
                            backofficeEndpoint={backofficeEndpoint}
                        />
                    )}
                />
            )}
            {hasFeature('show_sent_emails', accountSlug) && (
                <Route
                    path="/account/:accountSlug/orders/:id/sent_emails"
                    render={() => <SentEmailsPage type="order" />}
                />
            )}
            {hasPermission('partner_admin', accountSlug) && (
                <Route
                    path={ORDER_PATHS.pagePaths.channelsPath}
                    render={() => (
                        <ChannelsPage
                            history={history}
                            accountSlug={accountSlug}
                            location={location}
                            bookingCodesService={bookingCodesService}
                            articleService={articleService}
                        />
                    )}
                />
            )}
            {hasPermission('partner_admin', accountSlug) && (
                <Route
                    path={ORDER_PATHS.pagePaths.paypalDisputesPath}
                    render={() => (
                        <PaypalDisputesPage
                            history={history}
                            location={location}
                            accountSlug={accountSlug}
                            sort={sort}
                            onSortChanged={onSortChanged}
                            pagination={pagination}
                            onPaginationChanged={onPaginationChanged}
                            dateRange={dateRange}
                            onDateRangeChanged={onDateRangeChanged}
                            paypalService={paypalService}
                            ordersService={ordersService}
                        />
                    )}
                />
            )}
            {hasPermission('edit_orders', accountSlug) && !hideCheckoutRoute && (
                <Route
                    path={ORDER_PATHS.pagePaths.newOrderPath}
                    render={() => (
                        <InlineCheckout history={history} accountSlug={accountSlug} loginService={loginService} />
                    )}
                />
            )}
            <Feature name="is_capacity_report_enabled" accountSlug={accountSlug}>
                <Route
                    exact
                    path="/account/:accountSlug/orders/capacity_report/"
                    render={() => <CapacityReportPage />}
                />
            </Feature>
            <Route render={() => <Redirect to={`/account/${accountSlug}/orders/transactions`} />} />
        </Switch>
    )
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
        user: state.auth.user,
    }
}

export default withFeatures(withNavigation(connect(mapStateToProps)(OrdersListPage)))
