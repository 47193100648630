import React from 'react'
import styled from 'styled-typed'
import conviousLogo from 'images/convious-logo-pink.svg'
import { RequiresToChangePassword } from 'routesUtils'
import Header from 'header'
import Svg from 'svg'
import { PageContent } from './pageContent'
import { State } from 'store'
import { connect } from 'react-redux'

const Layout = styled.div`
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 16px;
    color: ${(props) => props.theme.colors.textDark};
    background: ${(props) => props.theme.colors.background};
    padding-left: 11.2em;
    height: 100%;
    width: 100%;
    flex: 1;
    transition: 0.15s all ease;

    &.fixed {
        position: fixed;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        padding-left: 0;
    }
`
const LayoutNoSidebar = styled(Layout)`
    padding: 0 1em;
`
const LogoWrapper = styled.div`
    width: 6em;
    position: absolute;
    top: 2em;
    left: 2em;
`

const ContentWrapper = styled.div`
    height: 100%;
`

export const FilterSection = styled.div`
    margin-top: 1em;
    margin-bottom: 1.5em;

    &:after {
        content: '';
        display: table;
        clear: both;
    }
`

interface HeaderOnlyProps {
    requiresPasswordChange: boolean
}

const HeaderOnly: React.FC<HeaderOnlyProps> = ({ children, requiresPasswordChange }) => (
    <LayoutNoSidebar>
        <ContentWrapper>
            <LogoWrapper>
                <a href="/">
                    <Svg src={conviousLogo} />
                </a>
            </LogoWrapper>
            <Header />
            <PageContent className="no-sidebar">
                <RequiresToChangePassword requiresPasswordChange={requiresPasswordChange}>
                    {children}
                </RequiresToChangePassword>
            </PageContent>
        </ContentWrapper>
    </LayoutNoSidebar>
)

const mapStateToProps = (state: State) => {
    return {
        requiresPasswordChange: !!state.profile?.profile?.requiresPasswordChange || false,
    }
}

export default connect(mapStateToProps)(HeaderOnly)
