import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash'
import { Checkbox } from 'uiComponents/input'
import Money from 'uiComponents/money'
import { IOrder, IOrderItems } from 'orders/schema'
import { getSelectedOrderItemsListByTableId, toggleAllOrderItems, toggleOrder } from 'orders/orders/redux'
import OrderItem from './item'
import ExpandIcon from 'orders/orders/commons/expandIcon'
import { ReplaceMessagesFunc } from 'messagesContext'
import './styles.scss'
import Infotip from 'uiComponents/infotip'
import { useHasFeature } from 'utils/useHasFeature'

interface OrderItemsProps {
    order: IOrder
    accountSlug: string
    replaceMessages: ReplaceMessagesFunc
    hideMessage: (id: string) => void
    shouldShowPricesWithoutTax: boolean
}

const OrderItems = ({
    order,
    accountSlug,
    replaceMessages,
    hideMessage,
    shouldShowPricesWithoutTax,
}: OrderItemsProps) => {
    const dispatch = useDispatch()
    const itemsUuids =
        order?.items?.filter((item: IOrderItems) => !item.refundInfo).map((item: IOrderItems) => item.id) ?? []
    const selectedItems = useSelector(getSelectedOrderItemsListByTableId(order.id))
    const partialOrderSelected = selectedItems.length > 0 && selectedItems.length < itemsUuids.length
    const [isAllExpanded, setIsAllExpanded] = React.useState(!!(order.items && order.items.length < 5))
    const bookingFeesEnabled = useHasFeature({ featureName: 'BookingFees' })

    const toggleOrderAndItems = React.useCallback(
        (checked: boolean) => {
            dispatch(toggleOrder({ orderUuid: order.id, selected: checked }))
            dispatch(
                toggleAllOrderItems({
                    tableId: order.id,
                    rows: itemsUuids.reduce(
                        (acc: { [itemUuid: string]: boolean }, itemId: string) => ({ ...acc, [itemId]: checked }),
                        {},
                    ),
                }),
            )
        },
        [order.id, itemsUuids],
    )

    React.useEffect(() => {
        toggleOrderAndItems(true)
    }, [])

    const handleOrderCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        toggleOrderAndItems(e.target.checked)
    }

    return (
        <div className="order-items">
            <div className="order">
                <div className="item-row">
                    <ExpandIcon isExpanded={isAllExpanded} setIsExpanded={setIsAllExpanded} />
                    <Checkbox
                        id="order-select-all"
                        checked={selectedItems.length > 0}
                        onChange={handleOrderCheckboxChange}
                        indeterminateState={partialOrderSelected}
                    />
                    <label htmlFor="order-select-all">
                        <span>Order ID:&nbsp;</span>
                        <strong>{order.number}</strong>
                    </label>
                    <div
                        className="expand"
                        onClick={() => {
                            setIsAllExpanded(!isAllExpanded)
                        }}
                    />
                    {shouldShowPricesWithoutTax && (
                        <span className="price-excl-tax">
                            Total (excl. tax):&nbsp;
                            <strong>
                                <Money amount={order.totalDiscountedPriceExclTax} accountSlug={accountSlug} />
                            </strong>
                        </span>
                    )}
                    <span className="price-incl-tax">
                        Total:&nbsp;
                        <strong>
                            <Money amount={order.totalDiscountedPriceInclTax} accountSlug={accountSlug} />
                        </strong>
                        <Infotip className="tooltip">{`Includes ${
                            bookingFeesEnabled ? 'fees and ' : ''
                        }discounts.`}</Infotip>
                    </span>
                </div>
            </div>
            {sortBy(order?.items, ['product', 'id']).map((item: IOrderItems) => (
                <OrderItem
                    key={item.id}
                    item={item}
                    accountSlug={accountSlug}
                    orderUuid={order.id}
                    replaceMessages={replaceMessages}
                    hideMessage={hideMessage}
                    shouldBeExpanded={isAllExpanded}
                    shouldShowPricesWithoutTax={shouldShowPricesWithoutTax}
                />
            ))}
        </div>
    )
}

export default OrderItems
