import * as React from 'react'
import styled from 'styled-typed'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { RequiresToChangePassword } from 'routesUtils'
import { AppServices } from 'middleware'
import { BaseRouteParams } from 'hocs'
import Header from 'header'
import { PageContent } from './pageContent'
import { State } from 'store'
import Sidebar from 'sidebar'
import { usePermission } from 'admin/hocs'
import { FeatureProps, withFeatures } from 'features'

const Layout = styled.div`
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 16px;
    color: ${(props) => props.theme.colors.textDark};
    background: ${(props) => props.theme.colors.background};
    padding-left: 11.2em;
    height: 100%;
    width: 100%;
    flex: 1;
    transition: 0.15s all ease;

    &.fixed {
        position: fixed;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        padding-left: 0;
    }
`

const ContentWrapper = styled.div`
    height: 100%;
`

export const FilterSection = styled.div`
    margin-top: 1em;
    margin-bottom: 1.5em;

    &:after {
        content: '';
        display: table;
        clear: both;
    }
`

interface SidebarMenuAndHeaderProps {}

interface SidebarMenuAndHeaderComponentProps extends FeatureProps, SidebarMenuAndHeaderProps {
    selfSignUp: string | null
    activeAccount: string | null
    requiresPasswordChange: boolean
}

const SidebarMenuAndHeader: React.FC<SidebarMenuAndHeaderComponentProps> = ({
    children,
    requiresPasswordChange,
    selfSignUp,
    activeAccount,
    hasFeature,
}) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const _slug = accountSlug || activeAccount
    const { hasPermission } = usePermission()

    return (
        <Layout id="layout">
            <Sidebar
                accountSlug={_slug as string}
                selfSignUp={selfSignUp}
                hasPermission={hasPermission}
                hasFeature={hasFeature}
                loggingService={AppServices.loggingService}
            />
            <ContentWrapper>
                <Header />
                <PageContent>
                    <RequiresToChangePassword requiresPasswordChange={requiresPasswordChange}>
                        {children}
                    </RequiresToChangePassword>
                </PageContent>
            </ContentWrapper>
        </Layout>
    )
}

const mapStateToProps = (state: State) => {
    return {
        requiresPasswordChange: !!state.profile?.profile?.requiresPasswordChange,
        selfSignUp: state.profile?.profile?.signUp || null,
        activeAccount: state.preferences.activeAccount
    }
}

export default withFeatures(connect(mapStateToProps)(SidebarMenuAndHeader))
