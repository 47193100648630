import {
    Locale,
    ArticleCrudData,
    CMS_KEY_PLACEHOLDER,
    ProductDisplayType,
    ArticleBusinessType,
} from 'admin/articleService'
import styled from 'styled-typed'
import { PricingType } from 'products/articleConfigurationService'
import { Col } from 'uiComponents/flex'
import { OptionItemDetails } from 'products/options/optionsService'

export const SectionTitle = styled.div`
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 1em;
`

export const PreviewBox = styled.div`
    margin-top: 2em;
    border: 1px solid ${(p) => p.theme.colors.border};
    width: 20em;
    border-radius: 4px;
    min-height: 10em;
`

export const RedCross = styled.span`
    color: ${(p) => p.theme.colors.status.error};
    margin-left: 0.8em;
`

export const PreviewText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    padding: 1rem;
`

export const PreviewNumber = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem;
`

export const PreviewDescription = styled.div`
    font-size: 0.75rem;
    line-height: 1.3em;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    padding: 1em;
`

export const Link = styled.div`
    font-size: 0.875em;
    span {
        color: ${(p) => p.theme.colors.boyBlue};
        text-decoration: underline;
        cursor: pointer;
    }
`

export const MinMaxCol = styled(Col)`
    display: flex;
    align-items: center;
`

const pricingTypeOptions: { name: string; value: PricingType }[] = [
    { value: 'rtp', name: 'Regular' },
    { value: 'nyop', name: 'NYOP - Name Your Own Price' },
    { value: 'upsell', name: 'Upsell' },
]

const membershipPricingTypeOptions: { name: string; value: PricingType }[] = [
    { value: 'payment_plan', name: 'Payment plan' },
]

export const getPricingTypeOptions = (businessType: ArticleBusinessType | null) => {
    return businessType === 'membership' ? membershipPricingTypeOptions : pricingTypeOptions
}

export const productTypeNameMap = {
    single_visit_ticket: 'Single ticket',
    visit_reservation: 'Reservation',
    upsell: 'Upsell',
    season_pass: 'Season/Yearly ticket',
    membership: 'Membership',
    multi_visit_ticket: 'Multi-visit ticket',
    bundle: 'Bundle',
    voucher: 'Voucher',
    service_date_change: 'Date change',
    service: 'Service',
    service_fee: 'Service fee',
    donation: 'Donation',
    loyalty_card: 'Loyalty card',
    food_and_beverage: 'Food and beverage',
}

const businessTypeOptions: {
    name: string
    value: ArticleBusinessType
}[] = [
    {
        value: 'single_visit_ticket',
        name: productTypeNameMap.single_visit_ticket,
    },
    { value: 'visit_reservation', name: productTypeNameMap.visit_reservation },
    { value: 'upsell', name: productTypeNameMap.upsell },
    { value: 'season_pass', name: productTypeNameMap.season_pass },
    { value: 'membership', name: productTypeNameMap.membership },
    { value: 'multi_visit_ticket', name: productTypeNameMap.multi_visit_ticket },
    {
        value: 'bundle',
        name: `${productTypeNameMap.bundle} (package or group booking)`,
    },
    {
        value: 'voucher',
        name: `${productTypeNameMap.voucher} (gift vouchers, coupons, discounts)`,
    },
    {
        value: 'service_date_change',
        name: `${productTypeNameMap.service_date_change}`,
    },
    {
        value: 'service',
        name: `${productTypeNameMap.service} (cancellation, other)`,
    },
    { value: 'service_fee', name: `${productTypeNameMap.service_fee}` },
    { value: 'donation', name: productTypeNameMap.donation },
    { value: 'loyalty_card', name: productTypeNameMap.loyalty_card },
    { value: 'food_and_beverage', name: productTypeNameMap.food_and_beverage },
]

export const getBusinessTypeOptions = (dateChangeFeatureEnabled: boolean, membershipsFeatureEnabled: boolean) =>
    businessTypeOptions.filter((businessType) => {
        if (businessType.value === 'service_date_change' && !dateChangeFeatureEnabled) {
            return false
        }
        if (businessType.value === 'membership' && !membershipsFeatureEnabled) {
            return false
        }
        return true
    })

export const displayTypeOptions: { name: string; value: ProductDisplayType }[] = [
    { value: 'REGULAR_TICKET', name: 'Single-use ticket' },
    { value: 'SUBSCRIPTION', name: 'Multi-use ticket (season pass)' },
]

export function mapLocales(locs: Locale[], productData: ArticleCrudData) {
    const descriptionKey = productData.description ? productData.description.key : CMS_KEY_PLACEHOLDER

    const emptyObject = {
        name: {
            key: CMS_KEY_PLACEHOLDER,
            text: {},
        },
        description: {
            key: descriptionKey,
            text: {},
        },
    }
    locs.forEach((l) => {
        emptyObject.name.text[l.code] = productData.name.text[l.code] ? productData.name.text[l.code] : ''
        emptyObject.description.text[l.code] =
            productData.description && productData.description.text[l.code] ? productData.description.text[l.code] : ''
    })
    return emptyObject
}

export const mapArticleData = (data: ArticleCrudData | OptionItemDetails) => {
    if ('scanningOptions' in data && data.scanningOptions?.scanLimitType === 'number_of_scans') {
        data.scanningOptions.scanLimitType = 'scan_period_selected_date'
    }
    return data as ArticleCrudData | OptionItemDetails
}

export function extractContentFromHTML(richText: string) {
    var span = document.createElement('span')
    span.innerHTML = richText
    return span.textContent || span.innerText
}
