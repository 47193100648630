import React, { useEffect } from 'react'
import { Headline } from '../../uiComponents/typography'
import { useParams } from 'react-router-dom'
import { SigmaService, EmbedUrlParams } from './sigmaService'
import { useHasFeature } from 'utils/useHasFeature'
import { usePageHeight, useSigmaIframe } from '@sigmacomputing/react-embed-sdk'
import { getPreferredLanguage, LanguageSelector } from 'uiComponents/languageSelector/languageSelector'
import { useAppSelector } from 'store/hooks'
import { getPartnerCountryCode } from 'auth/selectors'
import './sigmaReport.scss'

interface SigmaReportProps {
    accountSlug: string
    sigmaService: SigmaService
}

export const SigmaReport = ({ accountSlug, sigmaService }: SigmaReportProps) => {
    const { iframeRef } = useSigmaIframe()
    const height = Math.ceil(usePageHeight(iframeRef) || 0)
    const [embedUrl, setEmbedUrl] = React.useState('')
    const { reportId } = useParams<{ reportId: string }>()
    const featureFlagEnabled = useHasFeature({ featureName: 'SigmaReports' })
    const countryCode = useAppSelector(getPartnerCountryCode)

    const getUrlParams = (): EmbedUrlParams => ({
        responsive_height: true,
        menu_position: 'top',
        lng: countryCode === 'FR' ? getPreferredLanguage() : 'en',
    })

    const fetchEmbedUrl = (urlParams: EmbedUrlParams) => {
        sigmaService.getEmbedUrl(accountSlug, reportId, urlParams).then((url) => {
            setEmbedUrl(url)
        })
    }

    useEffect(() => {
        if (!embedUrl && featureFlagEnabled) {
            fetchEmbedUrl(getUrlParams())
        }
    })

    useEffect(() => {
        setEmbedUrl('')
    }, [featureFlagEnabled, accountSlug, reportId, countryCode])

    return featureFlagEnabled ? (
        <>
            <Headline size={1}>Sales & Finance</Headline>
            {countryCode === 'FR' && (
                <LanguageSelector
                    options={[
                        { name: 'English', value: 'en' },
                        { name: 'French', value: 'fr' },
                    ]}
                    onChange={() => {
                        fetchEmbedUrl(getUrlParams())
                    }}
                />
            )}
            {embedUrl && <iframe className="sigma-iframe" ref={iframeRef} style={{ height }} src={embedUrl} />}
        </>
    ) : null
}
