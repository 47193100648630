import React, { useState, useEffect, useContext, ChangeEvent, MouseEvent, useMemo } from 'react'
import { usePrevious } from 'reactUtils'
import { connect } from 'react-redux'
import { State } from 'store'
import { useHistory } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import { isEmpty } from 'lodash'
import { delay } from 'utils'
import { withNavigation, WithNavigationProps } from 'hocs'
import { MessageKind } from 'uiComponents/messages'
import { ContainerBody } from 'uiComponents/settingsContainer'
import { PageLoader } from 'uiComponents/loaders'
import { TextWithHorizontalLine } from 'uiComponents/pageElements'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { ImagesServiceContext } from 'http/context'
import { ImageUploadResponse } from 'http/imagesService'
import { Template } from 'ticketTemplates/ticketTemplatesService'
import { Section } from './common'
import {
    ArticleCrudData,
    InventoryPool,
    BarcodeReservoir,
    Integration,
    ExternalIdItem,
    Locale,
} from 'admin/articleService'
import GeneralInfo from './generalInfo'
import { Barcodes } from './barcodes'
import { Options } from './options'
import { ProductIds } from './externalIds'
import { ETicketsSection } from './eTickets'
import ExternalConfigurationSection from './externalConfigurations'
import FormActions from './formActions'
import { InventorySection } from 'products/inventorySection'
import { InventoryItem } from 'inventory/inventoryService'
import isEqual from 'lodash/isEqual'
import { FeatureProps, withFeatures } from 'features'
import { retrieveImgDimentions } from 'uiComponents/input/upload'
import { OptionGroup } from 'products/options/optionsService'
import { mapArticleData } from './utils'
import { createProductCrudValidationSchema } from './validation'
import { validateYupSchemaSync } from 'utils/errorHandler'
import { FormWrapper } from 'uiComponents/form/form'
import Commission from './commission'
import { AppServices } from 'middleware'
import PermissionRequired from 'admin/permissionRequired'
import { useGetAllCategories, useGetFullProductsAndArticlesListForSelect } from 'products/redux'
import { useGetTaxConfigurationsQuery } from 'settings/accountSettings/taxConfigurations/rtkQuery'
import { PricingType } from 'products/articleConfigurationService'

const dummyProductData = {
    id: '',
    productsListIds: [],
    multipleProductLists: null,
    name: { key: 'article.name', text: { en: '' } },
    description: null,
    showFrom: null,
    showTo: null,
    validFrom: null,
    validTo: null,
    pricingType: 'rtp' as PricingType,
    priority: 1,
    barcodePoolId: null,
    canBeEnabled: false,
    articleValidationErrors: null,
    enabled: false,
    image: null,
    squareImage: null,
    imageType: null,
    usedResources: [],
    redeemable: false,
    businessType: null,
    numericId: '',
    taxConfigurationUuid: null,
    externalId: null,
    externalIds: [],
    reservedArticleId: null,
    reservedArticleName: null,
    displayType: 'REGULAR_TICKET' as const,
    ticketTemplateConfig: null,
    ticketPrintTemplateConfig: null,
    apfEnabled: false,
    bookingHorizon: null,
    releaseTime: null,
    cardOpenByDefault: true,
    articleOptions: [],
    dateChangedArticles: [],
    minTickets: 1,
    maxTickets: 10,
    scanLimit: 'once' as const,
    scanLimitTimes: 1,
    ticketDuration: null,
    scanningOptions: {
        scanLimitType: 'number_of_scans' as const,
        maxScans: 1,
        ticketDuration: null,
        maxScansPerDay: null,
    },
}

const cumpulsorySaveMessage = {
    validity: 'To configure validity you need to save the product first.',
    pricing: 'To configure pricing you need to save the product first.',
}

interface ProductPageParams {
    accountSlug: string
    id: string
}

interface ProductFormProps
    extends ReturnType<typeof mapStateToProps>,
        WithNavigationProps<ProductPageParams>,
        FeatureProps {
    accountSlug: string
    setActiveSection: (section: string, header?: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    viewMode: 'flat' | 'nested' | ''
}

function ProductForm(props: ProductFormProps) {
    const imagesService = useContext(ImagesServiceContext)
    const articleService = AppServices.articleService
    const optionsService = AppServices.optionsService
    const [showOptionalSaveMsg, setShowOptionalSaveMsg] = useState<boolean>(false)
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [confirmationMessage, setConfirmationMessage] = useState<string>('')
    const [originalProductData, setOriginalProductData] = useState<ArticleCrudData>(dummyProductData)
    const [productData, setProductData] = useState<ArticleCrudData>(dummyProductData)
    const [ticketTemplatesList, setTicketTemplatesList] = useState<Template[]>([])
    const [locales, setLocales] = useState<Locale[] | null>(null)
    const productMultiselectOptions = useGetFullProductsAndArticlesListForSelect()
    const flattenedCategories = useGetAllCategories()
    const [inventoryPools, setInventoryPools] = useState<InventoryItem[]>([])
    const [barcodeReservoirs, setBarcodeReservoirs] = useState<BarcodeReservoir[]>([])
    const [productOptionsList, setProductOptionsList] = useState<OptionGroup[]>([])
    const [integrations, setIntegrations] = useState<Integration[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [localesWithMissingName, setLocalesWithMissingName] = useState<string[]>([])
    const [localesWithMissingDescription, setLocalesWithMissingDescription] = useState<string[]>([])
    const [activeLocaleIndex, setActiveLocaleIndex] = useState<number>(0)
    const [showBookingLimitsError, setShowBookingLimitsError] = useState<boolean>(false)
    const [missingBusinessType, setMissingBusinessType] = useState<boolean>(false)
    const [missingDateChangeProducts, setMissingDateChangeProducts] = useState<boolean>(false)
    const [missingInventoryPoolId, setMissingInventoryPoolId] = useState<boolean>(false)
    const [missingExternalIdType, setMissingExternalIdType] = useState<boolean>(false)
    const [minMaxTicketsError, setMinMaxTicketsError] = useState<boolean>(false)
    const [onSaveClicked, setOnSaveClicked] = useState<boolean>(false)
    const [formErrors, setFormErrors] = useState<any>({})
    const [clickedExternalLink, setClickedExternalLink] = useState<string | null>(null)
    const getTaxConfigurationsQuery = useGetTaxConfigurationsQuery(props.accountSlug)
    const loadingTaxConfigurations = getTaxConfigurationsQuery.isLoading
    const taxConfigurations = getTaxConfigurationsQuery.data?.taxConfigurations || null
    const defaultTaxConfigurationUuid = getTaxConfigurationsQuery.data?.defaultTaxConfigurationUuid || null

    const history = useHistory()
    const validationSchema = createProductCrudValidationSchema({
        scanningOptions: props.hasFeature('ScanningOptions', props.accountSlug),
    })
    const parentDisabledProductOptions = useMemo(
        () =>
            productMultiselectOptions?.map((product) => {
                return { ...product, disabled: product.type === 'category' }
            }),
        [productMultiselectOptions],
    )

    async function getProductData(id: string) {
        try {
            setLoading(true)
            const data = await articleService.fetchArticle(props.accountSlug, id)
            setOriginalProductData(data)
            setProductData(mapArticleData(data) as ArticleCrudData)
            setLoading(false)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Could not get the product with this ID, please try again later.',
            )
        }
    }

    async function getMetadata(): Promise<void> {
        try {
            setLoading(true)
            const accountLocales = await articleService.getAccountLocales(props.accountSlug)
            const barcodeRes = await articleService.getBarcodeReservoirs(props.accountSlug)
            const integrationsList = await articleService.getIntegrationsList()
            const optionsList = await optionsService.getOptionGroups(props.accountSlug)
            setBarcodeReservoirs(barcodeRes)
            setIntegrations(integrationsList)
            setProductOptionsList(optionsList)
            const inventory = await AppServices.inventoryService.getInventoryList(props.accountSlug)
            setInventoryPools(inventory)
            const templates = await AppServices.ticketTemplatesService.getTemplatesList(
                props.accountSlug,
                'all_tickets',
            )
            setTicketTemplatesList(templates)

            const localesToUpperCase = accountLocales.locales.map((locale) => ({
                code: locale.code,
                name: locale.name,
            }))
            setLocales(localesToUpperCase)
            setLoading(false)
        } catch (e) {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Could not get some article data, please try again later.',
            )
        }
    }

    useEffect(() => {
        const productId = props.match.params.id
        props.setActiveSection('productsList')

        getMetadata()
        if (!!productId && productId !== 'new') {
            getProductData(productId)
        }
    }, [])

    const prevId = usePrevious(props.match.params.id)
    useEffect(() => {
        if (prevId && prevId !== 'new' && prevId !== props.match.params.id) {
            getProductData(props.match.params.id)
        }
    }, [props.match.params.id])

    const prevAccount = usePrevious(props.accountSlug)
    useEffect(() => {
        if (prevAccount && prevAccount !== props.accountSlug) {
            history.push(`/account/${props.accountSlug}/products/home/${props.viewMode === 'flat' ? 'flat' : 'nested'}`)
        }
    }, [props.accountSlug])

    function areDateChangeProductsMissing(): boolean {
        if (productData.businessType === 'service_date_change' && !productData.dateChangedArticles.length) {
            setMissingDateChangeProducts(true)
            return true
        }
        return false
    }

    function validateLocales() {
        if (!locales) {
            return false
        }

        setLocalesWithMissingName([])
        setLocalesWithMissingDescription([])

        let missingTranslations = false

        const localesWithoutName = locales
            .filter((locale) => !productData.name.text[locale.code])
            .map((locale) => locale.code)

        if (localesWithoutName.length) {
            setLocalesWithMissingName(localesWithoutName)
            activateLocaleWithMissingValue(localesWithoutName[0])
            missingTranslations = true
        }

        const atLeastOneDescription = !!locales.find((locale) => !!productData.description?.text[locale.code])
        const localesWithoutDescription = locales
            .filter((locale) => !productData.description?.text[locale.code])
            .map((locale) => locale.code)

        if (atLeastOneDescription) {
            if (localesWithoutDescription.length) {
                setLocalesWithMissingDescription(localesWithoutDescription)
                if (!missingTranslations) {
                    activateLocaleWithMissingValue(localesWithoutDescription[0])
                    missingTranslations = true
                }
            }
        } else {
            productData.description = null
        }
        return !missingTranslations
    }

    const validateBookingLimits = () => {
        const bookingLimitsError =
            !!productData.bookingHorizon &&
            !!productData.releaseTime &&
            productData.bookingHorizon <= productData.releaseTime

        setShowBookingLimitsError(bookingLimitsError)
        return !bookingLimitsError
    }

    function isProductDataValid(productData: ArticleCrudData): boolean {
        if (!locales) {
            return false
        }

        setOnSaveClicked(true)
        setTimeout(() => setOnSaveClicked(false), 500)

        let missingBusType = false
        if (!productData.businessType) {
            setMissingBusinessType(true)
            missingBusType = true
        }

        let missingDateChProducts = false
        if (productData.enabled) {
            missingDateChProducts = areDateChangeProductsMissing()
        }

        const missingBarcodesId = !productData.barcodePoolId && productData.businessType !== 'service_date_change'

        let missingInventoryId = false
        productData.usedResources.every((r) => {
            if (!r.poolId) {
                missingInventoryId = true
                return false
            } else {
                return true
            }
        })
        setMissingInventoryPoolId(missingInventoryId)

        let missingExternalIdIntegrationType = false
        productData.externalIds.every((i) => {
            if (!i.integrationType) {
                missingExternalIdIntegrationType = true
                return false
            } else {
                return true
            }
        })

        setMissingExternalIdType(missingExternalIdIntegrationType)

        const minMaxTickError =
            !productData.minTickets || !productData.maxTickets || productData.minTickets > productData.maxTickets
        setMinMaxTicketsError(minMaxTickError)

        // double validation here to show non formik related validation messages
        const _formErrors = validateYupSchemaSync(validationSchema, productData)
        setFormErrors(_formErrors)

        const isValid = isEmpty(_formErrors)

        const formValid =
            validateLocales() &&
            validateBookingLimits() &&
            !missingBarcodesId &&
            !missingInventoryId &&
            !missingBusType &&
            !missingExternalIdIntegrationType &&
            !missingDateChProducts &&
            !minMaxTickError &&
            isValid

        if (!formValid) {
            props.replaceMessages('validation_error', 'error', 'Please fix the validation errors.')
        }
        return formValid
    }

    async function onSaveProduct(values: ArticleCrudData) {
        setShowConfirmation(false)
        if (isProductDataValid(values)) {
            setLoading(true)
            try {
                const dataToSave = {
                    ...cleanUpData(productData),
                    ...values,
                }
                if (!props.hasFeature('ScanningOptions', props.accountSlug)) {
                    dataToSave.scanningOptions = null
                }
                if (props.match.params.id !== 'new') {
                    const savedProduct = await articleService.updateArticle(
                        props.accountSlug,
                        dataToSave,
                        props.match.params.id,
                    )
                    setProductData(savedProduct)
                } else {
                    const savedProduct = await articleService.createArticle(props.accountSlug, dataToSave)
                    history.push(
                        `/account/${props.accountSlug}/products/crud/${savedProduct.id}?from=${props.viewMode}`,
                    )
                }
                props.replaceMessages('success', 'success', 'Product has been saved successfully.')
                setLoading(false)
                await delay(3000)
                props.removeAllMessages()
            } catch (e) {
                const message = e.message ? e.message : 'Oops! Could not save the product, please try again later.'
                props.replaceMessages('server_error', 'error', message)
                if (props.match.params.id !== 'new') {
                    getProductData(props.match.params.id)
                } else {
                    setLoading(false)
                }
            }
        }
    }

    async function onPublishUnpublishProduct(enable: boolean) {
        setShowConfirmation(false)
        if (isProductDataValid(productData) && !areDateChangeProductsMissing()) {
            try {
                const dataToSave = cleanUpData(productData)
                const savedProduct = await articleService.updateArticle(
                    props.accountSlug,
                    { ...dataToSave, enabled: enable },
                    props.match.params.id,
                )
                setProductData(savedProduct)
                if (enable) {
                    props.replaceMessages('success', 'success', 'The product has been published successfully.')
                } else {
                    props.replaceMessages('success', 'success', 'The product has been unpublished successfully.')
                }
                await delay(3000)
                props.removeAllMessages()
            } catch {
                if (enable) {
                    props.replaceMessages(
                        'server_error',
                        'error',
                        'Oops! Could not publish the product, please try again later.',
                    )
                } else {
                    props.replaceMessages(
                        'server_error',
                        'error',
                        'Oops! Could not unpublish the product, please try again later.',
                    )
                }
            }
        }
    }

    function getLocaleIndex(localeWithMissingName: string) {
        let localeIndex = null
        locales?.map((locale, index) => {
            if (locale.code === localeWithMissingName) {
                localeIndex = index
            }
        })
        return localeIndex
    }

    function activateLocaleWithMissingValue(localeWithMissingValue: string) {
        const activeLocaleIndex = getLocaleIndex(localeWithMissingValue)
        if (activeLocaleIndex !== null) {
            setActiveLocaleIndex(activeLocaleIndex)
        }
    }

    function cleanUpData(data: ArticleCrudData) {
        const cleanedUpData = cloneDeep(data)
        cleanedUpData.externalIds = cleanedUpData.externalIds?.filter((i) => !!i.externalId?.trim()) || []
        return cleanedUpData
    }

    function updateInventoryData(inventoryList: InventoryPool[]) {
        const updatedProductData = cloneDeep(productData)
        updatedProductData.usedResources = inventoryList
        setProductData(updatedProductData)
    }

    function updateExternalIdsListData(externalIds: ExternalIdItem[]) {
        const updatedProductData = cloneDeep(productData)
        updatedProductData.externalIds = externalIds
        setProductData(updatedProductData)
    }

    function updateExternalIdValue(event: ChangeEvent<HTMLTextAreaElement>, index: number) {
        const updatedProductData = cloneDeep(productData)
        if (!!updatedProductData.externalIds && updatedProductData.externalIds[index]) {
            updatedProductData.externalIds[index].externalId = event.target.value.trim() || null
        }
        setProductData(updatedProductData)
    }

    function resetMissingBusinessType() {
        setMissingBusinessType(false)
        if (!!missingBusinessType) {
            props.removeAllMessages()
        }
    }

    async function onWideImageUpload(file: File) {
        return await imagesService.uploadImage(file, {
            max_height: 300,
            max_width: 450,
            optimise_size: true,
        })
    }

    async function onSquareImageUpload(file: File) {
        const dimentions = await retrieveImgDimentions(file)
        if (!dimentions) {
            return
        }
        if (!!dimentions && dimentions.width !== dimentions.height) {
            return Promise.reject({
                message: 'The image you are trying to upload is not square.',
            })
        }
        return await imagesService.uploadImage(file, {
            optimise_size: true,
        })
    }

    function onWideImageUploadSuccess(response: ImageUploadResponse) {
        const updatedProductData = cloneDeep(productData)
        updatedProductData.image = response.url
        setProductData(updatedProductData)
    }

    function onSquareImageUploadSuccess(response: ImageUploadResponse) {
        const updatedProductData = cloneDeep(productData)
        updatedProductData.squareImage = response.url
        setProductData(updatedProductData)
    }

    const onOptionsSelectChange = (optionUuids: string[]) => {
        const updatedProductData = cloneDeep(productData)
        updatedProductData.articleOptions = optionUuids
        setProductData(updatedProductData)
    }

    const onExternalNavigate = (event: MouseEvent, destination: string) => {
        event.preventDefault()
        setClickedExternalLink(destination)

        if (!isEqual(originalProductData, productData)) {
            setShowOptionalSaveMsg(true)
        } else {
            history.push(destination)
        }
    }

    const onTicketTemplateSelect = (id: string) => {
        const updatedProductData = cloneDeep(productData)
        updatedProductData.ticketTemplateConfig = id || null
        setProductData(updatedProductData)
    }

    const onPrintTicketTemplateSelect = (id: string | null) => {
        const updatedProductData = cloneDeep(productData)
        updatedProductData.ticketPrintTemplateConfig = id || null
        setProductData(updatedProductData)
    }

    const saveBeforeContinue = (action: 'validity' | 'pricing') => {
        const productId = props.match.params.id
        if (productId === 'new') {
            setConfirmationMessage(cumpulsorySaveMessage[action])
            setShowConfirmation(true)
        } else {
            history.push(`/account/${props.accountSlug}/products/${action}/${productId}/detail?from=${props.viewMode}`)
        }
    }

    return (
        <FormWrapper
            enableReinitialize
            initialValues={productData}
            validationSchema={validationSchema}
            style={{ position: 'relative' }}
            formId="product-details-page"
            onSubmit={onSaveProduct}
            formName="product-details-page"
        >
            {({ submitForm }) => {
                return (
                    <>
                        {showConfirmation && (
                            <ConfirmationDialog
                                title="Save the product?"
                                text={confirmationMessage}
                                buttonText="Save"
                                onCancel={() => setShowConfirmation(false)}
                                onConfirm={() => submitForm()}
                            />
                        )}
                        {showOptionalSaveMsg && (
                            <ConfirmationDialog
                                title="You have unsaved changes!"
                                text="If you leave this page, all changes will be lost."
                                buttonText="Continue"
                                destructive
                                onCancel={() => {
                                    setClickedExternalLink(null)
                                    setShowOptionalSaveMsg(false)
                                }}
                                onConfirm={() =>
                                    history.push(
                                        clickedExternalLink || `/account/${props.accountSlug}/venue/codes/tickets/list`,
                                    )
                                }
                            />
                        )}
                        {(loading || !locales) && <PageLoader style={{ paddingBottom: '4rem' }} />}
                        {!loading && locales && (
                            <ContainerBody style={{ flexDirection: 'column' }}>
                                <ExternalConfigurationSection
                                    accountSlug={props.accountSlug}
                                    onSave={() => submitForm()}
                                    onPublish={() => onPublishUnpublishProduct(true)}
                                    onUnpublish={() => onPublishUnpublishProduct(false)}
                                    productData={productData}
                                    saveBeforeContinue={saveBeforeContinue}
                                />
                                <Section span={12}>
                                    {locales && flattenedCategories && (
                                        <GeneralInfo
                                            accountSlug={props.accountSlug}
                                            productData={productData}
                                            updateData={setProductData}
                                            locales={locales}
                                            activeLocaleIndex={activeLocaleIndex}
                                            setActiveLocaleIndex={setActiveLocaleIndex}
                                            localesWithMissingName={localesWithMissingName}
                                            localesWithMissingDescription={localesWithMissingDescription}
                                            showBookingLimitsError={showBookingLimitsError}
                                            missingBusinessType={missingBusinessType}
                                            resetMissingBusinessType={resetMissingBusinessType}
                                            replaceMessages={props.replaceMessages}
                                            removeAllMessages={props.removeAllMessages}
                                            onWideImageUpload={onWideImageUpload}
                                            onWideImageUploadSuccess={onWideImageUploadSuccess}
                                            onSquareImageUpload={onSquareImageUpload}
                                            onSquareImageUploadSuccess={onSquareImageUploadSuccess}
                                            viewMode={props.viewMode}
                                            productTreeOptions={parentDisabledProductOptions}
                                            dateChangeFeatureEnabled={props.hasFeature(
                                                'DateChangeProductCRUDUpdate',
                                                props.accountSlug,
                                            )}
                                            membershipsFeatureEnabled={props.hasFeature(
                                                'memberships',
                                                props.accountSlug,
                                            )}
                                            missingDateChangeProducts={missingDateChangeProducts}
                                            minMaxTicketsError={minMaxTicketsError}
                                            loadingTaxConfigurations={loadingTaxConfigurations}
                                            taxConfigurations={taxConfigurations}
                                            defaultTaxConfigurationUuid={defaultTaxConfigurationUuid}
                                            onExternalNavigate={onExternalNavigate}
                                        />
                                    )}
                                </Section>
                                {productData.businessType !== 'service_date_change' && (
                                    <>
                                        <Section span={12}>
                                            <TextWithHorizontalLine
                                                style={{ marginTop: '0.8rem', marginBottom: '1rem' }}
                                                text="INTEGRATIONS"
                                                alignment="left"
                                            />
                                            <ProductIds
                                                productData={productData}
                                                exeternalIds={productData.externalIds || []}
                                                integrations={integrations}
                                                missingType={missingExternalIdType}
                                                updateExternalIdValue={updateExternalIdValue}
                                                updateExternalIdsList={updateExternalIdsListData}
                                            />
                                        </Section>
                                        <Section span={12}>
                                            <TextWithHorizontalLine
                                                style={{ marginTop: '0.8rem', marginBottom: '2rem' }}
                                                text="E-TICKET"
                                                alignment="left"
                                            />
                                            <ETicketsSection
                                                accountSlug={props.accountSlug}
                                                ticketTemplatesList={ticketTemplatesList}
                                                productData={productData}
                                                onTicketTemplateSelect={onTicketTemplateSelect}
                                                onPrintTicketTemplateSelect={onPrintTicketTemplateSelect}
                                                onExternalNavigate={onExternalNavigate}
                                            />
                                        </Section>
                                        <Section span={12}>
                                            <TextWithHorizontalLine
                                                style={{ marginTop: '0.8rem', marginBottom: '1rem' }}
                                                text="BARCODES"
                                                alignment="left"
                                            />
                                            <Barcodes
                                                accountSlug={props.accountSlug}
                                                productData={productData}
                                                barcodeReservoirs={barcodeReservoirs}
                                                setProductData={(data) => setProductData(data)}
                                                onExternalNavigate={onExternalNavigate}
                                                validate={onSaveClicked}
                                            />
                                        </Section>
                                        <Section span={12}>
                                            <TextWithHorizontalLine
                                                style={{ marginTop: '0.8rem', marginBottom: '1rem' }}
                                                text="SALES LIMITS"
                                                alignment="left"
                                            />
                                            <InventorySection
                                                inventoryPools={inventoryPools}
                                                usedInventory={productData.usedResources}
                                                updateInventoryData={updateInventoryData}
                                                missingId={missingInventoryPoolId}
                                                fieldErrors={formErrors?.usedResources}
                                            />
                                        </Section>
                                        <Section span={12}>
                                            <TextWithHorizontalLine
                                                style={{ marginTop: '0.8rem', marginBottom: '1rem' }}
                                                text="OPTIONS"
                                                alignment="left"
                                            />
                                            <Options
                                                productOptions={productData.articleOptions || []}
                                                productOptionsList={productOptionsList}
                                                onSelectChange={onOptionsSelectChange}
                                                onNavigateToOptionGroups={(e) =>
                                                    onExternalNavigate(
                                                        e,
                                                        `/account/${props.accountSlug}/products/option_groups/home`,
                                                    )
                                                }
                                            />
                                        </Section>
                                    </>
                                )}
                                <PermissionRequired accountSlug={props.accountSlug} name="convious_admin">
                                    <Commission />
                                </PermissionRequired>
                                <FormActions accountSlug={props.accountSlug} viewMode={props.viewMode} />
                            </ContainerBody>
                        )}
                    </>
                )
            }}
        </FormWrapper>
    )
}

const mapStateToProps = (state: State) => {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default withFeatures(withNavigation(connect(mapStateToProps)(ProductForm)))
