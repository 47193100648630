import React, { useEffect, useState } from 'react'
import PaymentPlanInstallments from './paymentPlanInstallments'
import PaymentPlanRenewal from './paymentPlanRenewal'
import PaymentPlanPricing from './paymentPlanPricing'
import './index.scss'
import { PaymentPlan, PaymentPlansService } from '../paymentPlansService'
import { ButtonWrapper } from 'uiComponents/pageElements'
import ActionButton from 'uiComponents/buttons'
import { LoadingButton } from '@mui/lab'
import { useMessages } from 'messagesContext'
import { useHistory } from 'react-router-dom'

interface DetailPageForPaymentPlanProps {
    articleUuid: string
    paymentPlans: PaymentPlan[]
    paymentPlansService: PaymentPlansService
    accountSlug: string
    hasPermission: (permission: string, accountSlug: string) => boolean
    viewMode: 'flat' | 'nested' | ''
}

export const DetailPageForPaymentPlan = ({
    paymentPlans,
    paymentPlansService,
    accountSlug,
    articleUuid,
    hasPermission,
    viewMode,
}: DetailPageForPaymentPlanProps) => {
    const canEdit = hasPermission('edit_pricing_settings', accountSlug)
    const { replaceMessages } = useMessages()
    const history = useHistory()

    const [renewAutomatically, setRenewAutomatically] = useState(true)
    const [renewIntoMonthly, setRenewIntoMonthly] = useState(false)
    const [allowYearlyInstallments, setAllowYearlyInstallments] = useState(true)
    const [allowMonthlyInstallments, setAllowMonthlyInstallments] = useState(false)
    const [totalPriceYearly, setTotalPriceYearly] = useState(0)
    const [totalPriceMonthly, setTotalPriceMonthly] = useState(0)
    const [initialPayment, setInitialPayment] = useState(0)
    const [newPaymentPlans, setNewPaymentPlans] = useState<PaymentPlan[]>(paymentPlans)
    const [errors, setErrors] = useState<Record<string, string>>({})
    const [isSaving, setIsSaving] = useState(false)

    const inferSettingsFromPaymentPlans = (paymentPlans: PaymentPlan[]) => {
        if (paymentPlans.length === 0) {
            return
        }
        const yearlyPaymentPlan = paymentPlans.find((plan) => plan.paymentInterval === 'P1Y')
        const monthlyPaymentPlan = paymentPlans.find((plan) => plan.paymentInterval === 'P1M')

        if (yearlyPaymentPlan) {
            setAllowYearlyInstallments(true)
            setTotalPriceYearly(yearlyPaymentPlan?.totalPrice || 0)
            if (yearlyPaymentPlan.automaticRenewal) {
                setRenewAutomatically(true)
                if (yearlyPaymentPlan.automaticRenewal === 'P1M') {
                    setRenewIntoMonthly(true)
                }
            }
        } else {
            setAllowYearlyInstallments(false)
        }

        if (monthlyPaymentPlan) {
            setAllowMonthlyInstallments(true)
            setTotalPriceMonthly(monthlyPaymentPlan?.totalPrice || 0)
            setInitialPayment(monthlyPaymentPlan?.initialPayment || 0)
        }
    }

    const updatePaymentPlans = () => {
        const newPaymentPlans = []
        if (allowYearlyInstallments) {
            const yearlyPaymentPlan: PaymentPlan = {
                paymentInterval: 'P1Y',
                totalPrice: totalPriceYearly,
                automaticRenewal: renewAutomatically ? (renewIntoMonthly ? 'P1M' : 'P1Y') : undefined,
            }
            newPaymentPlans.push(yearlyPaymentPlan)
        }
        if (allowMonthlyInstallments) {
            const monthlyPaymentPlan: PaymentPlan = {
                paymentInterval: 'P1M',
                totalPrice: totalPriceMonthly,
                initialPayment: initialPayment,
                automaticRenewal: renewAutomatically ? 'P1M' : undefined,
            }
            newPaymentPlans.push(monthlyPaymentPlan)
        }
        setNewPaymentPlans(newPaymentPlans)
    }

    useEffect(() => {
        inferSettingsFromPaymentPlans(newPaymentPlans)
    }, [])

    useEffect(updatePaymentPlans, [
        allowYearlyInstallments,
        allowMonthlyInstallments,
        totalPriceYearly,
        totalPriceMonthly,
        initialPayment,
        renewAutomatically,
        renewIntoMonthly,
    ])

    const clearErrors = (errorKeys: string[]) => {
        setErrors((prev) => {
            const newErrors = { ...prev }
            errorKeys.forEach((key) => delete newErrors[key])
            return newErrors
        })
    }

    const validatePaymentPlans = (): Promise<void> => {
        const errors: Record<string, string> = {}
        if (!allowYearlyInstallments && !allowMonthlyInstallments) {
            errors.installments = 'Select at least one payment plan'
        }
        if (allowYearlyInstallments) {
            if (!totalPriceYearly) {
                errors.totalPriceYearly = 'Cannot be empty or 0'
            }
        }
        if (allowMonthlyInstallments) {
            if (!totalPriceMonthly) {
                errors.totalPriceMonthly = 'Cannot be empty or 0'
            }
            if (initialPayment === totalPriceMonthly) {
                errors.monthlyPayment = 'Cannot be empty or 0'
            }
        }
        setErrors(errors)

        if (Object.keys(errors).length > 0) {
            return Promise.reject(errors)
        }
        return Promise.resolve()
    }

    const createPaymentPlans = async () => {
        setIsSaving(true)
        await paymentPlansService
            .createPaymentPlans(accountSlug, articleUuid, newPaymentPlans)
            .then(() => {
                replaceMessages('success', 'success', 'Pricing settings saved successfully')
            })
            .catch(() => {
                replaceMessages('server_error', 'error', 'Failed to save pricing settings')
            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    return (
        <>
            <div className="payment-plan">
                <div className="payment-plan-column">
                    <PaymentPlanInstallments
                        allowYearlyInstallments={allowYearlyInstallments}
                        allowMonthlyInstallments={allowMonthlyInstallments}
                        setAllowYearlyInstallments={(value) => {
                            clearErrors(['installments'])
                            setAllowYearlyInstallments(value)
                        }}
                        setAllowMonthlyInstallments={(value) => {
                            clearErrors(['installments'])
                            setAllowMonthlyInstallments(value)
                        }}
                        error={errors.installments}
                    />
                    <PaymentPlanPricing
                        totalPriceYearly={totalPriceYearly}
                        totalPriceMonthly={totalPriceMonthly}
                        allowYearlyInstallments={allowYearlyInstallments}
                        allowMonthlyInstallments={allowMonthlyInstallments}
                        initialPayment={initialPayment || Number((totalPriceMonthly / 12).toFixed(2))}
                        onTotalPriceYearlyChange={(value) => {
                            clearErrors(['totalPriceYearly'])
                            setTotalPriceYearly(value)
                        }}
                        onTotalPriceMonthlyChange={(value) => {
                            clearErrors(['totalPriceMonthly'])
                            setInitialPayment(Number((value / 12).toFixed(2)))
                            setTotalPriceMonthly(value)
                        }}
                        onInitialPaymentChange={(value) => {
                            clearErrors(['initialPayment', 'monthlyPayment'])
                            setInitialPayment(value)
                        }}
                        accountSlug={accountSlug}
                        errors={errors}
                    />
                </div>
                <div className="payment-plan-column">
                    <PaymentPlanRenewal
                        renewAutomatically={renewAutomatically}
                        renewIntoMonthly={renewIntoMonthly}
                        onRenewAutomaticallyChange={setRenewAutomatically}
                        onRenewIntoMonthlyChange={setRenewIntoMonthly}
                    />
                </div>
            </div>
            <div className="payment-plan-footer">
                <ButtonWrapper>
                    <ActionButton
                        size="large"
                        secondary
                        onClick={() => {
                            history.push(`/account/${accountSlug}/products/home/${viewMode}`)
                        }}
                    >
                        {canEdit ? 'Cancel' : 'Back'}
                    </ActionButton>
                    {canEdit && (
                        <LoadingButton
                            id="saveSettings"
                            type="button"
                            size="large"
                            variant="contained"
                            style={{ marginLeft: '1.5em' }}
                            loading={isSaving}
                            onClick={() => validatePaymentPlans().then(createPaymentPlans)}
                        >
                            Save
                        </LoadingButton>
                    )}
                </ButtonWrapper>
            </div>
        </>
    )
}
export default DetailPageForPaymentPlan
