import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { History } from 'history'
import { withFeatures } from 'features'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { delay } from 'utils'
import { MessageKind } from 'uiComponents/messages'
import { PageLoader } from 'uiComponents/loaders'
import PricingForm from './pricingForm'
import DetailPageForDynamic from './dynamic/detailPage'
import DetailPageForUpsellStatic from './upsell_static/detailPage'
import DetailPageForPaymentPlan from './paymentPlan'
import { HorizontalLine } from 'uiComponents/pageElements'
import { ProductContainerBody } from 'uiComponents/settingsContainer'
import Infotip from 'uiComponents/infotip'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { ProductListWithPath } from 'products/crud/common'
import ExceptionsTable from './exceptions/exceptionsTable'
import { ArticleService as AdminArticleService } from 'admin/articleService'
import { PricingService, PricingData } from './pricingService'
import { ArticleConfigurationService, PricingType } from 'products/articleConfigurationService'
import ExternalConfigurationSection from './externalConfigurations'
import { ChannelsService } from 'channels/channelsService'
import { PaymentPlan, PaymentPlansService } from './paymentPlansService'

export interface Validity {
    valid: boolean
    message: string
}
export interface Validities {
    [key: string]: Validity
}

interface ProductPricingPageParams {
    accountSlug: string
    id: string
}

interface ProductPricingPageProps {
    history: History
    match: RouteMatch<ProductPricingPageParams>
    hasFeature: (feature: string, slug: string) => boolean
    pricingService: PricingService
    articleConfigurationService: ArticleConfigurationService
    articleService: AdminArticleService
    paymentPlansService: PaymentPlansService
    channelsService: ChannelsService
    navigation: Navigation
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideTopMessage: (id: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    className?: string
    viewMode: 'flat' | 'nested' | ''
}

interface ProductPricingPageState {
    pricingData: PricingData | null
    paymentPlans: PaymentPlan[] | null
    loading: boolean
    accountSlug: string
    productId: string
    productName: string | null
    pricingType: PricingType | null
    categoryIds: string[] | null
    backendError: boolean
    flattenedCategories: ProductListWithPath[] | null
}

class ProductPricingPageRouter extends React.Component<ProductPricingPageProps, ProductPricingPageState> {
    constructor(props: ProductPricingPageProps) {
        super(props)
        this.state = {
            pricingData: null,
            paymentPlans: null,
            loading: false,
            accountSlug: this.props.match.params.accountSlug,
            productId: this.props.match.params.id,
            productName: null,
            pricingType: null,
            categoryIds: null,
            backendError: false,
            flattenedCategories: null,
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        await this.getPricingDetails()
    }

    async componentDidUpdate(prevProps: ProductPricingPageProps) {
        if (prevProps.match.params.accountSlug !== this.props.match.params.accountSlug) {
            this.props.history.push(`/account/${this.props.match.params.accountSlug}/products/home/nested`)
        }
    }

    updateAfterSave = async () => {
        await this.getData()
    }

    getPricingDetails = async () => {
        try {
            await this.getData()
            this.props.setActiveSection('productsList')
        } catch {
            this.props.replaceTopMessages('server_error', 'error', 'Oops! An unknown error occured.')
            this.setState({ backendError: true, loading: false })
        }
    }

    getData = async () => {
        const id = this.state.productId
        const ids = [id]
        const article = await this.props.articleService.fetchArticle(this.state.accountSlug, id)
        this.setState({
            productName: article.name.text.en,
            pricingType: article.pricingType,
            categoryIds: article.productsListIds || null,
        })

        if (article.pricingType === 'payment_plan') {
            const paymentPlans = await this.props.paymentPlansService.getPaymentPlans(this.state.accountSlug, id)
            this.setState({
                paymentPlans,
                loading: false,
            })
        } else {
            const data = await this.props.pricingService.getProductPricing(ids)
            if (data.length === 0) {
                this.props.replaceTopMessages(
                    'server_error',
                    'warn',
                    'Oops! No pricing settings exist for this article.',
                )
                this.setState({ loading: false })
                await delay(3000)
                this.props.hideTopMessage('server_error')
                return
            }
            const pricingData = data[0]
            this.setState({
                pricingData: pricingData,
                loading: false,
            })
        }
    }

    render() {
        const {
            backendError,
            loading,
            pricingData,
            paymentPlans,
            accountSlug,
            productId,
            pricingType,
            productName,
            categoryIds,
            flattenedCategories,
        } = this.state

        const {
            className,
            history,
            viewMode,
            pricingService,
            paymentPlansService,
            replaceTopMessages,
            hideTopMessage,
            hasPermission,
            articleService,
            channelsService,
        } = this.props

        return (
            <ProductContainerBody className={className} id="pricing-settings-detail-page">
                {loading && <PageLoader />}
                {!backendError && !loading && (
                    <>
                        <ExternalConfigurationSection
                            history={history}
                            accountSlug={accountSlug}
                            id={this.props.match.params.id}
                            viewMode={viewMode}
                        />
                        {pricingType === 'payment_plan' && (
                            <DetailPageForPaymentPlan
                                articleUuid={productId}
                                paymentPlans={paymentPlans ?? []}
                                paymentPlansService={paymentPlansService}
                                accountSlug={accountSlug}
                                hasPermission={hasPermission}
                                viewMode={viewMode}
                            />
                        )}
                        {pricingType === 'rtp' && (
                            <PricingForm
                                pricingService={pricingService}
                                pricingData={pricingData}
                                productName={productName}
                                categoryIds={categoryIds}
                                viewMode={viewMode}
                                allCategories={flattenedCategories || []}
                                updateAfterSave={this.updateAfterSave}
                                replaceMessages={replaceTopMessages}
                                hideMessage={hideTopMessage}
                            />
                        )}
                        {pricingType === 'nyop' && (
                            <DetailPageForDynamic
                                pricingService={pricingService}
                                history={history}
                                replaceTopMessages={replaceTopMessages}
                                hideTopMessage={hideTopMessage}
                                hasPermission={hasPermission}
                                pricingData={pricingData}
                                productName={productName ? productName : ''}
                                updateAfterSave={this.updateAfterSave}
                                viewMode={viewMode}
                                allCategories={flattenedCategories || []}
                            />
                        )}
                        {(pricingType === 'upsell' || pricingType === 'static') && (
                            <DetailPageForUpsellStatic
                                pricingService={pricingService}
                                history={history}
                                replaceTopMessages={replaceTopMessages}
                                hideTopMessage={hideTopMessage}
                                hasPermission={hasPermission}
                                pricingData={pricingData}
                                productName={productName ? productName : ''}
                                pricingType={pricingType}
                                updateAfterSave={this.updateAfterSave}
                                categoryIds={categoryIds}
                                viewMode={viewMode}
                                allCategories={flattenedCategories || []}
                            />
                        )}
                    </>
                )}
                {pricingData && (
                    <>
                        <HorizontalLine />
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: '1em',
                                fontSize: '.85em',
                            }}
                        >
                            Rules that override your default pricing settings
                            <Infotip pointer="left" maxWidth="18em">
                                If you have overwritten your default prices with exceptions they will be displayed here
                            </Infotip>
                        </div>
                        {pricingData.overrides.length > 0 && (
                            <ExceptionsTable
                                productId={productId}
                                pricingService={pricingService}
                                articleService={articleService}
                                channelsService={channelsService}
                                accountSlug={accountSlug}
                                handleError={() => {}}
                                style={{ paddingLeft: '0' }}
                                hasPermission={hasPermission}
                            />
                        )}
                        {pricingData.overrides.length === 0 && (
                            <NoResultsRow text="This product's pricing settings have no exceptions" />
                        )}
                    </>
                )}
            </ProductContainerBody>
        )
    }
}

export default withNavigation(withFeatures(ProductPricingPageRouter))
