import React, { useState, useEffect } from 'react'
import { History } from 'history'
import { TableLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { InlineDataTable, HeaderRow, TableHeader, Cell, ExpandCell, DynamicRow } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { OptionsService, OptionGroup, getOptionItemPrice, sortByPriority } from 'products/options/optionsService'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { Col } from 'uiComponents/flex'
import { IconBox, IconWrapper, IconRow, MenuIcon } from 'products/actionMenu'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { faTrash, faPencilAlt, faPlus } from '@fortawesome/pro-light-svg-icons'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import { OptionItemsRowCells } from './optionItemsRowCells'
import { PricingServiceContext } from 'products/pricing/context'
import { flatMap } from 'utils'
import { PricingData } from 'products/pricing/pricingService'

export const optionsLisColumnWidths = ['3.5em', null, '10em', '15em', '11.5em']

interface OptionGroupsListPageProps {
    navigation: Navigation
    match: RouteMatch<{}>
    history: History
    accountSlug: string
    optionsService: OptionsService
    setActiveSection: (section: string, header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function OptionGroupsListPage(props: OptionGroupsListPageProps) {
    const pricingService = React.useContext(PricingServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [optionGroupsList, setOptionGroupsList] = useState<OptionGroup[]>([])
    const [currentExpanded, setCurrentExpanded] = useState<string>('')
    const [groupToDelete, setGroupToDelete] = useState('')

    function mapItemPrices(data: OptionGroup[], pricing: PricingData[]) {
        return data.map((d) => ({
            ...d,
            optionItems: d.optionItems
                .map((oi) => ({
                    ...oi,
                    price: getOptionItemPrice(oi.pricingId, pricing),
                }))
                .sort(sortByPriority),
        }))
    }

    async function getOptionGroupsList() {
        setLoading(true)
        try {
            const data = await props.optionsService.getOptionGroups(props.accountSlug)
            data.sort(sortByPriority)
            const pricingIds = flatMap(data, (x) => x.optionItems.map((oi) => oi.pricingId))
            const pricing = await pricingService.getProductPricing(pricingIds)
            const query = props.navigation.query()
            setCurrentExpanded(query.optionGroupId || '')
            setOptionGroupsList(mapItemPrices(data, pricing))
            setLoading(false)
        } catch {
            setLoading(false)
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    useEffect(() => {
        props.setActiveSection('optionGroups', 'Option Groups')
        getOptionGroupsList()
    }, [props.accountSlug])

    const toggleExpanded = (id: string) => {
        const expanded = currentExpanded === id
        expanded ? setCurrentExpanded('') : setCurrentExpanded(id)
        props.navigation.addQueryWithReplace({
            optionGroupId: expanded ? null : id,
        })
    }

    const onDeleteConfirm = async () => {
        try {
            await props.optionsService.deleteOptionGroup(props.accountSlug, groupToDelete)
            setGroupToDelete('')
            getOptionGroupsList()
        } catch {
            props.replaceMessages(
                'delete_error',
                'error',
                'Oops! Could not delete the option group. Please try again later.',
            )
            setGroupToDelete('')
        }
    }

    return (
        <>
            {!!groupToDelete && (
                <ConfirmationDialog
                    title="Delete the group?"
                    text="Are you sure you want to delete this option group?"
                    buttonText="Delete"
                    destructive
                    onCancel={() => setGroupToDelete('')}
                    onConfirm={onDeleteConfirm}
                    loading={loading}
                />
            )}
            <InlineDataTable columnWidths={optionsLisColumnWidths} bordered id="option-groups-list">
                <HeaderRow>
                    <TableHeader nonInteractive noPadding />
                    <TableHeader>Name</TableHeader>
                    <TableHeader align="center">Priority</TableHeader>
                    <TableHeader>Price</TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {loading && <TableLoader />}
                {!loading && (
                    <>
                        {optionGroupsList.length === 0 && <NoResultsRow text="There are no option groups yet." />}
                        {optionGroupsList.map((group) => {
                            const expanded = group.uuid === currentExpanded
                            return (
                                <div key={group.uuid}>
                                    <DynamicRow
                                        interactive
                                        highlighted={expanded}
                                        onClick={() => toggleExpanded(group.uuid)}
                                        className={expanded ? 'expanded optionGroupsRow' : 'optionGroupsRow'}
                                    >
                                        <ExpandCell className="expand" expanded={expanded} />
                                        <Cell title={group.name} className="withName">
                                            <span>{group.name}</span>
                                        </Cell>
                                        <Cell align="center">{group.priority}</Cell>
                                        <Cell />
                                        <Cell align="right" onClick={(e) => e.stopPropagation()}>
                                            <IconRow>
                                                <Col span={4}>
                                                    <IconBox title="Add new option item">
                                                        <UnstyledLink
                                                            to={`/account/${props.accountSlug}/products/option_groups/option_item/${group.uuid}/new`}
                                                        >
                                                            <IconWrapper>
                                                                <MenuIcon icon={faPlus} />
                                                            </IconWrapper>
                                                        </UnstyledLink>
                                                    </IconBox>
                                                </Col>
                                                <Col span={4}>
                                                    <IconBox title="Option group details">
                                                        <UnstyledLink
                                                            to={`/account/${props.accountSlug}/products/option_groups/group_details/${group.uuid}`}
                                                        >
                                                            <IconWrapper>
                                                                <MenuIcon icon={faPencilAlt} />
                                                            </IconWrapper>
                                                        </UnstyledLink>
                                                    </IconBox>
                                                </Col>
                                                <Col span={4}>
                                                    <IconBox
                                                        title="Remove option group"
                                                        onClick={() => setGroupToDelete(group.uuid)}
                                                    >
                                                        <IconWrapper>
                                                            <MenuIcon icon={faTrash} />
                                                        </IconWrapper>
                                                    </IconBox>
                                                </Col>
                                            </IconRow>
                                        </Cell>
                                    </DynamicRow>
                                    {expanded && (
                                        <NestedContainerWrapper grade={1} className="expanded">
                                            {group.optionItems.length === 0 && (
                                                <div style={{ padding: '1em 4.8em' }}>
                                                    There are no option items in this group yet.
                                                </div>
                                            )}
                                            {group.optionItems.length > 0 &&
                                                group.optionItems.map((item) => {
                                                    return (
                                                        <div key={item.id}>
                                                            <DynamicRow
                                                                interactive
                                                                narrow
                                                                onClick={() =>
                                                                    props.history.push(
                                                                        `/account/${props.accountSlug}/products/option_groups/option_item/${group.uuid}/${item.id}`,
                                                                    )
                                                                }
                                                                className="optionGroupItemRow"
                                                            >
                                                                <OptionItemsRowCells
                                                                    optionItem={item}
                                                                    groupUuid={group.uuid}
                                                                    accountSlug={props.accountSlug}
                                                                    history={props.history}
                                                                    optionsService={props.optionsService}
                                                                    replaceMessages={props.replaceMessages}
                                                                    nested
                                                                    loading={loading}
                                                                    reloadList={getOptionGroupsList}
                                                                />
                                                            </DynamicRow>
                                                        </div>
                                                    )
                                                })}
                                        </NestedContainerWrapper>
                                    )}
                                </div>
                            )
                        })}
                    </>
                )}
            </InlineDataTable>
        </>
    )
}

export default withNavigation(OptionGroupsListPage)
