import * as React from 'react'
import { Switch, Route, Redirect, useParams } from 'react-router-dom'
import WithRoutePermission from '../guards/withRoutePermission'
import { BaseRouteParams } from 'hocs'
import { AppServices } from 'middleware'
import { ConfigReader } from 'utils/confiReader'

import OpeningTimesPage from 'venue/openingTimes'
import BookingCodesPage from 'venue/bookingCodes'
import ParkMapRoutes from './parkMapRoutes'

function VenueRoutes() {
    const { accountSlug } = useParams<BaseRouteParams>()

    return (
        <Switch>
            <Route
                path="/account/:accountSlug/venue/opening_times"
                render={({ history, location }) => (
                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                        <OpeningTimesPage accountSlug={accountSlug} history={history} location={location} />
                    </WithRoutePermission>
                )}
            />
            <Route
                path="/account/:accountSlug/venue/codes"
                render={({ history, location }) => (
                    <WithRoutePermission requiredPermissions={['access_codes']}>
                        <BookingCodesPage
                            accountSlug={accountSlug}
                            history={history}
                            location={location}
                            articleService={AppServices.articleService}
                            loginService={AppServices.loginService}
                            bookingCodesService={AppServices.bookingCodesService}
                            backofficeEndpoint={ConfigReader.getBackOfficeEndpoint()}
                        />
                    </WithRoutePermission>
                )}
            />
            <Route path="/account/:accountSlug/venue/park_map" render={ParkMapRoutes} />
            <Route render={() => <Redirect to={`/account/${accountSlug}/venue/opening_times`} />} />
        </Switch>
    )
}

export default VenueRoutes
