import * as React from 'react'
import styled from 'styled-typed'
import MobileHeader from './mobileHeader'

const HeaderWrapper = styled.div`
    > #cp-mobile-header {
        display: none;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        z-index: 101;
        top: 0;
        right: 0;
        position: sticky;

        > #cp-mobile-header {
            display: flex;
        }
    }
`

function Header() {
    return (
        <HeaderWrapper>
            <MobileHeader />
        </HeaderWrapper>
    )
}

export default Header
