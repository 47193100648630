import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useAppSelector } from 'store/hooks'
import { withFeatures } from 'features'
import { SelectedForRefund, TicketsForRefund } from '../types'
import CancelRefundDialog from '../components/dialog'
import { RefundPaypalDetails } from 'orders/paypalDisputes/schema'
import { calculateRefundFee, getTicketsForRefund } from 'orders/utils/calculateOrderRefund'
import { CurrencyProps, withCurrency } from 'uiComponents/money/moneyHoc'
import { useGetOrderDetailsForRefundQuery, useRefundOrderMutation } from 'orders/reduxQueries'
import {
    calculateTotalAmount,
    calculateTotalBookingFeeAmount,
    getAllSelectedList,
    updateBookingFeeSelection,
    updateTicketSelection,
} from './utils'
import { mapFooterData, mapHeaderData, prepareTableData } from './mappings'
import { delay, flatMap } from 'utils'
import { generateOnRefundErrorMessage } from 'orders/utils/orderRefundErrorMessage'
import { ReplaceMessagesFunc } from 'messagesContext'

interface SingleCancelRefundProps extends CurrencyProps {
    accountSlug: string
    orderId: string
    isCancellation: boolean
    hasFeature: (feature: string, slug: string) => boolean
    paypalRefund?: RefundPaypalDetails
    onClose: () => void
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    replaceMessages: ReplaceMessagesFunc
}

const SingleCancelRefund = ({
    accountSlug,
    paypalRefund,
    hasFeature,
    isCancellation,
    orderId,
    replaceMessages,
    onClose,
    formatCurrencyString,
}: SingleCancelRefundProps) => {
    const { data: order, isLoading, isError: getOrderDetailsError } = useGetOrderDetailsForRefundQuery({ orderId })
    const [refundOrder, { error: refundOrderError, isLoading: refunding }] = useRefundOrderMutation()
    const selectedItems = useAppSelector((state) => state.orderTable.selectedItems)
    const [ticketsForRefund, setTicketsForRefund] = useState<TicketsForRefund[]>([])
    const [selectedTickets, setSelectedTickets] = useState<SelectedForRefund[]>([])
    const [cancelRefundReason, setCancelRefundReason] = useState<string>()
    const [confirmMode, setConfirmMode] = useState<boolean>(false)
    const [partnerRefundFeeOff, setPartnerRefundFeeOff] = useState<boolean>(true)
    const [selectRefundFee, setSelectRefundFee] = useState<boolean>(true)
    const partialRefundsDisallowed = !hasFeature('OrderPartialRefunds', accountSlug)
    const fullRefundOnly = !isCancellation && (partialRefundsDisallowed || (!!order && !order.canPartialRefund))
    const user = useAppSelector((state) => state.auth.user)
    const bookingFeesEnabled = hasFeature('BookingFees', accountSlug)

    useEffect(() => {
        if (order) {
            setPartnerRefundFeeOff(!order.partnerRefundFee)
            const tickets = getTicketsForRefund(order)
            setTicketsForRefund(tickets)
            setSelectedTickets(getAllSelectedList(tickets, selectedItems, orderId))
        }

        if (getOrderDetailsError) {
            onClose()
            replaceMessages(
                'unknown_error',
                'error',
                `Oops! There was a problem with getting order ${orderId} details for refund`,
            )
        }

        if (refundOrderError) {
            onClose()
            replaceMessages(
                'unknown_error',
                'error',
                generateOnRefundErrorMessage(refundOrderError as Error, isCancellation, order?.id ?? ''),
            )
        }
    }, [order, getOrderDetailsError, refundOrderError])

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCancelRefundReason(event.target.value)
    }

    const handleItemSelect = useCallback(
        (orderId: string, ticketId?: string) => {
            if (!ticketId) {
                return
            }

            const updatedSelection = updateTicketSelection(orderId, ticketId, ticketsForRefund, selectedTickets)
            setSelectedTickets(updatedSelection)
        },
        [selectedTickets, ticketsForRefund, updateTicketSelection],
    )

    const handleBookingFeeSelect = useCallback(
        (orderId: string, uuid?: string) => {
            if (!uuid) {
                return
            }

            const updatedSelection = updateBookingFeeSelection(orderId, uuid, selectedTickets)
            setSelectedTickets(updatedSelection)
        },
        [selectedTickets, ticketsForRefund, updateTicketSelection],
    )

    const onCancel = () => {
        if (confirmMode) {
            setConfirmMode(false)
        } else {
            onClose()
        }
    }

    const onNext = () => {
        if (confirmMode) {
            refund()
        } else {
            setConfirmMode(true)
        }
    }

    const refund = async () => {
        const response = await refundOrder({
            accountSlug,
            orderId,
            selectedForRefundOrderItems: selectedTickets,
            refundReason: cancelRefundReason ?? '',
            forgoPartnerRefundFee: !selectRefundFee,
            isCancellation,
        }).unwrap()

        if (response) {
            onClose()
            const message = isCancellation
                ? `Order ${order?.id} cancellation request was successful`
                : `Order ${order?.id} refund request was successfully placed and is being processed`
            replaceMessages('refunded_successfully', 'success', message)
            await delay(5000)
            replaceMessages('refunded_successfully', 'success', message)
        } else {
            onClose()
        }
    }

    const showPartialRedeemWarning = useMemo(() => {
        const allTicketItems = flatMap(
            ticketsForRefund.map((t) => t.items),
            (x) => x,
        )
        const redeemedItems = allTicketItems.filter((i) => !i.itemRefundable)
        return redeemedItems.length > 0
    }, [ticketsForRefund])

    const totalAmount = useMemo(
        () =>
            calculateTotalAmount(
                order?.totalDiscountedPriceInclTax ?? '0',
                fullRefundOnly,
                ticketsForRefund,
                selectedTickets,
            ),
        [order, fullRefundOnly, ticketsForRefund, selectedTickets],
    )

    const totalBookingFeeAmount = useMemo(() => {
        return calculateTotalBookingFeeAmount(ticketsForRefund, selectedTickets)
    }, [ticketsForRefund, selectedTickets])

    const partnerRefundFeeAmount = useMemo(() => {
        if (!order || partnerRefundFeeOff || !selectRefundFee || isCancellation) {
            return 0
        }
        return calculateRefundFee(
            Number(totalAmount) + Number(totalBookingFeeAmount),
            Number(order.partnerRefundFee),
            order.partnerRefundFeeType,
        )
    }, [order, totalAmount, selectRefundFee, totalBookingFeeAmount])

    const totalAmountToRefund = useMemo(() => {
        return Number(totalAmount) - partnerRefundFeeAmount + Number(totalBookingFeeAmount)
    }, [totalAmount, partnerRefundFeeAmount, totalBookingFeeAmount])

    const tableData = useMemo(() => {
        return prepareTableData({
            tickets: ticketsForRefund,
            selectedTickets: selectedTickets,
            totalAmount: totalAmountToRefund,
            partnerRefundFeeAmount: partnerRefundFeeAmount,
            partnerRefundFeeOff: partnerRefundFeeOff,
            partnerRefundFeeSelected: selectRefundFee,
            showPartialRedeemWarning: showPartialRedeemWarning,
            partnerRefundFee: order?.partnerRefundFee,
            partnerRefundFeeType: order?.partnerRefundFeeType,
            bookingFeesEnabled,
            confirmMode,
            isCancellation,
        })
    }, [
        ticketsForRefund,
        selectedTickets,
        totalAmountToRefund,
        partnerRefundFeeAmount,
        partnerRefundFeeOff,
        selectRefundFee,
        showPartialRedeemWarning,
        order,
        bookingFeesEnabled,
        confirmMode,
        isCancellation,
    ])

    const footerProps = useMemo(
        () =>
            mapFooterData({
                accountSlug,
                confirmMode,
                isCancellation,
                fullRefundOnly,
                refunding,
                onCancel,
                onNext,
                totalAmount: totalAmountToRefund,
                noTicketsSelected: selectedTickets.length === 0,
                refundDisallowed: partnerRefundFeeAmount > Number(totalAmount) && !isCancellation,
                formatCurrencyString,
                customerEmail: order?.customer?.email,
            }),
        [
            accountSlug,
            confirmMode,
            isCancellation,
            fullRefundOnly,
            refunding,
            onCancel,
            onNext,
            totalAmountToRefund,
            selectedTickets,
            partnerRefundFeeAmount,
            totalAmount,
            formatCurrencyString,
            order,
        ],
    )

    const notesProps = useMemo(
        () => ({ cancelRefundReason, handleTextAreaChange, isCancellation }),
        [cancelRefundReason, handleTextAreaChange, isCancellation],
    )

    const headerProps = useMemo(
        () =>
            mapHeaderData({
                accountSlug,
                user,
                isCancellation,
                formatCurrencyString,
                order,
                paypalRefund,
                onClose,
            }),
        [accountSlug, order, user, isCancellation, formatCurrencyString, paypalRefund],
    )

    return (
        <CancelRefundDialog
            accountSlug={accountSlug}
            headerProps={headerProps}
            footerProps={footerProps}
            tableData={tableData}
            confirmMode={confirmMode}
            loading={isLoading}
            refunding={refunding}
            handleItemSelect={handleItemSelect}
            handleRefundFeeSelect={() => setSelectRefundFee((prev) => !prev)}
            handleBookingFeeSelect={handleBookingFeeSelect}
            notesProps={notesProps}
        />
    )
}

export default withFeatures(withCurrency(SingleCancelRefund))
