import { find } from 'lodash'
import { RefundTicket } from './types'
import { ICancelRefundTableRow } from './components/cancelRefundTable/types'

export interface BookingFeeRowData extends ICancelRefundTableRow {
    amountOfProducts: number
    amountOfSelectedProducts: number
    productName: string
}

export const getBookingFeesRowsData = (
    rowsData: BookingFeeRowData[],
    ticket: RefundTicket,
    isSelected: boolean,
    productName: string,
    orderItemId?: string,
    isBundle?: boolean,
) => {
    for (const bookingFee of ticket.bookingFees) {
        const bookingFeeExist = find(rowsData, { id: bookingFee.uuid })

        if (bookingFeeExist) {
            bookingFeeExist.amount += isSelected ? Number(bookingFee.calculatedAmountInclTax) : 0
            bookingFeeExist.amountOfProducts += 1
            if (isSelected) {
                bookingFeeExist.amountOfSelectedProducts += 1
            }

        } else {
            rowsData.push({
                id: bookingFee.uuid,
                rowType: 'bookingFee',
                name: bookingFee.title,
                description: '1/1 products selected',
                infoTip: 'none',
                amount: isSelected ? Number(bookingFee.calculatedAmountInclTax) : 0,
                selected: true,
                orderItemId: orderItemId || '',
                amountOfProducts: 1,
                amountOfSelectedProducts: isSelected ? 1 : 0,
                productName,
            })
        }
    }
}
