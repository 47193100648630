import { getTotalAmount } from 'orders/utils/calculateOrderRefund'
import { SelectedForRefund, TicketsForRefund } from '../types'
import { cloneDeep } from 'lodash'

export const getSelectedBookingFees = (
    ticketForRefund: TicketsForRefund,
    preselectedItems: '' | string[] | undefined,
): string[] => {
    const bookingFeesUuids: string[] = []

    ticketForRefund.items.forEach((i) => {
        if (i.itemRefundable) {
            i.ticketEntity.bookingFees.forEach((b) => {
                if (
                    !bookingFeesUuids.includes(b.uuid) &&
                    ((preselectedItems && preselectedItems.some((uuid) => i.ticketUuids.includes(uuid))) ||
                        preselectedItems?.length === 0)
                ) {
                    bookingFeesUuids.push(b.uuid)
                }
            })
        }
    })

    return bookingFeesUuids
}

export const getAllSelectedList = (
    ticketsForRefund: TicketsForRefund[] = [],
    selectedItems: Record<string, Record<string, boolean>>,
    orderUuid?: string,
) => {
    const preselectedItems =
        selectedItems &&
        orderUuid &&
        selectedItems[orderUuid] &&
        Object.entries(selectedItems[orderUuid])
            .filter(([, v]) => v)
            .map(([k]) => k)

    const allItems = ticketsForRefund
        .map((t) => {
            return {
                uuid: t.orderItemId,
                ticketUuids: t.items
                    .filter((i) => i.itemRefundable)
                    .flatMap((i) => i.ticketUuids)
                    .filter((t) =>
                        preselectedItems && preselectedItems.length > 0 ? preselectedItems.includes(t) : true,
                    ),
                bookingFeeUuids: getSelectedBookingFees(t, preselectedItems),
            }
        })
        .filter((t) => t.ticketUuids.length > 0)

    return [...allItems]
}

export const filterTicketsForConfirmation = (
    tickets: TicketsForRefund[],
    selectedTicketsForRefund: SelectedForRefund[],
) => {
    const selectedOrderItemIds = selectedTicketsForRefund.map((t) => t.uuid)
    const selectedOrderItems = tickets.filter((t) => selectedOrderItemIds.includes(t.orderItemId) && t.canRefund)
    return selectedOrderItems.map((t) => {
        const selectedTicketsUuids = selectedTicketsForRefund.find((st) => st.uuid === t.orderItemId)?.ticketUuids || []
        return {
            ...t,
            items: t.items.filter((i) => selectedTicketsUuids.includes(i.ticketUuids[0])),
        }
    })
}

export const calculateTotalAmount = (
    totalDiscountedPriceInclTax: string,
    fullRefundOnly: boolean,
    tickets: TicketsForRefund[],
    selectedTickets: SelectedForRefund[],
): string => {
    const ticketList = filterTicketsForConfirmation(tickets, selectedTickets)
    const refundableTicketsList = ticketList
        .filter((t) => t.canRefund)
        .map((t) => ({ ...t, items: t.items.filter((i) => i.itemRefundable) }))

    const totalAmount = fullRefundOnly ? totalDiscountedPriceInclTax : getTotalAmount(refundableTicketsList)

    return totalAmount
}

const getOrderItemBookingFeesTotalAmount = (ticket: TicketsForRefund, selectedBookingFees: string[]) => {
    return ticket.items.reduce((s, item) => {
        return (
            s +
            item.ticketEntity.bookingFees
                .filter((bookingFee) => selectedBookingFees.includes(bookingFee.uuid))
                .reduce((s, b) => s + Number(b.calculatedAmountInclTax), 0)
        )
    }, 0)
}

export const getTotalBookingFeeAmount = (tickets: TicketsForRefund[], selectedBookingFees: string[]) => {
    return tickets.reduce((s, t) => s + getOrderItemBookingFeesTotalAmount(t, selectedBookingFees), 0).toFixed(2)
}

export const calculateTotalBookingFeeAmount = (
    tickets: TicketsForRefund[],
    selectedTickets: SelectedForRefund[],
): string => {
    const ticketList = filterTicketsForConfirmation(tickets, selectedTickets)
    const refundableTicketsList = ticketList
        .filter((t) => t.canRefund)
        .map((t) => ({ ...t, items: t.items.filter((i) => i.itemRefundable) }))

    return getTotalBookingFeeAmount(refundableTicketsList, selectedTickets.flatMap((t) => t.bookingFeeUuids))
}

export const updateTicketSelection = (
    orderItemId: string,
    ticketId: string,
    ticketsForRefund: TicketsForRefund[],
    selectedTickets: SelectedForRefund[],
) => {
    let currentSelection = cloneDeep(selectedTickets)
    let updatedOrderItem = currentSelection.find((st) => st.uuid === orderItemId)

    const isBundleItem = ticketsForRefund.find((t) => t.orderItemId === orderItemId)?.isBundle ?? false

    // Helper function to get initial ticket UUIDs when the order item is not yet selected
    const getInitialTicketUuids = () => {
        if (isBundleItem) {
            const bundleOrderItem = ticketsForRefund.find((t) => t.orderItemId === orderItemId)
            const bundleTicketsIds =
                bundleOrderItem?.items.find((i) => i.ticketUuids.includes(ticketId))?.ticketUuids ?? []
            return bundleTicketsIds
        } else {
            return [ticketId]
        }
    }

    // Helper function to get ticket UUIDs to toggle when the order item is already selected
    const getTicketUuidsToToggle = () => {
        if (isBundleItem) {
            const bundleOrderItem = ticketsForRefund.find((t) => t.orderItemId === orderItemId)
            return bundleOrderItem?.items.find((i) => i.ticketUuids.includes(ticketId))?.ticketUuids ?? []
        } else {
            return [ticketId]
        }
    }

    const getInitialBookingFeeUuids = () => {
        const bookingFeeIds = ticketsForRefund
            .find((t) => t.orderItemId === orderItemId)
            ?.items.find((i) => i.ticketUuids.includes(ticketId))
            ?.ticketEntity.bookingFees.map((b) => b.uuid)
        return bookingFeeIds
    }
    
    if (!updatedOrderItem) {
        // Add the order item with the initial ticket UUIDs
        const ticketUuidsToAdd = getInitialTicketUuids()
        updatedOrderItem = { uuid: orderItemId, ticketUuids: ticketUuidsToAdd, bookingFeeUuids: getInitialBookingFeeUuids() ?? [] }
        currentSelection.push(updatedOrderItem)
    } else {
        // Toggle the ticket UUIDs
        const ticketUuidsToToggle = getTicketUuidsToToggle()
        const hasAllTickets = ticketUuidsToToggle.every((t) => updatedOrderItem?.ticketUuids.includes(t))

        if (hasAllTickets) {
            // Remove the tickets
            updatedOrderItem.ticketUuids = updatedOrderItem.ticketUuids.filter((t) => !ticketUuidsToToggle.includes(t))
            if (updatedOrderItem.ticketUuids.length === 0) {
                // Remove the order item if no tickets are selected
                currentSelection = currentSelection.filter((st) => st.uuid !== orderItemId)
            }
        } else {
            // Add the tickets
            updatedOrderItem.ticketUuids = [
                ...updatedOrderItem.ticketUuids,
                ...ticketUuidsToToggle.filter((t) => !updatedOrderItem?.ticketUuids.includes(t)),
            ]
        }
    }
    return currentSelection
}

export const updateBookingFeeSelection = (
    orderItemId: string,
    bookingFeeId: string,
    selectedTickets: SelectedForRefund[],
) => {
    let currentSelection = cloneDeep(selectedTickets)
    let updatedOrderItem = currentSelection.find((st) => st.uuid === orderItemId)

    if (!updatedOrderItem) {
        updatedOrderItem = { uuid: orderItemId, ticketUuids: [], bookingFeeUuids: [bookingFeeId] }
        currentSelection.push(updatedOrderItem)
    } else {
        const hasBookingFee = updatedOrderItem.bookingFeeUuids.includes(bookingFeeId)

        if (hasBookingFee) {
            updatedOrderItem.bookingFeeUuids = updatedOrderItem.bookingFeeUuids.filter((b) => b !== bookingFeeId)
        } else {
            updatedOrderItem.bookingFeeUuids.push(bookingFeeId)
        }
    }
    return currentSelection
}
