import React, { useMemo } from 'react'
import { FilterCategory, MetaData } from 'uiComponents/filter/schema'
import Filter from 'uiComponents/filter'
import { useAppSelector } from 'store/hooks'
import { getActiveAccount } from 'preferences/selectors'
import { applicableFilters, staticMetadaCategories } from './config'
import { useHasFeature } from 'features'
import { useGetAccountLocationsQuery } from 'orders/reduxQueries'
import { formatFlatOptions } from 'uiComponents/filter/filterHelpers'

const CartTableFilters: React.FC = () => {
    const accountSlug = useAppSelector(getActiveAccount)
    const shouldShowOpenStatus = useHasFeature('should_show_open_status')
    const locationQuery = useGetAccountLocationsQuery({ accountSlug: accountSlug! })

    if (!accountSlug) throw new Error('No account selected')

    const filteredReservationStatuses = useMemo(() => {
        return staticMetadaCategories.map((category) => {
            if (category.category === 'reservation_status') {
                return {
                    ...category,
                    options: category.options.filter((option) =>
                        shouldShowOpenStatus ? true : option.slug !== 'open',
                    ),
                }
            }
            return category
        })
    }, [shouldShowOpenStatus])

    const data = useMemo((): MetaData[] => {
        const locationCategory = {
            category: 'location_id' as FilterCategory,
            options: formatFlatOptions(
                'location_id',
                (locationQuery.data ?? []).map((l) => ({
                    name: l.name,
                    slug: l.uuid,
                })),
            ),
        }

        return [...filteredReservationStatuses, locationCategory]
    }, [filteredReservationStatuses, locationQuery.data])

    return (
        <Filter
            accountSlug={accountSlug}
            applicableFilters={applicableFilters}
            metaData={data}
            standaloneFilters={[]}
            singleSelectCategories={[]}
            compact
            loading={locationQuery.isLoading}
        />
    )
}

export default CartTableFilters
