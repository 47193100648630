import * as React from 'react'
import { StyledATag } from 'uiComponents/typography'
import { MessageKind } from 'uiComponents/messages'
import { ProductListWithPath } from 'products/crud/common'
import { NestedElement } from 'products/components/nestedContainer'
import { ArticleListItem, ProductList } from 'admin/articleService'
import { addDays, getDay, startOfMonth, startOfToday, endOfMonth, isThisMonth, isAfter } from 'date-fns'
import { format } from 'date-fns'
import { AvailabilityDates } from 'products/articleConfigurationService'
import { CalendarData } from 'products/pricing/articlePricingService'
import { CalendarPrice, PricingData } from 'products/pricing/pricingService'
import { PricingTimeslot } from '../globalPricingSettings/studio/types'

export const getColorFromWeight = (weight: number | null | undefined = 0) => {
    let dayColor
    if (!weight || weight === 0) {
        dayColor = '#8B9898'
    } else if (weight >= 1 && weight <= 3) {
        dayColor = '#00AC1E'
    } else if (weight >= 4 && weight <= 6) {
        dayColor = '#00AC1E'
    } else if (weight >= 7 && weight <= 10) {
        dayColor = '#FFAC42'
    }
    return dayColor
}

export type Availability = 'AVAILABLE' | 'NOT_AVAILABLE'
export interface Price {
    date: string
    pricing: {
        availability: Availability
        price: string
        weight: number
    }
    timeslots: PricingTimeslot[]
}

export function getSubheader(section: string) {
    let subheader = <></>
    switch (section) {
        case 'productsList':
            subheader = (
                <>
                    Create and edit categories and products. View our detailed guide&nbsp;
                    <StyledATag
                        target="_blank"
                        href="https://support.convious.com/help/how-to-create-products-and-product-lists-categories-from-your-control-panel-a-full-guide"
                    >
                        here
                    </StyledATag>
                    .
                </>
            )
            break
        case 'pricingExceptions':
            subheader = (
                <>
                    Add and edit pricing exceptions to your pricing strategy. For more instructions, visit our&nbsp;
                    <StyledATag
                        target="_blank"
                        href="https://support.convious.com/help/how-to-create-pricing-exceptions-for-your-products"
                    >
                        detailed guide on pricing exceptions
                    </StyledATag>
                    .
                </>
            )
            break
        case 'validityExceptions':
            subheader = (
                <>
                    Add and edit validity exceptions for your products’ For more instructions, visit our&nbsp;
                    <StyledATag
                        target="_blank"
                        href="https://support.convious.com/help/how-to-manage-and-edit-validity-date-settings-for-your-products"
                    >
                        detailed guide on validity and validity exceptions
                    </StyledATag>
                    .
                </>
            )
            break
        case 'timeSlots':
            subheader = (
                <>
                    Add new time slot groups or edit existing ones. Find all the information you need about time
                    slots&nbsp;
                    <StyledATag
                        target="_blank"
                        href="https://support.convious.com/help/managing-products-inventory-time-slots"
                    >
                        here
                    </StyledATag>
                    .
                </>
            )
            break
        case 'optionGroups':
            subheader = (
                <>
                    Add new options groups or items to your products or edit existing ones. You can find a step-by-step
                    guide&nbsp;
                    <StyledATag
                        target="_blank"
                        href="https://support.convious.com/help/how-to-add-options-to-your-products"
                    >
                        here
                    </StyledATag>
                    .
                </>
            )
            break
        case 'inventory':
            subheader = (
                <>
                    Manage how many people come to your venue and when. Learn more about the setup in our&nbsp;
                    <StyledATag target="_blank" href="https://support.convious.com/help/crowd-control">
                        Knowledge base
                    </StyledATag>
                    .
                </>
            )
            break
        default:
            break
    }

    return subheader
}

export function getBaseKnowledgeUrl(section: string): string {
    switch (section) {
        case 'productsList':
            return 'https://support.convious.com/help/how-to-create-products-and-product-lists-categories-from-your-control-panel-a-full-guide'
        case 'pricingExceptions':
            return 'https://support.convious.com/help/how-to-create-pricing-exceptions-for-your-products'
        case 'validityExceptions':
            return 'https://support.convious.com/help/how-to-manage-and-edit-validity-date-settings-for-your-products'
        case 'timeSlots':
            return 'https://support.convious.com/help/managing-products-inventory-time-slots'
        case 'optionGroups':
            return 'https://support.convious.com/help/how-to-add-options-to-your-products'
        case 'inventory':
            return 'https://support.convious.com/help/crowd-control'
        default:
            return ''
    }
}

export function getDuplicationMessage(productName: string) {
    return {
        id: 'duplication_warning',
        status: 'warn' as MessageKind,
        text: `This is a copy of the product '${productName}'. Please remember to update the name in all languages.
      Also note that the external id, bundle, template parameters and product option groups setting have not been copied.`,
        visible: true,
    }
}

export function getFilteredCategoryIds(pathMemberIds: string[] | null, flattenedCategories: ProductListWithPath[]) {
    if (!pathMemberIds) {
        return []
    }
    let categoryInGameIds: string[] = []
    pathMemberIds.forEach((id) => {
        const foundCat = flattenedCategories.find((cat) => cat.uuid === id)
        const parentIds = foundCat ? foundCat.parentIds : []
        categoryInGameIds = categoryInGameIds.concat(parentIds)
    })
    categoryInGameIds = categoryInGameIds.filter((item, index) => categoryInGameIds.indexOf(item) === index)
    return categoryInGameIds
}

export const fetchNewPriorities = (
    elementId: string,
    priority: number,
    formerPriority: string,
    priorities: { [index: number]: string[] },
): any => {
    const newPriorities = {
        ...priorities,
        [formerPriority]: priorities[formerPriority].filter((id: string) => id !== elementId),
    }
    if (!newPriorities[priority]) {
        newPriorities[priority] = [elementId]
    } else {
        newPriorities[priority].push(elementId)
    }
    return newPriorities
}

export const hasDuplicatePriority = (element: NestedElement, priorities: any): boolean => {
    const elementId = (element as ProductList).uuid ? (element as ProductList).uuid : (element as ArticleListItem).id
    let prio = null
    for (let key in priorities) {
        if (priorities.hasOwnProperty(key)) {
            if (priorities[key].indexOf(elementId) > -1) {
                prio = key
            }
        }
    }
    return prio ? priorities[prio].length > 1 : false
}

export const getStartDate = (eventDate: Date): Date => {
    const startOfTheMonth = startOfMonth(eventDate)
    const now = startOfToday()
    const monthFirstDate = isThisMonth(eventDate)
        ? now
        : addDays(startOfTheMonth, -1 * (getDayOfWeek(startOfTheMonth) - 1))
    return monthFirstDate < now ? now : monthFirstDate
}

export const getEndDate = (eventDate: Date): Date => {
    const endOfTheMonth = endOfMonth(eventDate)
    return addDays(endOfTheMonth, 9)
}

export const getDayOfWeek = (eventDate: Date): number => {
    const dayOfWeek = getDay(eventDate)
    return dayOfWeek === 0 ? 7 : dayOfWeek
}

export function generateUnavailableDates(from: Date, to: Date): Price[] {
    const dates = []
    let currentDate = from
    while (!isAfter(currentDate, to)) {
        dates.push(format(currentDate, 'yyyy-MM-dd'))
        currentDate = addDays(currentDate, 1)
    }
    return dates.map<Price>((d) => ({
        date: d,
        pricing: {
            availability: 'NOT_AVAILABLE' as Availability,
            price: '0',
            weight: 0,
        },
        timeslots: [],
    }))
}
const secondsPartPattern = /^(\d\d:\d\d):\d\d$/

export const getTimeslots = (
    prices: CalendarPrice[],
    accountSlug: string,
    formatCurrencyString: (amount: number | string, accountSlug?: string) => string,
): PricingTimeslot[] => {
    const filteredPrices = prices.filter((price) => !!price.startTime && price.products.length > 0)

    return filteredPrices.map((price) => ({
        startTime: price.startTime?.replace(secondsPartPattern, '$1') ?? '',
        endTime: price.endTime?.replace(secondsPartPattern, '$1') ?? '',
        color: getColorFromWeight(price.color),
        price: formatCurrencyString(price.products[0].price, accountSlug),
    }))
}

export function transformPricesToCalendarData(
    data: CalendarData,
    availabilities: AvailabilityDates,
    accountSlug: string,
    formatCurrencyString: (amount: number | string, accountSlug?: string) => string,
): Price[] {
    const datesWithMinPrice: { [key: string]: { price: string; color: number } } = {}
    data.prices.forEach((pr) => {
        const priceObject = {
            price: pr.products[0].price,
            color: pr.color,
        }
        if (
            !datesWithMinPrice[pr.dt] ||
            parseFloat(datesWithMinPrice[pr.dt].price) > parseFloat(pr.products[0].price)
        ) {
            datesWithMinPrice[pr.dt] = priceObject
        }
    })
    const dates = Object.keys(datesWithMinPrice)

    return dates.map((d) => ({
        date: d,
        pricing: {
            availability: availabilities[d]
                ? availabilities[d].available
                    ? ('AVAILABLE' as Availability)
                    : ('NOT_AVAILABLE' as Availability)
                : ('AVAILABLE' as Availability),
            price: formatCurrencyString(datesWithMinPrice[d].price, accountSlug),
            weight: datesWithMinPrice[d].color,
        },
        timeslots: getTimeslots(
            data.prices.filter((price) => price.dt === d),
            accountSlug,
            formatCurrencyString,
        ),
    }))
}

export const buildCheckedProductInfo = (
    elements: NestedElement[],
    checkedArticlesIds: string[],
    knownPricing: PricingData[],
) => {
    return elements
        .filter((el) => checkedArticlesIds.indexOf((el as ArticleListItem).id) > -1)
        .map((el) => {
            const product = knownPricing.find((pr) => pr.productId === (el as ArticleListItem).id)
            return {
                name: el.name,
                max: product?.originalPrice || '',
                min: product?.minAcceptedPrice || '',
                avg: product?.avgTargetPrice || '',
                gate: product?.gatePrice || '',
                id: (el as ArticleListItem).id,
                version: product?.version || '',
            }
        })
}
