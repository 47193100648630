import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Navigation, renderSearch } from 'navigation'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { closeSideBar } from './reducer'
import classNames from 'classnames'
import './styles.scss'

interface MenuItemProps {
    children?: React.ReactNode
    path: string
    icon?: IconProp
    image?: string
    text: string
    style?: object
    className?: string
    userpilot?: string
    navigation: Navigation
    match: RouteMatch<any>
    onClick?: (path: string) => void
    bordered?: boolean
    count?: number
    close?: boolean
    floatText?: string
    rightComponent?: React.ReactNode
}

function MenuItem(props: MenuItemProps) {
    const { path, icon, image, text, children, className: itemClasses, userpilot, count, rightComponent } = props
    const originalQuery = props.navigation.query()
    const persistedQueryItems = ['dateRange', 'dateFrom', 'dateTo', 'period']
    const query = Object.keys(originalQuery)
        .filter((key) => persistedQueryItems.indexOf(key) > -1)
        .reduce((obj, key) => {
            obj[key] = originalQuery[key]
            return obj
        }, {})
    const persistedSearch = renderSearch(query)
    const [expanded, setExpanded] = React.useState<boolean>(false)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (props.children && props.match.url.indexOf(props.path) > -1) {
            setExpanded(true)
        }
    }, [])

    React.useEffect(() => {
        if (props.close) {
            setExpanded(false)
        }
    }, [props.close])

    const toggleExpanded = (e: React.MouseEvent<any>) => {
        if (props.children) {
            e.preventDefault()
            setExpanded(!expanded)
        }

        if (props.onClick) {
            props.onClick(props.path)
        }

        if (!props.children) {
            dispatch(closeSideBar())
        }
    }

    return (
        <>
            <li data-userpilot={userpilot}>
                <NavLink
                    to={{ pathname: path, search: persistedSearch }}
                    onClick={toggleExpanded}
                    className={classNames('menu-link', itemClasses)}
                >
                    <div style={{ display: 'flex' }}>
                        {icon && (
                            <div className="icon-wrapper">
                                <FontAwesomeIcon className="icon" icon={icon} />
                            </div>
                        )}
                        {image && (
                            <div className="image-wrapper">
                                <img className="image" src={image} />
                            </div>
                        )}
                        <div style={{ position: 'relative' }}>
                            {props.floatText && <div className="float-text">{props.floatText}</div>}
                            {text}
                            {!!props.count && <div className="badge">{count}</div>}
                        </div>
                        {rightComponent && rightComponent}
                        {!!children && (
                            <div className="chevron-div">
                                <FontAwesomeIcon
                                    style={{ fontSize: '.8em' }}
                                    icon={expanded ? faChevronUp : faChevronDown}
                                />
                            </div>
                        )}
                    </div>
                </NavLink>
            </li>
            {children && (
                <li className={classNames({ sublinks: true, show: expanded })}>
                    <div className="sublink-area">
                        <ul className="sublink-list">{children}</ul>
                    </div>
                </li>
            )}
            {props.bordered && (
                <li>
                    <div className="separator" />
                </li>
            )}
        </>
    )
}

export default withNavigation(MenuItem)
