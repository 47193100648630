import React from 'react'
import { SingleSelect, SingleSelectOption } from 'uiComponents/input/singleSelect'
import './languageSelector.scss'

export const getPreferredLanguage = () => {
    return localStorage.getItem('language') || 'en'
}

interface LanguageSelectorProps {
    options: SingleSelectOption[]
    onChange?: (language: string) => void
}

export const LanguageSelector = ({ options, onChange }: LanguageSelectorProps) => {
    const [language, setLanguage] = React.useState(getPreferredLanguage())

    const handleLanguageChange = (value: string) => {
        localStorage.setItem('language', value)
        setLanguage(value)
        if (onChange) {
            onChange(value)
        }
    }

    return (
        <SingleSelect
            id="language-selector"
            className="language-selector"
            options={options}
            onSelect={handleLanguageChange}
            selected={language}
        />
    )
}
