import React from 'react'
import { ArticleCrudData } from 'admin/articleService'
import { NavigationTabs } from 'uiComponents/navigation/tabs'
import { usePermission } from 'admin/hocs'

interface ExternalConfigurationSectionProps {
    accountSlug: string
    productData: ArticleCrudData
    onSave: () => void
    onPublish: () => void
    onUnpublish: () => void
    saveBeforeContinue: (action: 'validity' | 'pricing') => void
}

function ExternalConfigurationSection({
    accountSlug,
    productData,
    saveBeforeContinue,
}: ExternalConfigurationSectionProps) {
    const { hasPermission } = usePermission()
    const pricingIsSet =
        productData.articleValidationErrors?.length &&
        !productData.articleValidationErrors.includes('pricing_settings_not_configured') &&
        !productData.articleValidationErrors.includes('ouroboros_payment_plans_not_configured')

    const validityIsSet =
        productData.articleValidationErrors?.length &&
        !productData.articleValidationErrors.includes('validity_not_configured')

    const tabs = [
        {
            name: 'Edit Details',
            onClick: () => {},
            current: true,
            disabled: !hasPermission('partner_admin', accountSlug),
        },
        {
            name: validityIsSet ? 'Edit Validity' : 'Configure Validity',
            onClick: () => saveBeforeContinue('validity'),
            current: false,
            disabled: !hasPermission('view_product_validity', accountSlug),
        },
        {
            name: pricingIsSet ? 'Edit Pricing' : 'Configure Pricing',
            onClick: () => saveBeforeContinue('pricing'),
            current: false,
            disabled: !hasPermission('view_pricing_settings', accountSlug),
        },
    ]

    return (
        <div style={{ marginBottom: '2.5em' }}>
            <NavigationTabs tabs={tabs} />
        </div>
    )
}

export default ExternalConfigurationSection
