import * as React from 'react'
import {
    FilterToggleContainer,
    FilterBaseElement,
    CategoriesContainer,
    ActionsSection,
    ActionText,
} from 'uiComponents/filter/filterComponents'
import { OptionsList } from 'uiComponents/menus/'
import { ActionButton } from 'uiComponents/buttons'
import { Body } from 'uiComponents/typography'
import { MetaData, FilterOption, categoryNameMap, FilterCategory, FilterQueryItems, Filters } from './schema'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import { flatMap } from 'utils'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'
import { TwoLevelMenu } from 'uiComponents/menus/'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StandaloneFilterCategory from 'uiComponents/filter/standaloneFilterCategory'
import { PlainLoader } from 'uiComponents/loaders'
import { checkOptionsMatch } from 'uiComponents/filter/filterOptionsItem'
import { FilterOptionsItem } from 'uiComponents/filter/filterOptionsItem'
import { get } from 'lodash'

export const renderFilterQuery = (filters: FilterOption[] | FilterQueryItems[]) => {
    const groupedFilters = groupBy(filters, (x) => x.category)
    const filterString = Object.entries(groupedFilters)
        .map(([k, v]) => `${k}:${v.map((x) => x.slug).join(',')}`)
        .join(';')
    return filterString
}

export const parseFilterQuery = (filterString: string): Filters[] => {
    return !filterString
        ? []
        : decodeURIComponent(filterString)
              .split(';')
              .map((f) => f.split(':'))
              .map((f) => ({ attribute: f[0], values: f[1].split(',') }))
}

export const parseFilterQueryIntoOptions = (filterString: string): FilterQueryItems[] => {
    const result: FilterQueryItems[] = []
    const categoriesList = !filterString
        ? []
        : decodeURIComponent(filterString)
              .split(';')
              .map((f) => f.split(':'))
              .map((f) => ({ category: f[0], values: f[1].split(',') }))
    categoriesList.forEach((item) => {
        item.values.forEach((v) => {
            result.push({
                category: item.category as FilterCategory,
                slug: v,
            })
        })
    })
    return result
}

export function getAllLevelsChildOptions(option: FilterOption) {
    let allOptions = [option]
    option.children.forEach((ch) => {
        allOptions = allOptions.concat(getAllLevelsChildOptions(ch))
    })
    return allOptions
}

export function getSelectedCheckboxes(selectedFilters: FilterOption[], allFilterOptions: FilterOption[]) {
    const allParentOptionIds = flatMap(
        selectedFilters.map((o) => o.parents),
        (x) => x,
    )
    const uniqueParentIds = allParentOptionIds.filter((id, i) => allParentOptionIds.indexOf(id) === i)
    const parentsWithAllChildrenSelected = allFilterOptions.filter((o) =>
        allChildFiltersSelected(o, selectedFilters, allFilterOptions),
    )
    return {
        selected: [...parentsWithAllChildrenSelected],
        indeterminate: uniqueParentIds,
    }
}

function allChildFiltersSelected(option: FilterOption, selectedFilters: FilterOption[], allOptions: FilterOption[]) {
    const filterOption = allOptions.find((o) => checkOptionsMatch(o, option))
    if (filterOption) {
        const allChildFilters = getAllChildFilters(filterOption)
        const childFiltersInSelection = allChildFilters.filter(
            (f) => !!selectedFilters.find((sf) => checkOptionsMatch(f, sf)),
        )
        return allChildFilters.length === childFiltersInSelection.length
    }
    return false
}

function getAllChildFilters(option: FilterOption) {
    return getAllLevelsChildOptions(option)
        .filter((o) => o.children.length === 0)
        .map((o) => ({ ...o }))
}

interface CheckBoxesData {
    selected: FilterOption[]
    indeterminate: string[]
}

interface FilterProps {
    accountSlug: string
    metaData: MetaData[]
    compact?: boolean
    navigation: Navigation
    match: RouteMatch<any>
    applicableFilters: FilterCategory[]
    standaloneFilters: FilterCategory[]
    singleSelectCategories: FilterCategory[]
    disabled?: boolean
    loading?: boolean
    userpilot?: string
    filterKey?: string
    customBaseElementText?: string
}

interface FilterState {
    activeCategory: string
    checkBoxesData: CheckBoxesData
    allFilterOptions: FilterOption[]
    selectedFilters: FilterOption[]
    appliedFilters: FilterOption[]
    closeMenu: boolean
}

class Filter extends React.Component<FilterProps, FilterState> {
    constructor(props: FilterProps) {
        super(props)
        this.state = {
            activeCategory: '',
            allFilterOptions: [],
            checkBoxesData: { selected: [], indeterminate: [] },
            selectedFilters: [],
            appliedFilters: [],
            closeMenu: false,
        }
    }

    componentDidMount() {
        this.setFiltersData()
    }

    componentDidUpdate(prevProps: FilterProps) {
        const prevFilters = get(prevProps.navigation.query(), this.filterKey())
        const filters = get(this.props.navigation.query(), this.filterKey())
        if (!isEqual(prevProps.metaData, this.props.metaData) || prevFilters !== filters) {
            this.setFiltersData()
        }
        if (this.props.disabled && !prevProps.disabled) {
            this.onClearAll()
        }
    }

    filterKey = () => {
        return this.props.filterKey || 'filter'
    }

    setActiveCategory = (activeCategory: string) => {
        this.setState({ activeCategory })
    }

    setFiltersData = () => {
        const allTopLevelOptions = flatMap(
            this.props.metaData.map((o) => o.options),
            (x) => x,
        )
        let allLevelOptionsList: FilterOption[] = []
        allTopLevelOptions.forEach((o) => {
            allLevelOptionsList = allLevelOptionsList.concat(getAllLevelsChildOptions(o))
        })
        const appliedFilters = this.getAppliedFiltersFromQuery(allLevelOptionsList)
        this.setState({
            allFilterOptions: allLevelOptionsList,
            appliedFilters,
            selectedFilters: appliedFilters,
            checkBoxesData: getSelectedCheckboxes(appliedFilters, allLevelOptionsList),
        })
    }

    getAppliedFiltersFromQuery = (allLevelOptionsList: FilterOption[]) => {
        const query = this.props.navigation.query()
        const filters = parseFilterQuery(get(query, this.filterKey()))
        const appliedFilters: FilterOption[] = []
        allLevelOptionsList.forEach((o) => {
            const applied = filters.find((f) => o.category === f.attribute && f.values.includes(o.slug))
            if (applied) {
                appliedFilters.push(o)
            }
        })
        return appliedFilters
    }

    onOptionSelect = (option: FilterOption) => {
        this.props.singleSelectCategories.includes(option.category)
            ? this.handleSingleSelectOptionClick(option)
            : this.handleMultiSelectOptionClick(option)
    }

    handleMultiSelectOptionClick = (option: FilterOption) => {
        const { selectedFilters, checkBoxesData, allFilterOptions } = this.state
        const wasSelected = !!checkBoxesData.selected.find((f) => checkOptionsMatch(f, option))
        let updatedFilterSelection = cloneDeep(selectedFilters)
        const allRelatedFilters = getAllChildFilters(option)
        if (wasSelected) {
            const allRelatedFiltersIds = allRelatedFilters.map((o) => o.slug)
            updatedFilterSelection = updatedFilterSelection.filter((o) => allRelatedFiltersIds.indexOf(o.slug) < 0)
        } else {
            const allAlreadySelected = updatedFilterSelection.map((o) => o.slug + o.category)
            const allRelatedNotSelected = allRelatedFilters.filter(
                (f) => allAlreadySelected.indexOf(f.slug + f.category) < 0,
            )
            allRelatedNotSelected.forEach((o) => updatedFilterSelection.push(o))
        }
        this.setState({
            selectedFilters: updatedFilterSelection,
            checkBoxesData: getSelectedCheckboxes(updatedFilterSelection, allFilterOptions),
        })
    }

    handleSingleSelectOptionClick = (option: FilterOption) => {
        let updatedFilterSelection = cloneDeep(this.state.selectedFilters)
        updatedFilterSelection = updatedFilterSelection.filter((f) => f.category !== option.category)
        updatedFilterSelection.push(option)
        this.setState(
            {
                selectedFilters: updatedFilterSelection,
                checkBoxesData: getSelectedCheckboxes(updatedFilterSelection, this.state.allFilterOptions),
            },
            () => this.onApply(),
        )
    }

    onApply = () => {
        this.setState(
            {
                closeMenu: true,
                appliedFilters: this.state.selectedFilters,
            },
            () => this.setState({ closeMenu: false }),
        )
        this.props.navigation.addQueryWithReplace({
            [this.filterKey()]: renderFilterQuery(this.state.selectedFilters),
        })
    }

    onRevertUnappliedSelection = () => {
        this.setState({ selectedFilters: this.state.appliedFilters })
    }

    onClearAll = () => {
        this.setState({
            appliedFilters: [],
            selectedFilters: [],
            checkBoxesData: { selected: [], indeterminate: [] },
        })
        this.props.navigation.addQueryWithReplace({ [this.filterKey()]: null })
    }

    toggleAllInCategory = (category: string) => {
        this.props.singleSelectCategories.includes(category as FilterCategory)
            ? this.clearSingeSelectSelection(category)
            : this.toggleAllInMultiSelectCategory(category)
    }

    toggleAllInMultiSelectCategory = (category: string) => {
        let updatedFilterSelection = cloneDeep(this.state.selectedFilters)
        const selectedCheckboxesInCategory = this.state.checkBoxesData.selected.filter((f) => f.category === category)
        if (selectedCheckboxesInCategory.length === 0) {
            const allInCategory = this.state.allFilterOptions.filter((f) => f.category === category && f.leafNode)
            allInCategory.forEach((i) => {
                if (!updatedFilterSelection.find((f) => f.category === category && f.slug === i.slug)) {
                    updatedFilterSelection.push(i)
                }
            })
        } else {
            updatedFilterSelection = updatedFilterSelection.filter((f) => f.category !== category)
        }
        this.setState({
            selectedFilters: updatedFilterSelection,
            checkBoxesData: getSelectedCheckboxes(updatedFilterSelection, this.state.allFilterOptions),
        })
    }

    clearSingeSelectSelection = (category: string) => {
        let updatedFilterSelection = cloneDeep(this.state.selectedFilters)
        updatedFilterSelection = updatedFilterSelection.filter((f) => f.category !== category)
        this.setState(
            {
                selectedFilters: updatedFilterSelection,
            },
            () => this.onApply(),
        )
    }

    renderFilterBaseElement = (text: string) => {
        return (
            <FilterBaseElement>
                <FontAwesomeIcon icon={faFilter} style={{ marginRight: '0.5rem' }} />
                <Body size={2}>{text}</Body>
            </FilterBaseElement>
        )
    }

    filterCategories = (md: MetaData) => {
        const oneOptionWithChildren = md.options.length === 1 && !!md.options[0].children.length
        return (
            this.props.applicableFilters.includes(md.category) &&
            (['products', 'capacity_pools'].includes(md.category) || md.options.length > 1 || oneOptionWithChildren)
        )
    }

    render() {
        const { checkBoxesData, appliedFilters, activeCategory, closeMenu } = this.state
        const { metaData, loading, userpilot, compact, singleSelectCategories, customBaseElementText } = this.props
        const applicableCategoriesData = metaData.filter(this.filterCategories)
        const standaloneCategories: MetaData[] = []
        this.props.standaloneFilters.forEach((f) => {
            const applicable = applicableCategoriesData.find((c) => c.category === f)
            if (applicable) {
                standaloneCategories.push(applicable)
            }
        })
        const groupedFilterCategories = applicableCategoriesData.filter(
            (c) => this.props.standaloneFilters.indexOf(c.category as FilterCategory) < 0,
        )
        const categoriesForMenu = groupedFilterCategories.map((c) => ({
            name: categoryNameMap[c.category],
            slug: c.category,
        }))
        const activeOptions = groupedFilterCategories.find((c) => c.category === activeCategory)?.options || []
        const uniqueAppliedFilters = uniqWith(appliedFilters, (a, b) => a.slug === b.slug && a.category === b.category)
        const noneSelectedInGroupedActiveCategory =
            checkBoxesData.selected.filter((f) => f.category === activeCategory).length === 0
        const selectedCountInGroupedFilters = uniqueAppliedFilters.filter(
            (f) => !!categoriesForMenu.find((c) => c.slug === f.category),
        )
        const categoriesForMenuWithSelectedCount = categoriesForMenu.map((c) => {
            const appliedCount = uniqueAppliedFilters.filter((f) => f.category === c.slug).length
            c.name = appliedCount ? `${c.name} (${appliedCount})` : c.name
            return c
        })

        return (
            <FilterToggleContainer data-userpilot={userpilot}>
                {compact ? (
                    <>
                        <TwoLevelMenu
                            menuToggleText={customBaseElementText || 'Filter'}
                            menuToggleIcon={faFilter}
                            firstLevelOptions={categoriesForMenuWithSelectedCount}
                            disabledInfotipText="Filters can only be applied to time ranges starting from Jan 1, 2020"
                            closeMenu={closeMenu}
                            loading={loading}
                            selectedCount={selectedCountInGroupedFilters.length}
                            activeOptionOverride={activeCategory}
                            onActiveOptionChange={this.setActiveCategory}
                            maxSecondLevelWidth="420px"
                            expandLeft
                        >
                            <OptionsList>
                                {activeOptions.map((o, i) => (
                                    <FilterOptionsItem
                                        key={o.name + i}
                                        option={o}
                                        onSelect={this.onOptionSelect}
                                        checkBoxesData={checkBoxesData}
                                    />
                                ))}
                            </OptionsList>
                            {!this.props.singleSelectCategories.includes(activeCategory as FilterCategory) && (
                                <ActionsSection>
                                    <ActionText onClick={() => this.toggleAllInCategory(activeCategory)}>
                                        {noneSelectedInGroupedActiveCategory ? 'Select all' : 'Clear all'}
                                    </ActionText>
                                    <ActionButton
                                        onClick={this.onApply}
                                        size="small"
                                        kind="action"
                                        style={{ marginLeft: '1.5em' }}
                                    >
                                        Apply
                                    </ActionButton>
                                </ActionsSection>
                            )}
                        </TwoLevelMenu>
                    </>
                ) : (
                    <>
                        {this.renderFilterBaseElement(customBaseElementText || 'Filter by')}
                        <CategoriesContainer className={loading ? 'loading' : ''} style={{ marginRight: '0.9em' }}>
                            {loading && (
                                <PlainLoader
                                    style={{
                                        width: '3em',
                                        position: 'absolute',
                                        zIndex: 50,
                                        top: '-0.2em',
                                        left: '5em',
                                    }}
                                />
                            )}
                            {standaloneCategories.map((o, i) => (
                                <StandaloneFilterCategory
                                    key={o.category + i}
                                    filterOptions={o.options}
                                    categorySlug={o.category}
                                    categoryName={categoryNameMap[o.category]}
                                    onOutsideClick={this.onRevertUnappliedSelection}
                                    onApply={this.onApply}
                                    onToggleAllCheckboxes={() => this.toggleAllInCategory(o.category)}
                                    onOptionSelect={this.onOptionSelect}
                                    checkBoxesData={checkBoxesData}
                                    appliedFilters={uniqueAppliedFilters.filter((f) => f.category === o.category)}
                                    singleSelectCategory={singleSelectCategories.includes(o.category)}
                                    singleCustomFilter={!!customBaseElementText && standaloneCategories.length === 1}
                                />
                            ))}
                        </CategoriesContainer>
                        {groupedFilterCategories.length > 0 && (
                            <TwoLevelMenu
                                menuToggleText="+ More filters"
                                firstLevelOptions={categoriesForMenuWithSelectedCount}
                                disabledInfotipText="Filters can only be applied to time ranges starting from Jan 1, 2020"
                                closeMenu={closeMenu}
                                loading={loading}
                                selectedCount={selectedCountInGroupedFilters.length}
                                activeOptionOverride={activeCategory}
                                onActiveOptionChange={this.setActiveCategory}
                                expandLeft
                            >
                                <OptionsList>
                                    {activeOptions.map((o, i) => (
                                        <FilterOptionsItem
                                            key={o.name + i}
                                            option={o}
                                            onSelect={this.onOptionSelect}
                                            checkBoxesData={checkBoxesData}
                                            singleSelectCategory={singleSelectCategories.includes(o.category)}
                                        />
                                    ))}
                                </OptionsList>
                                {!this.props.singleSelectCategories.includes(activeCategory as FilterCategory) && (
                                    <ActionsSection>
                                        <ActionText onClick={() => this.toggleAllInCategory(activeCategory)}>
                                            {noneSelectedInGroupedActiveCategory ? 'Select all' : 'Clear all'}
                                        </ActionText>
                                        <ActionButton
                                            onClick={this.onApply}
                                            size="small"
                                            kind="action"
                                            style={{ marginLeft: '1.5em' }}
                                        >
                                            Apply
                                        </ActionButton>
                                    </ActionsSection>
                                )}
                            </TwoLevelMenu>
                        )}
                    </>
                )}
            </FilterToggleContainer>
        )
    }
}

export default withNavigation(Filter)
