import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { History } from 'history'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { delay } from 'utils'
import { PricingService, CreatePricingSettingsError, PricingData } from '../pricingService'
import PricingDetailsForm from './detailForm'
import { MessageKind } from 'uiComponents/messages'
import { ProductListWithPath } from 'products/crud/common'
import { handleFailure, getValidity, Validities, priceToNumberOrNull, DEFAULT_STEP_SIZE } from '../common'

interface ProductPricingPageParams {
    accountSlug: string
    id: string
}

interface ProductPricingPageProps {
    history: History
    match: RouteMatch<ProductPricingPageParams>
    pricingService: PricingService
    navigation: Navigation
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideTopMessage: (id: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    className?: string
    pricingData: PricingData | null
    productName: string
    updateAfterSave: () => void
    viewMode: 'flat' | 'nested' | ''
    allCategories: ProductListWithPath[]
}

interface ProductPricingPageState {
    pricingData: PricingData | null
    accountSlug: string
    settingsChanged: boolean
    maxAcceptedPrice: number | null
    minAcceptedPrice: number | null
    averageTargetPrice: number | null
    gatePrice: number | null
    stepSize: string
    charmPricing: boolean
    validities: Validities
    isSubmitting: boolean
}

class DetailPageForDynamic extends React.Component<ProductPricingPageProps, ProductPricingPageState> {
    savingDisabled = false

    constructor(props: ProductPricingPageProps) {
        super(props)
        const initialValidities = {
            BOX: {
                valid: true,
                message: '',
            },
            MIN: {
                valid: true,
                message: '',
            },
            AVG: {
                valid: true,
                message: '',
            },
            GATE: {
                valid: true,
                message: '',
            },
        }
        this.state = {
            pricingData: this.props.pricingData,
            accountSlug: this.props.match.params.accountSlug,
            settingsChanged: false,
            maxAcceptedPrice: this.props.pricingData ? priceToNumberOrNull(this.props.pricingData.originalPrice) : null,
            minAcceptedPrice: this.props.pricingData
                ? priceToNumberOrNull(this.props.pricingData.minAcceptedPrice)
                : null,
            averageTargetPrice: this.props.pricingData
                ? priceToNumberOrNull(this.props.pricingData.avgTargetPrice)
                : null,
            gatePrice: this.props.pricingData ? priceToNumberOrNull(this.props.pricingData.gatePrice) : null,
            stepSize: props.pricingData?.stepSize || DEFAULT_STEP_SIZE,
            charmPricing: props.pricingData?.charmPricing || false,
            validities: initialValidities,
            isSubmitting: false,
        }
    }

    setMaxAcceptedPrice = async (e: React.ChangeEvent<any>) => {
        const value = e.target.value === '' ? null : parseFloat(e.target.value)
        this.setState({ maxAcceptedPrice: value, settingsChanged: true })
        await delay(4000)
        this.setState({ settingsChanged: false })
    }

    setMinAcceptedPrice = async (e: React.ChangeEvent<any>) => {
        let value = e.target.value === '' ? null : parseFloat(e.target.value)
        this.setState({ minAcceptedPrice: value, settingsChanged: true })
        await delay(4000)
        this.setState({ settingsChanged: false })
    }

    setAverageTargetPrice = async (e: React.ChangeEvent<any>) => {
        let value = e.target.value === '' ? null : parseFloat(e.target.value)
        this.setState({ averageTargetPrice: value, settingsChanged: true })
        await delay(4000)
        this.setState({ settingsChanged: false })
    }

    setGatePrice = async (e: React.ChangeEvent<any>) => {
        const value = e.target.value === '' ? null : parseFloat(e.target.value)
        this.setState({ gatePrice: value })
    }

    setPriceStepSize = async (value: string) => {
        this.setState({ stepSize: value, settingsChanged: true })
        await delay(4000)
        this.setState({ settingsChanged: false })
    }

    setNewCharmPricing = async (value: boolean) => {
        this.setState({ charmPricing: value, settingsChanged: true })
        await delay(4000)
        this.setState({ settingsChanged: false })
    }

    onFinishTyping = () => {
        this.props.hideTopMessage('validation_error')
        const maxValidity = this.validateMaxAcceptedPrice()
        const minValidity = this.validateMinAcceptancePrice()
        const avgValidity = this.validateAvgTargetPrice()
        const gateValidity = this.validateGatePrice()
        this.setState({
            validities: {
                BOX: maxValidity,
                MIN: minValidity,
                AVG: avgValidity,
                GATE: gateValidity,
            },
        })
    }

    validateMaxAcceptedPrice = () => {
        return getValidity(this.state.maxAcceptedPrice)
    }

    validateMinAcceptancePrice = () => {
        const value = this.state.minAcceptedPrice
        let validity = {
            valid: true,
            message: '',
        }
        if (value === null) {
            validity = {
                valid: false,
                message: 'This price should be set.',
            }
        } else if (this.state.maxAcceptedPrice !== null && value > this.state.maxAcceptedPrice) {
            validity = {
                valid: false,
                message: 'Minimum Accepted price should be lower than the Maximum Accepted price.',
            }
        } else if (value < 0) {
            validity = {
                valid: false,
                message: 'Minimum Accepted price should be higher than 0.',
            }
        }
        return validity
    }

    validateAvgTargetPrice = () => {
        const value = this.state.averageTargetPrice
        let validity = {
            valid: true,
            message: '',
        }
        if (value === null) {
            validity = {
                valid: false,
                message: 'This price should be set.',
            }
        } else if (this.state.maxAcceptedPrice !== null && value > this.state.maxAcceptedPrice) {
            validity = {
                valid: false,
                message: 'Average price should be lower than the Maximum Accepted price.',
            }
        } else if (this.state.minAcceptedPrice !== null && value < this.state.minAcceptedPrice) {
            validity = {
                valid: false,
                message: 'Average price should be higher than the Minimum Accepted price.',
            }
        } else if (value < 0) {
            validity = {
                valid: false,
                message: 'Average price should be higher than 0.',
            }
        }
        return validity
    }

    validateGatePrice = () => {
        const value = this.state.gatePrice
        let validity = {
            valid: true,
            message: '',
        }
        if (value === null) {
            return validity
        } else if (this.state.maxAcceptedPrice !== null && value < this.state.maxAcceptedPrice) {
            validity = {
                valid: false,
                message: 'Gate price should be higher than the Maximum Accepted price.',
            }
        } else if (value < 0) {
            validity = {
                valid: false,
                message: 'Gate price should be higher than 0.',
            }
        }
        return validity
    }

    onSettingsSave = async (evt: React.FormEvent<HTMLElement>) => {
        evt.preventDefault()
        if (this.savingDisabled) {
            return
        }
        this.savingDisabled = true

        if (!this.isFormValid()) {
            this.props.replaceTopMessages('validation_error', 'error', 'Please check the validation errors below.')
            this.savingDisabled = false
            return
        }

        this.setState({ isSubmitting: true })
        const responseData = await this.props.pricingService.sendPricingSettings({
            pricingType: 'nyop',
            id: this.props.match.params.id,
            account: this.state.accountSlug,
            originalPrice: this.state.maxAcceptedPrice,
            minAcceptedPrice: this.state.minAcceptedPrice,
            avgTargetPrice: this.state.averageTargetPrice,
            gatePrice: this.state.gatePrice,
            stepSize: this.state.stepSize,
            charmPricing: this.state.charmPricing,
            version: this.props.pricingData?.version || null,
        })
        responseData
            .ifFailure((err: CreatePricingSettingsError) => {
                handleFailure(err, this.props.replaceTopMessages)
                this.savingDisabled = false
                this.setState({ isSubmitting: false })
            })
            .ifSuccess(async () => {
                this.props.replaceTopMessages(
                    'pricing_settings_save_success',
                    'success',
                    'Pricing settings were successfully saved.',
                )
                this.props.updateAfterSave()
                await delay(500)
                this.savingDisabled = false
                this.setState({ isSubmitting: false })
            })
    }

    isFormValid = () => {
        const maxValidity = this.validateMaxAcceptedPrice()
        const minValidity = this.validateMinAcceptancePrice()
        const avgValidity = this.validateAvgTargetPrice()
        const gateValidity = this.validateGatePrice()
        this.setState({
            validities: {
                BOX: maxValidity,
                MIN: minValidity,
                AVG: avgValidity,
                GATE: gateValidity,
            },
        })
        return maxValidity.valid && minValidity.valid && avgValidity.valid && gateValidity.valid
    }

    render() {
        const {
            maxAcceptedPrice,
            minAcceptedPrice,
            averageTargetPrice,
            gatePrice,
            stepSize,
            charmPricing,
            accountSlug,
            validities,
        } = this.state
        return (
            <PricingDetailsForm
                history={this.props.history}
                match={this.props.match}
                originalPrice={maxAcceptedPrice}
                minAcceptedPrice={minAcceptedPrice}
                averageTargetPrice={averageTargetPrice}
                gatePrice={gatePrice}
                stepSize={stepSize}
                charmPricing={charmPricing}
                accountSlug={accountSlug}
                onMaxAcceptedPriceChange={this.setMaxAcceptedPrice}
                onMinAcceptancePriceChange={this.setMinAcceptedPrice}
                onAvgTargetPriceChange={this.setAverageTargetPrice}
                onGatePriceChange={this.setGatePrice}
                onStepSizeChange={this.setPriceStepSize}
                onCharmPricingChange={this.setNewCharmPricing}
                onSettingsSave={this.onSettingsSave}
                settingsChanged={this.state.settingsChanged}
                onSettingsAlertMsgDismiss={() => {
                    this.setState({ settingsChanged: false })
                }}
                onFinishTyping={this.onFinishTyping}
                validities={this.state.validities}
                formValid={
                    validities['BOX'].valid &&
                    validities['MIN'].valid &&
                    validities['AVG'].valid &&
                    validities['GATE'].valid
                }
                hasPermission={this.props.hasPermission}
                articleName={this.props.productName}
                pricingType="nyop"
                viewMode={this.props.viewMode}
                isSubmitting={this.state.isSubmitting}
            />
        )
    }
}

export default withNavigation(DetailPageForDynamic)
