import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCopy } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import CopyToClipboard from 'react-copy-to-clipboard'
import classNames from 'classnames'
import './styles/copyIcon.scss'

interface CopyIconProps {
    text: string
    style?: React.CSSProperties
}

export function CopyIcon({ text, style }: CopyIconProps) {
    const [copied, setCopied] = React.useState(false)
    const handleCopy = () => {
        if (!copied) {
            setCopied(true)
            setTimeout(() => setCopied(false), 3000)
        }
    }

    return (
        <CopyToClipboard text={text} onCopy={handleCopy}>
            <div className={classNames('copy-icon-container', { copied })} title="Copy" style={style}>
                <FontAwesomeIcon className="copy-icon" icon={faCopy as IconProp} />
                <FontAwesomeIcon className="confirmation-icon" icon={faCheck as IconProp} />
            </div>
        </CopyToClipboard>
    )
}
