import * as React from 'react'
import styled from 'styled-typed'
import { Route, Switch } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { State } from 'store'
import { Account } from 'auth/state'
import { changeActiveAccount } from 'preferences/actions.async'
import { Popup } from 'uiComponents/popups/popup'
import { PopupList, MenuLink } from 'uiComponents/menus/'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { parseSearch, renderSearch } from 'navigation'
import { ResponsiveImage } from 'uiComponents/image'
import conviousPinkLogo from 'images/convious-logo-pink.png'
import { isSidebarOpen } from './selectors'

const SelectContainer = styled.div`
    position: relative;
    display: flex;
    background: ${(props) => props.theme.colors.white};
`
const Caret = styled(FontAwesomeIcon)`
    margin-left: 0.3em;
    margin-right: 0.6em;
`

const SelectTrigger = styled.a`
    padding: 0.85em;
    border: 1px solid #eff2f7;
    border-radius: 0.25em;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.textDark};
    cursor: pointer;
    width: 10em;
    ${Caret} {
        margin-left: 0.3em;
        margin-right: 0;
        font-size: 0.65em;
    }
`

const AccountTitle = styled.span`
    flex: 1;
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 7em;
`

const SelectDropdown = styled(Popup)`
    border-radius: 0 0 0.25em 0.25em;
    padding: 0.5em 0;
    left: 0;
    right: 0;
    top: 100%;
    max-height: 25em;
    overflow: auto;
    font-size: 0.85rem;
    width: 100%;
`

const AccountLink = styled(MenuLink)`
    font-weight: normal;
    padding: 0.3125em 1.25em;
`

const AccountSelectorWrapper = styled.div`
    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        > .convious-logo {
            display: none !important;
        }
    }
`

interface SelectorDropdownProps {
    activeAccount: string
    accounts: Account[]
    isAdmin: boolean
    changeActiveAccount: typeof changeActiveAccount
    selfSignUp: string | null
    isSidebarOpen: boolean
}

interface SelectorDropdownState {
    expanded: boolean
    search: string
}

class SelectorDropdown extends React.Component<
    SelectorDropdownProps & RouteComponentProps<any>,
    SelectorDropdownState
> {
    popup: HTMLDivElement | null = null

    constructor(props: SelectorDropdownProps & RouteComponentProps<any>) {
        super(props)
        this.state = {
            expanded: false,
            search: '',
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
        const urlSlug = this.props.match.params['accountSlug']
        if (urlSlug && urlSlug !== this.props.activeAccount && this.props.accounts.find((x) => x.slug === urlSlug)) {
            this.props.changeActiveAccount(urlSlug)
        }
        document.addEventListener('keydown', this.onKeyDown, false)
    }

    componentDidUpdate(prevProps: SelectorDropdownProps & RouteComponentProps<any>) {
        const oldUrlSlug = prevProps.match.params['accountSlug']
        const newUrlsSlug = this.props.match.params['accountSlug']
        if (
            oldUrlSlug !== newUrlsSlug &&
            newUrlsSlug !== this.props.activeAccount &&
            this.props.accounts.find((x) => x.slug === newUrlsSlug)
        ) {
            this.props.changeActiveAccount(newUrlsSlug)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
        document.removeEventListener('keydown', this.onKeyDown, false)
    }

    onKeyDown = (ev: KeyboardEvent) => {
        // Space, Backspace, Delete, _ , &
        const allowedKeyList = [32, 8, 46, 189, 55]
        if (
            this.state.expanded &&
            ((ev.keyCode >= 65 && ev.keyCode <= 90) || allowedKeyList.indexOf(ev.keyCode) > -1)
        ) {
            if (ev.keyCode === 8 || ev.keyCode === 46) {
                this.setState({ search: '' })
            } else {
                this.setState({ search: this.state.search + ev.key.toLowerCase() })
            }
        }
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.popup && this.popup.contains && !this.popup.contains(ev.target as Node) && this.state.expanded) {
            this.setState({
                expanded: false,
            })
        }
    }

    setPopupRef = (node: HTMLDivElement) => {
        this.popup = node
    }

    toggleExpanded = () => {
        this.setState({ expanded: !this.state.expanded, search: '' })
    }

    onChange = async (slug: string) => {
        this.setState({ expanded: false })
        if (this.props.activeAccount === slug && this.props.match.params['accountSlug'] === slug) {
            return
        }
        await this.props.changeActiveAccount(slug)
        const path = this.props.match.params[1]
        const search = this.props.location.search
        const queryItems = parseSearch(search)
        if (queryItems.page) {
            queryItems.page = '1'
        }
        const nonPersistentQueryItems = ['q', 'search', 'filter', 'checkout_funnel_rates', 'sankeyFilter']
        nonPersistentQueryItems.forEach((item) => {
            if (queryItems[item]) {
                delete queryItems[item]
            }
        })
        const query = renderSearch(queryItems)
        if (this.props.match.path.indexOf(':accountSlug') > -1) {
            this.props.history.push(`/account/${slug}/${path}${query}`)
        } else {
            this.props.history.push(`${this.props.location.pathname}${query}`)
        }
    }

    getFilteredAccounts = (accounts: Account[]) => {
        if (this.props.isAdmin && this.state.search) {
            return accounts.filter(
                (a) =>
                    a.slug.toLowerCase().indexOf(this.state.search) > -1 ||
                    a.name.toLowerCase().indexOf(this.state.search) > -1,
            )
        }
        return accounts
    }

    render() {
        const { accounts, activeAccount } = this.props
        const activeOption = accounts.find((x) => x.slug === activeAccount) || accounts[0]
        const inaccessibleAccount = this.props.match.params['accountSlug']
            ? this.props.match.params['accountSlug'] !== activeAccount
            : false
        const sortedAccounts = accounts
            .slice()
            .sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0,
            )
        const filteredAccounts = this.getFilteredAccounts(sortedAccounts)

        return (
            <AccountSelectorWrapper className="safari-wrapper">
                <ResponsiveImage
                    className="convious-logo"
                    style={{ display: 'block' }}
                    src={conviousPinkLogo}
                    alt="Convious"
                />
                <SelectContainer ref={this.setPopupRef}>
                    {accounts.length === 1 && !inaccessibleAccount && (
                        <SelectTrigger>
                            <AccountTitle style={{ cursor: 'auto' }}>{accounts[0].name}</AccountTitle>
                        </SelectTrigger>
                    )}
                    {(accounts.length > 1 || inaccessibleAccount) && (
                        <>
                            <SelectTrigger onClick={this.toggleExpanded}>
                                <AccountTitle>
                                    {inaccessibleAccount ? 'Select Account' : activeOption.name}
                                </AccountTitle>
                                <Caret icon={this.state.expanded ? faChevronUp : faChevronDown} />
                            </SelectTrigger>
                            <SelectDropdown visible={this.state.expanded}>
                                <PopupList>
                                    {filteredAccounts.map((w) => (
                                        <li key={w.slug}>
                                            <AccountLink onClick={() => this.onChange(w.slug)}>{w.name}</AccountLink>
                                        </li>
                                    ))}
                                    {filteredAccounts.length === 0 && this.props.isAdmin && (
                                        <li style={{ padding: '.5em .8em' }}>
                                            <div style={{ marginBottom: '.5em' }}>No accounts found</div>
                                            <div style={{ fontSize: '.8em' }}>
                                                Press Backspace or Delete to search again
                                            </div>
                                        </li>
                                    )}
                                </PopupList>
                            </SelectDropdown>
                        </>
                    )}
                </SelectContainer>
            </AccountSelectorWrapper>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
        activeAccount: state.preferences.activeAccount,
        isAdmin: !!state.auth.user?.isAdmin,
        isSidebarOpen: isSidebarOpen(state),
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            changeActiveAccount,
        },
        dispatch,
    )
}

const ConnectedSelectorDropdown = withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectorDropdown))

export function AccountSelector(props: { selfSignUp: string | null }) {
    return (
        <Switch>
            <Route
                path="/(account|widget)/:accountSlug/*"
                render={() => <ConnectedSelectorDropdown selfSignUp={props.selfSignUp} />}
            />
            <Route render={() => <ConnectedSelectorDropdown selfSignUp={props.selfSignUp} />} />
        </Switch>
    )
}
