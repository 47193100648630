import * as React from 'react'
import classNames from 'classnames'
import { CircleInfoSolid } from '@convious/icons'
import { IconButton, Tooltip } from '@mui/material'
import { Cell, DataRow } from 'uiComponents/table'
import { Checkbox } from 'uiComponents/input'
import Money from 'uiComponents/money'
import { ICancelRefundTableRow } from './types'
import './styles.scss'

interface CancelRefundRowProps {
    row: ICancelRefundTableRow
    isSelected: boolean
    onSelectItem: (parentId: string | null, id: string) => void
    onRefundFeeSelect: () => void
    onBookingFeeSelect: (parentId: string | null, id: string) => void
    accountSlug: string
    confirmMode: boolean
}

const CancelRefundTableRow = ({
    row,
    isSelected,
    onSelectItem,
    accountSlug,
    onRefundFeeSelect,
    onBookingFeeSelect,
    confirmMode,
}: CancelRefundRowProps) => {
    const onCheckboxChange = React.useCallback(() => {
        onSelectItem(row.orderItemId ?? null, row.id)
    }, [onSelectItem, row])

    const onBookingFeeSelected = React.useCallback(() => {
        onBookingFeeSelect(row.orderItemId ?? null, row.id)
    }, [onBookingFeeSelect, row])

    const clickHandler = (() => {
        if (row.disabled) {
            return undefined
        }
        switch (row.rowType) {
            case 'refundFee':
                return onRefundFeeSelect
            case 'bookingFee':
                return onBookingFeeSelected
            default:
                return onCheckboxChange
        }
    })()

    return (
        <DataRow
            className={classNames('cancel-refund-data-row', {
                'total-row': row.rowType === 'total',
            })}
            onClick={clickHandler}
        >
            <Cell noPadding>
                {!confirmMode && row.rowType !== 'total' && (
                    <Checkbox
                        className="row-checkbox"
                        id="partner-refund-fee-checkbox"
                        checked={isSelected}
                        disabled={row.disabled}
                    />
                )}
                <p
                    className={classNames('body1', {
                        bold: row.rowType === 'total',
                    })}
                    title={row.name}
                >
                    {row.name}
                </p>
            </Cell>
            <Cell className="middle-cell" noPadding>
                <p className="body1" title={row.descriptionInfo ? row.descriptionInfo : row.description}>
                    {row.description}
                </p>
                {row.infoTip && (
                    <Tooltip title={<p style={{ whiteSpace: 'pre' }}>{row.infoTip}</p>} placement="top-end">
                        <IconButton>
                            <CircleInfoSolid width={24} height={24} className="info-tip" />
                        </IconButton>
                    </Tooltip>
                )}
            </Cell>
            <Cell
                align="right"
                className={classNames({
                    'overflow-allowed': row.rowType !== 'total',
                })}
                noPadding
            >
                <p
                    className={classNames('body1', {
                        error: row.rowType === 'refundFee',
                        bold: row.rowType === 'total',
                    })}
                >
                    {row.rowType === 'refundFee' ? '- ' : ''}
                    <Money accountSlug={accountSlug} amount={row.amount.toFixed(2)} />
                </p>
            </Cell>
        </DataRow>
    )
}

export default CancelRefundTableRow
