import * as React from 'react'
import { useState, useCallback } from 'react'
import styled from 'styled-typed'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { History, Location } from 'history'
import { Account } from 'auth/state'
import {
    Container,
    ContainerBody,
    ContainerHeading,
    FullWidthContainerBody,
    HeadingSectionName,
} from 'uiComponents/settingsContainer'
import { getBaseKnowledgeUrl } from './utils'
import { PageTitle } from 'uiComponents/typography'
import Feature from 'features/feature'
import { withFeatures } from 'features'
import { StatsService } from 'http/statsService'
import Permission from 'admin/permissionRequired'
import { TicketTemplatesService } from 'ticketTemplates/ticketTemplatesService'
import { parseSearch } from 'navigation'
import { ArticleService } from 'admin/articleService'
import { AddNewTopButton, ExceptionsTopButtons, ProductsTopButtons } from 'products/topButtons'
import { ProductListWithPath } from 'products/crud/common'
import { ManagementService } from 'settings/accountSettings/contract/managementService'
import ProductPricingDetailRouter from 'products/pricing/productPricingDetailRouter'
import PricingExceptionPage from 'products/pricing/exceptions/exceptionDetailPage'
import PricingExceptionsListPage from 'products/pricing/exceptions/exceptionsListPage'
import { PricingService } from 'products/pricing/pricingService'
import ValidityExceptionsListPage from 'products/validity/exceptions/exceptionsList'
import ValidityExceptionPage from 'products/validity/exceptions/exceptionDetailPage'
import ProductValidityDetailPage from 'products/validity/articleDetailPage'
import { ArticleConfigurationService } from 'products/articleConfigurationService'
import TimeSlotsListPage from 'timeSlots/list'
import TimeSlotGroupForm from 'timeSlots/form'
import InventoryListPage from 'inventory/list'
import OptionGroupsListPage from 'products/options/groupsList'
import OptionGroupDetails from 'products/options/groupDetails'
import OptionItemDetails from 'products/options/optionItemDetails'
import { OptionsService } from 'products/options/optionsService'
import CrowdControlForm from 'inventory/crowdControl'
import InventoryRuleForm from 'inventory/ruleEditForm'
import { ChannelsService } from 'channels/channelsService'
import HomePage from 'products/home'
import ProductTable from './productTable'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'
import { usePermission } from 'admin/hocs'
import WithRoutePermission from 'routes/guards/withRoutePermission'
import { BaseRouteParams } from 'hocs'
import { PaymentPlansService } from './pricing/paymentPlansService'

const TAB_WIDTH = '11.3em'

const Heading = styled(HeadingSectionName)`
    width: ${TAB_WIDTH};
`

const Header = styled.div`
    margin-bottom: 1.375em;
    position: relative;
    opacity: 1;
`

interface ProductsContainerProps {
    history: History
    accounts: Account[]
    location: Location
    articleConfigurationService: ArticleConfigurationService
    articleService: ArticleService
    pricingService: PricingService
    paymentPlansService: PaymentPlansService
    channelsService: ChannelsService
    managementService: ManagementService
    ticketTemplatesService: TicketTemplatesService
    statsService: StatsService
    optionsService: OptionsService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

const ProductsContainer: React.FC<ProductsContainerProps> = (props) => {
    const {
        location,
        history,
        pricingService,
        articleConfigurationService,
        statsService,
        managementService,
    } = props
    const { hasPermission } = usePermission()
    const { accountSlug } = useParams<BaseRouteParams>()
    const [header, setHeader] = useState('Products Overview')
    const [subheader, setSubheader] = useState<string>('')
    const [activeSection, setActiveSection] = useState('productsList')
    const [flattenedCategories] = useState<ProductListWithPath[] | null>(null)
    const [loadingProducts, setLoadingProducts] = useState(false)
    const [showBulkButtons, setShowBulkButtons] = useState(false)
    const [bulkEditPricing, setBulkEditPricing] = useState(false)
    const [bulkEditValidity, setBulkEditValidity] = useState(false)
    const { removeAllMessages, hideMessage, replaceMessages, addMessage } = useMessages()

    const setActiveSectionAndHeader = useCallback(
        (section: string, newHeader?: string) => {
            const newSubheader = getBaseKnowledgeUrl(section)
            setSubheader(newSubheader)

            if (newHeader) {
                setHeader(newHeader)
            }

            if (
                section === 'productsList' &&
                (location.pathname.includes('crud') || location.pathname.includes('detail'))
            ) {
                return
            }

            setActiveSection(section)
        },
        [location.pathname]
    )

    const generateParentsPath = useCallback(
        async (
            array: { name: string; path: string; id: string }[],
            id: string,
            allLists: ProductListWithPath[] = flattenedCategories || []
        ): Promise<{ name: string; path: string; id: string }[] | null> => {
            try {
                const parentPl = allLists.find((l) => l.uuid === id)
                const parentCategoryPath = {
                    name: parentPl?.name || 'Unknown',
                    id: id,
                    path: `/account/${accountSlug}/products/home?listIds=`,
                }
                array.unshift(parentCategoryPath)

                const parentId = parentPl?.parentIds[parentPl?.parentIds.length - 2]
                if (parentId) {
                    return await generateParentsPath(array, parentId, allLists)
                } else {
                    return array
                }
            } catch {
                replaceMessages('server_error', 'error', 'Oops! An unknown error occurred.')
                return null
            }
        },
        [flattenedCategories, accountSlug, props],
    )

    const params = parseSearch(location.search)
    const viewMode =
        location.pathname.indexOf('nested') > -1
            ? 'nested'
            : location.pathname.indexOf('flat') > -1
            ? 'flat'
            : ((params.from || 'nested') as 'nested' | 'flat')

    type SourceType = '?from=flat' | '?from=nested'
    const source = `?from=${params.from || viewMode}` as SourceType

    const routePermissionCheckData = {
        accounts: props.accounts,
        accountSlug: accountSlug,
        history: props.history,
        hasPermission: hasPermission,
    }

    return (
        <div style={{ position: 'relative' }} id="pricing-settings">
            <Header
                style={{
                    marginBottom: '0',
                    minHeight: '0',
                }}
            >
                <PageTitle data-userpilot="products-page-header">{header}</PageTitle>
            </Header>
            {(location.pathname.includes('products/home') || location.pathname.includes('/products/productTable')) && (
                <ProductsTopButtons
                    showBulkButtons={showBulkButtons}
                    accountSlug={accountSlug}
                    loading={loadingProducts}
                    showAdjustView={!location.pathname.includes('/products/productTable')}
                    showBulkEditPricing={() => setBulkEditPricing(true)}
                    showBulkEditValidity={() => setBulkEditValidity(true)}
                    origin={viewMode}
                    history={props.history}
                />
            )}
            <Permission name="edit_pricing_settings" accountSlug={accountSlug}>
                {location.pathname.indexOf('pricing/exceptions/list') > -1 && (
                    <ExceptionsTopButtons accountSlug={accountSlug} for="pricing" />
                )}
            </Permission>
            <Permission name="edit_product_validity" accountSlug={accountSlug}>
                {location.pathname.indexOf('validity/exceptions/list') > -1 && (
                    <ExceptionsTopButtons accountSlug={accountSlug} for="validity" />
                )}
            </Permission>
            <Permission name="partner_admin" accountSlug={accountSlug}>
                {location.pathname.indexOf('option_groups/home') > -1 && (
                    <AddNewTopButton accountSlug={accountSlug} for="option_groups" />
                )}
                {location.pathname.indexOf('inventory/home') > -1 && (
                    <AddNewTopButton accountSlug={accountSlug} for="inventory" />
                )}
                {location.pathname.indexOf('time_slots/home') > -1 && (
                    <AddNewTopButton accountSlug={accountSlug} for="time_slots" />
                )}
            </Permission>
            <Container style={{ marginBottom: '0' }}>
                <ContainerHeading style={{ paddingLeft: '0.3em' }}>
                    <Heading
                        className={activeSection === 'productsList' ? 'active' : ''}
                        to={`/account/${accountSlug}/products/home/${viewMode}`}
                    >
                        Products
                    </Heading>
                    <Feature name="Pricing" accountSlug={accountSlug}>
                        <Permission name="view_pricing_settings" accountSlug={accountSlug}>
                            <Heading
                                id="exceptionsTab"
                                className={activeSection === 'pricingExceptions' ? 'active' : ''}
                                to={`/account/${accountSlug}/products/pricing/exceptions/list${source}`}
                            >
                                Pricing exceptions
                            </Heading>
                        </Permission>
                    </Feature>
                    <Permission name="view_product_validity" accountSlug={accountSlug}>
                        <Heading
                            id="exceptionsTab"
                            className={activeSection === 'validityExceptions' ? 'active' : ''}
                            to={`/account/${accountSlug}/products/validity/exceptions/list${source}`}
                        >
                            Validity exceptions
                        </Heading>
                    </Permission>
                    <Permission name="partner_admin" accountSlug={accountSlug}>
                        <Heading
                            id="timeSlotsTab"
                            className={activeSection === 'timeSlots' ? 'active' : ''}
                            to={`/account/${accountSlug}/products/time_slots/home${source}`}
                        >
                            Time slots
                        </Heading>
                    </Permission>
                    <Permission name="partner_admin" accountSlug={accountSlug}>
                        <Heading
                            id="optionGroupsTab"
                            className={activeSection === 'optionGroups' ? 'active' : ''}
                            to={`/account/${accountSlug}/products/option_groups/home${source}`}
                        >
                            Option groups
                        </Heading>
                    </Permission>
                    <Permission name="partner_admin" accountSlug={accountSlug}>
                        <Heading
                            id="inventoryTab"
                            className={activeSection === 'inventory' ? 'active' : ''}
                            to={`/account/${accountSlug}/products/inventory/home${source}`}
                        >
                            Crowd Control
                        </Heading>
                    </Permission>
                </ContainerHeading>
                <Switch location={location}>
                    <Route path="/account/:accountSlug/products/productTable" component={ProductTable} />
                    <Route
                        path="/account/:accountSlug/products/pricing/:id/detail"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['view_pricing_settings']}>
                                <ContainerBody>
                                    <ProductPricingDetailRouter
                                        pricingService={pricingService}
                                        articleService={props.articleService}
                                        paymentPlansService={props.paymentPlansService}
                                        channelsService={props.channelsService}
                                        articleConfigurationService={articleConfigurationService}
                                        history={history}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceTopMessages={replaceMessages}
                                        hideTopMessage={hideMessage}
                                        hasPermission={hasPermission}
                                        viewMode={viewMode}
                                    />
                                </ContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/pricing/exceptions/list"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['view_pricing_settings']}>
                                <FullWidthContainerBody>
                                    <PricingExceptionsListPage
                                        accountSlug={accountSlug}
                                        history={history}
                                        pricingService={pricingService}
                                        articleService={props.articleService}
                                        managementService={managementService}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceTopMessages={replaceMessages}
                                        hasPermission={hasPermission}
                                    />
                                </FullWidthContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/pricing/exceptions/:id"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['view_pricing_settings']}>
                                <ContainerBody>
                                    <PricingExceptionPage
                                        pricingService={pricingService}
                                        articleService={props.articleService}
                                        channelsService={props.channelsService}
                                        statsService={statsService}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceTopMessages={replaceMessages}
                                        removeAllMessages={removeAllMessages}
                                        hasPermission={hasPermission}
                                    />
                                </ContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/validity/:id/detail"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['view_product_validity']}>
                                <ContainerBody>
                                    <ProductValidityDetailPage
                                        history={history}
                                        location={location}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceTopMessages={replaceMessages}
                                        hideTopMessage={hideMessage}
                                        removeAllMessages={removeAllMessages}
                                        hasFeature={props.hasFeature}
                                        viewMode={viewMode}
                                    />
                                </ContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/validity/exceptions/list"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['view_product_validity']}>
                                <FullWidthContainerBody>
                                    <ValidityExceptionsListPage
                                        articleConfigurationService={articleConfigurationService}
                                        articleService={props.articleService}
                                        accountSlug={accountSlug}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceTopMessages={replaceMessages}
                                        standalonePage
                                    />
                                </FullWidthContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/validity/exceptions/:id"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['view_product_validity']}>
                                <ValidityExceptionPage
                                    articleConfigurationService={articleConfigurationService}
                                    articleService={props.articleService}
                                    history={history}
                                    location={location}
                                    setActiveSection={setActiveSectionAndHeader}
                                    replaceTopMessages={replaceMessages}
                                    removeAllMessages={removeAllMessages}
                                />
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/time_slots/home"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['partner_admin']}>
                                <FullWidthContainerBody>
                                    <TimeSlotsListPage
                                        history={props.history}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceMessages={replaceMessages}
                                        removeAllMessages={removeAllMessages}
                                        hasPermission={hasPermission}
                                        accountSlug={accountSlug}
                                    />
                                </FullWidthContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/time_slots/edit/:id"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['partner_admin']}>
                                <TimeSlotGroupForm
                                    history={props.history}
                                    accountSlug={accountSlug}
                                    setActiveSection={setActiveSectionAndHeader}
                                    replaceMessages={replaceMessages}
                                    removeAllMessages={removeAllMessages}
                                />
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/time_slots/duplicate/:id"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['partner_admin']}>
                                <TimeSlotGroupForm
                                    prototype
                                    history={props.history}
                                    accountSlug={accountSlug}
                                    setActiveSection={setActiveSectionAndHeader}
                                    replaceMessages={replaceMessages}
                                    removeAllMessages={removeAllMessages}
                                />
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/option_groups/home"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['partner_admin']}>
                                <FullWidthContainerBody>
                                    <OptionGroupsListPage
                                        history={props.history}
                                        accountSlug={accountSlug}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceMessages={replaceMessages}
                                        optionsService={props.optionsService}
                                    />
                                </FullWidthContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/option_groups/group_details/:id"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['partner_admin']}>
                                <FullWidthContainerBody>
                                    <OptionGroupDetails
                                        history={props.history}
                                        accountSlug={accountSlug}
                                        replaceMessages={replaceMessages}
                                        removeAllMessages={removeAllMessages}
                                        optionsService={props.optionsService}
                                        articleService={props.articleService}
                                        setActiveSection={setActiveSectionAndHeader}
                                    />
                                </FullWidthContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/option_groups/option_item/:groupUuid/:itemId"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['partner_admin']}>
                                <FullWidthContainerBody>
                                    <OptionItemDetails
                                        history={props.history}
                                        accountSlug={accountSlug}
                                        replaceMessages={replaceMessages}
                                        removeAllMessages={removeAllMessages}
                                        optionsService={props.optionsService}
                                        articleService={props.articleService}
                                        setActiveSection={setActiveSectionAndHeader}
                                    />
                                </FullWidthContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/inventory/home"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['partner_admin']}>
                                <FullWidthContainerBody>
                                    <InventoryListPage
                                        history={props.history}
                                        accountSlug={accountSlug}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceMessages={replaceMessages}
                                        source={source}
                                    />
                                </FullWidthContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/inventory/item/:id"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['partner_admin']}>
                                <FullWidthContainerBody>
                                    <CrowdControlForm
                                        history={props.history}
                                        accountSlug={accountSlug}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceMessages={replaceMessages}
                                        removeAllMessages={removeAllMessages}
                                    />
                                </FullWidthContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/products/inventory/rule/:ruleId/item/:inventoryId"
                        render={() => (
                            <WithRoutePermission requiredPermissions={['partner_admin']}>
                                <FullWidthContainerBody>
                                    <InventoryRuleForm
                                        history={props.history}
                                        accountSlug={accountSlug}
                                        setActiveSection={setActiveSectionAndHeader}
                                        replaceMessages={replaceMessages}
                                        removeAllMessages={removeAllMessages}
                                    />
                                </FullWidthContainerBody>
                            </WithRoutePermission>
                        )}
                    />
                    <Route
                        path={['/account/:accountSlug/products/home', '/account/:accountSlug/products/crud']}
                        render={() => (
                            <HomePage
                                location={location}
                                accountSlug={accountSlug}
                                pricingService={pricingService}
                                articleService={props.articleService}
                                optionsService={props.optionsService}
                                history={history}
                                setActiveSection={setActiveSectionAndHeader}
                                replaceTopMessages={replaceMessages}
                                hasPermission={hasPermission}
                                hasFeature={props.hasFeature}
                                addMessage={addMessage}
                                removeAllMessages={removeAllMessages}
                                hideMessage={hideMessage}
                                routePermissionCheckData={routePermissionCheckData}
                                ticketTemplatesService={props.ticketTemplatesService}
                                viewMode={viewMode}
                                setShowBulkButtons={setShowBulkButtons}
                                bulkEditPricing={bulkEditPricing}
                                bulkEditValidity={bulkEditValidity}
                                cancelBulkEditPricing={() => setBulkEditPricing(false)}
                                cancelBulkEditValidity={() => setBulkEditValidity(false)}
                                setLoading={setLoadingProducts}
                            />
                        )}
                    />
                    <Route render={() => <Redirect to={`/account/${accountSlug}/products/home/nested`} />} />
                </Switch>
            </Container>
            <BaseKnowledgeLink link={subheader}/>
        </div>
    )
}

export default withFeatures(ProductsContainer)
