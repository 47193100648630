import React, { useState } from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { History } from 'history'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { delay } from 'utils'
import { PricingType } from 'products/articleConfigurationService'
import { MessageKind } from 'uiComponents/messages'
import { PricingService, CreatePricingSettingsError, PricingData } from '../pricingService'
import PricingDetailsForm from './detailForm'
import { ProductListWithPath } from 'products/crud/common'
import { handleFailure, getValidity, validateGatePrice, priceToNumberOrNull, DEFAULT_STEP_SIZE } from '../common'

interface ProductPricingPageParams {
    accountSlug: string
    id: string
}

interface ProductPricingPageProps {
    history: History
    match: RouteMatch<ProductPricingPageParams>
    pricingService: PricingService
    navigation: Navigation
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideTopMessage: (id: string) => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    className?: string
    pricingData: PricingData | null
    productName: string
    pricingType: PricingType
    updateAfterSave: () => void
    categoryIds: string[] | null
    viewMode: 'flat' | 'nested' | ''
    allCategories: ProductListWithPath[]
}

const DetailPageForUpsellStatic = (props: ProductPricingPageProps) => {
    let savingDisabled = false
    const accountSlug = props.match.params.accountSlug
    const [maxAcceptedPrice, setMaxAcceptedPrice] = useState<number | null>(
        props.pricingData ? priceToNumberOrNull(props.pricingData.originalPrice) : null,
    )
    const [gatePrice, setGatePrice] = useState<number | null>(
        props.pricingData ? priceToNumberOrNull(props.pricingData.gatePrice) : null,
    )
    const [stepSize, setStepSize] = useState<string>(props.pricingData?.stepSize || DEFAULT_STEP_SIZE)
    const [charmPricing, setCharmPricing] = useState<boolean>(props.pricingData?.charmPricing || false)

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const setMaxPrice = async (e: React.ChangeEvent<any>) => {
        const value = e.target.value === '' ? null : parseFloat(e.target.value)
        setMaxAcceptedPrice(value)
    }

    const setGate = async (e: React.ChangeEvent<any>) => {
        const value = e.target.value === '' ? null : parseFloat(e.target.value)
        setGatePrice(value)
    }

    const setPriceStepSize = async (value: string) => {
        setStepSize(value)
    }

    const setNewCharmPricing = async (value: boolean) => {
        setCharmPricing(value)
    }

    const onSettingsSave = async (evt: React.FormEvent<HTMLElement>) => {
        evt.preventDefault()
        if (savingDisabled) {
            return
        }
        savingDisabled = true

        if (!isFormValid()) {
            props.replaceTopMessages('validation_error', 'error', 'Please check the validation errors below.')
            savingDisabled = false
            return
        }

        setIsSubmitting(true)

        const responseData = await props.pricingService.sendPricingSettings({
            pricingType: props.pricingType,
            id: props.match.params.id,
            account: accountSlug,
            originalPrice: maxAcceptedPrice,
            minAcceptedPrice: null,
            avgTargetPrice: null,
            gatePrice,
            stepSize,
            charmPricing,
            version: props.pricingData?.version || null,
        })
        responseData
            .ifFailure((err: CreatePricingSettingsError) => {
                handleFailure(err, props.replaceTopMessages)
                savingDisabled = false
                setIsSubmitting(false)
            })
            .ifSuccess(async () => {
                props.replaceTopMessages(
                    'pricing_settings_save_success',
                    'success',
                    'Pricing settings were successfully saved.',
                )
                props.updateAfterSave()
                await delay(500)
                savingDisabled = false
                setIsSubmitting(false)
            })
    }

    const isFormValid = () => {
        const maxValidity = getValidity(maxAcceptedPrice)
        const gateValidity = validateGatePrice(gatePrice, maxAcceptedPrice)
        return maxValidity.valid && gateValidity.valid
    }

    return (
        <PricingDetailsForm
            history={props.history}
            match={props.match}
            originalPrice={maxAcceptedPrice}
            gatePrice={gatePrice}
            stepSize={stepSize}
            charmPricing={charmPricing}
            accountSlug={accountSlug}
            existingPricingSettings={!!props.pricingData}
            onMaxAcceptedPriceChange={setMaxPrice}
            onGatePriceChange={setGate}
            onStepSizeChange={setPriceStepSize}
            onCharmPricingChange={setNewCharmPricing}
            onSettingsSave={onSettingsSave}
            hasPermission={props.hasPermission}
            articleName={props.productName}
            pricingType={props.pricingType}
            categoryIds={props.categoryIds}
            replaceTopMessages={props.replaceTopMessages}
            hideTopMessage={props.hideTopMessage}
            viewMode={props.viewMode}
            allCategories={props.allCategories}
            isSubmitting={isSubmitting}
        />
    )
}

export default withNavigation(DetailPageForUpsellStatic)
