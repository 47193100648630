import { AccountService } from 'admin/accountService'
import { ArticleService, ArticleService as AdminArticleService } from 'admin/articleService'
import { ProductsListService } from 'admin/productsListService'
import { ReCaptchaService } from 'auth/reCaptchaService'
import { SsoService } from 'auth/ssoService'
import { ChannelsService } from 'channels/channelsService'
import { GoogleTTDService } from 'channels/googleTTDService'
import * as config from 'config'
import { CopyEditorService } from 'copyEditor/copyEditorService'
import { CRMService } from 'crm/crmService'
import { EmailTemplatesService } from 'emailTemplates/emailTemplatesService'
import { EngageToolsService } from 'engageTools/engageToolsService'
import { LocaleService } from 'engageTools/studio/localesService'
import { ImagesService } from 'http/imagesService'
import { LoginService } from 'http/loginService'
import { PasswordResetService } from 'http/passwordResetService'
import { StatsService } from 'http/statsService'
import { InventoryService, ReservationsInventoryUrls } from 'inventory/inventoryService'
import { Navigation } from 'navigation'
import { NotificationCampaignsService } from 'notificationCampaigns'
import { OrdersService } from 'orders/ordersService'
import { PaypalService } from 'orders/paypalService'
import { ArticleConfigurationService } from 'products/articleConfigurationService'
import { DiscountRulesService } from 'products/discountRules/discountRulesService'
import { OptionsService } from 'products/options/optionsService'
import { ArticlePricingService } from 'products/pricing/articlePricingService'
import { PricingService } from 'products/pricing/pricingService'
import { RequestService } from 'requests/requestService'
import { ManagementService } from 'settings/accountSettings/contract/managementService'
import { IntegrationsService } from 'settings/integrationService'
import { NotificationsService } from 'settings/notificationsService'
import { AccountSettingsService } from 'settings/settingsService'
import { SignUpService } from 'signUp/signUpService'
import { SnippetService } from 'snippet/snippetService'
import { TapPlacementService } from 'tapPlacements/service'
import TapChannelService from 'tapPromotions/channelService'
import TapPromotionService from 'tapPromotions/service'
import { TicketTemplatesService } from 'ticketTemplates/ticketTemplatesService'
import { TimeSlotsService } from 'timeSlots/timeSlotsService'
import { BookingCodesService } from 'venue/bookingCodes/bookingCodesService'
import { OpeningTimesService } from 'venue/openingTimes/openingTimesService'
import { ParkMapService } from 'venue/parkMap/parkMapService'
import { AudienceService } from '../crm/audiences/audienceService'
import { AngeliaNotificationsService } from '../pushNotifications/angeliaNotificationsService'
import { history } from './history'
import {
    componentsService,
    httpService,
    impersonationService,
    loggingService,
    oauthService,
    session,
    store,
    userService,
} from './store'
import { StoreService } from './storeService'
import { TaxConfigurationsService } from 'settings/accountSettings/taxConfigurations/taxConfigurationsService'
import { ReservationsService } from 'reservations/reservationsService'
import { CapacityReportService } from 'orders/capacityReport/capacityReportService'
import { BookingFeesService } from 'settings/accountSettings/bookingFees/bookingFeesService'
import { SigmaService } from 'reports/sigma/sigmaService'
import { PaymentPlansService } from 'products/pricing/paymentPlansService'

export const navigation = new Navigation(history, history.location)

const createServices = () => {
    const clientId = config.getRequired('oauth-client-id')
    const clientSecret = config.getRequired('oauth-client-secret')
    const backofficeEndpoint = config.getRequired('backoffice-endpoint')
    const statsEndpoint = config.getRequired('stats-endpoint')
    const experimentsEndpoint = config.getRequired('experiments-endpoint')
    const pricingEndpoint = config.getRequired('pricing-api-endpoint')
    const imagesEndpoint = config.getRequired('images-endpoint')
    const notificationsEndpoint = config.getRequired('notifications-endpoint')
    const aionEndpoint = config.getRequired('aion-endpoint')
    const mendixEndpoint = config.getRequired('mendix-endpoint')
    const pandoraEndpoint = config.getRequired('pandora-endpoint')
    const crmApiEndpoint = config.getRequired('customers-endpoint')
    const dashboardAPIEndpoint = config.getRequired('dashboard-api-endpoint')
    const statsApiEndpoint = config.getRequired('stats-endpoint')
    const artemisEndpoint = config.getRequired('tap-endpoint')
    const backendEndpoint = config.getRequired('dashboard-api-endpoint')
    const backtapEndpoint = config.getRequired('backtap-endpoint')
    const angeliaNotificationsEndpoint = config.getRequired('angelia-notifications-endpoint')
    const audienceBuilderEndpoint = config.getRequired('audience-builder-endpoint')

    const loginService = new LoginService(session, oauthService, store)
    const passwordResetService = new PasswordResetService(config.getRequired('oauth-endpoint'))
    const signUpService = new SignUpService(backendEndpoint, session, clientId, clientSecret, httpService)
    const reCaptchaService = new ReCaptchaService(backendEndpoint)
    const imagesService = new ImagesService(httpService, imagesEndpoint)
    const accountSettingsService = new AccountSettingsService(
        httpService,
        loggingService,
        backofficeEndpoint,
        backendEndpoint,
        clientId,
        clientSecret,
        session,
    )
    const storeService = new StoreService(store)
    const ssoRedirectUrl = window.location.origin + '/sso_auth/success/'
    const ssoService = new SsoService(backendEndpoint, ssoRedirectUrl, session, clientId, clientSecret)
    const pricingService = new PricingService(httpService, loggingService, pricingEndpoint)
    const articlePricingService = new ArticlePricingService(httpService, loggingService, backofficeEndpoint)
    const accountService = new AccountService(httpService, backofficeEndpoint)
    const bookingFeesService = new BookingFeesService(httpService, backofficeEndpoint)
    const taxConfigurationsService = new TaxConfigurationsService(httpService, backofficeEndpoint)
    const timeSlotsService = new TimeSlotsService(httpService, loggingService, backofficeEndpoint)
    const openingTimesService = new OpeningTimesService(httpService, loggingService, backofficeEndpoint)
    const inventoryService = new InventoryService(
        httpService,
        loggingService,
        new ReservationsInventoryUrls(config.getRequired('reservations-endpoint')),
    )
    const reservationsService = new ReservationsService(httpService)
    const productsListService = new ProductsListService(httpService, backofficeEndpoint)
    const articleService = new ArticleService(httpService, loggingService, backofficeEndpoint, storeService)
    const statsService = new StatsService(httpService, statsEndpoint)
    const articleConfigurationService = new ArticleConfigurationService(httpService, loggingService, backofficeEndpoint)
    const channelsService = new ChannelsService(httpService, loggingService, backofficeEndpoint)
    const notificationsService = new NotificationsService(httpService, notificationsEndpoint)
    const googleTTDService = new GoogleTTDService(httpService, loggingService, backofficeEndpoint, aionEndpoint)
    const ordersService = new OrdersService(httpService, loggingService, backofficeEndpoint)
    const tapChannelService = new TapChannelService(httpService, mendixEndpoint)
    const discountRulesService = new DiscountRulesService(httpService, loggingService, pandoraEndpoint)
    const copyEditorService = new CopyEditorService(httpService, loggingService, backofficeEndpoint)
    const crmService = new CRMService(httpService, crmApiEndpoint)
    const snippetService = new SnippetService(httpService, dashboardAPIEndpoint, experimentsEndpoint)
    const localeService = new LocaleService(httpService, experimentsEndpoint)
    const engageToolsService = new EngageToolsService(httpService, statsApiEndpoint)
    const tapPLacementService = new TapPlacementService(httpService, artemisEndpoint)
    const tapPromotionService = new TapPromotionService(httpService, artemisEndpoint)
    const emailTemplatesService = new EmailTemplatesService(httpService, loggingService, backofficeEndpoint)
    const adminArticleService = new AdminArticleService(httpService, loggingService, backofficeEndpoint, storeService)
    const bookingCodesService = new BookingCodesService(httpService, loggingService, backofficeEndpoint)
    const notificationCampaignsService = new NotificationCampaignsService(httpService, mendixEndpoint)
    const ticketTemplatesService = new TicketTemplatesService(httpService, loggingService, backofficeEndpoint)
    const requestService = new RequestService(httpService, dashboardAPIEndpoint)
    const paypalService = new PaypalService(httpService, backofficeEndpoint)
    const managementService = new ManagementService(httpService, backofficeEndpoint, dashboardAPIEndpoint)
    const optionsService = new OptionsService(httpService, loggingService, backofficeEndpoint)
    const integrationsService = new IntegrationsService(httpService, loggingService, backofficeEndpoint)
    const parkMapService = new ParkMapService(httpService, loggingService, backtapEndpoint)
    const angeliaNotificationsService = new AngeliaNotificationsService(
        httpService,
        loggingService,
        angeliaNotificationsEndpoint,
    )
    const audienceService = new AudienceService(httpService, loggingService, audienceBuilderEndpoint)
    const capacityReportService = new CapacityReportService(httpService, backofficeEndpoint)
    const sigmaService = new SigmaService(httpService, backofficeEndpoint)
    const paymentPlansService = new PaymentPlansService(httpService, backofficeEndpoint)

    return {
        httpService,
        userService,
        componentsService,
        impersonationService,
        loggingService,
        loginService,
        passwordResetService,
        signUpService,
        reCaptchaService,
        ssoService,
        pricingService,
        articlePricingService,
        accountService,
        bookingFeesService,
        taxConfigurationsService,
        timeSlotsService,
        openingTimesService,
        inventoryService,
        productsListService,
        articleService,
        statsService,
        articleConfigurationService,
        channelsService,
        notificationsService,
        googleTTDService,
        ordersService,
        tapChannelService,
        discountRulesService,
        copyEditorService,
        imagesService,
        accountSettingsService,
        crmService: crmService,
        snippetService,
        localeService,
        engageToolsService,
        tapPLacementService,
        tapPromotionService,
        emailTemplatesService,
        adminArticleService,
        bookingCodesService,
        notificationCampaignsService,
        ticketTemplatesService,
        requestService,
        paypalService,
        managementService,
        optionsService,
        integrationsService,
        parkMapService,
        angeliaNotificationsService,
        audienceService,
        reservationsService,
        capacityReportService,
        sigmaService,
        paymentPlansService,
    }
}

export let AppServices: ReturnType<typeof createServices> = createServices()
