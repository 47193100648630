import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { match as RouteMatch } from 'react-router-dom'
import { History } from 'history'
import { Navigation } from 'navigation'
import { delay } from 'utils'
import { withFeatures } from 'features'
import { ContainerBody } from 'uiComponents/settingsContainer'
import { ImageUploadResponse } from 'http/imagesService'
import { ImagesServiceContext } from 'http/context'
import { ChannelsServiceContext } from 'channels/context'
import { usePrevious } from 'reactUtils'
import cloneDeep from 'lodash/cloneDeep'
import { withNavigation } from 'hocs'
import { PageLoader } from 'uiComponents/loaders'
import { ActionButton } from 'uiComponents/buttons'
import { MessageKind } from 'uiComponents/messages'
import { ButtonWrapper } from 'uiComponents/pageElements'
import { FormTogglerCol, FormToggler, SingleSelect, SingleSelectOption, IconContainer, Icon } from 'uiComponents/input'
import { Col, Row } from 'uiComponents/flex'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { ArticleService, ProductListData, Locale } from 'admin/articleService'
import { GeneralInfoSection } from './generalInfo'
import { ResellersSection } from './resellersSection'
import Feature from 'features/feature'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import CopyToClipboard from 'react-copy-to-clipboard'
import Infotip from 'uiComponents/infotip'
import { QuantityPickerType } from 'admin/articleService'
import { retrieveImgDimentions } from 'uiComponents/input/upload'
import { LEFT_SIDE_SPAN, RIGHT_SIDE_SPAN } from 'products/crud/common'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Commission from '../commission'
import { FormWrapper } from 'uiComponents/form/form'
import PermissionRequired from 'admin/permissionRequired'
import { isEmpty } from 'lodash'

const InfoBlock = styled.div`
    margin-top: 0.5em;
    font-size: 0.75em;
    font-weight: 300;
    padding: 0.75em;
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 4px;
    background: ${(props) => props.theme.colors.tableRow};
    margin-right: -10em;
    width: 100%;
`
const LinkWrapper = styled.div`
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4em;
    margin: 0.5em 0;
    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.boyBlue};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
const CodeWrapper = styled(LinkWrapper)`
    font-family: consolas;
`

interface PageParams {
    accountSlug: string
    id: string
}

interface ProductListDetailFormProps {
    history: History
    match: RouteMatch<PageParams>
    hasFeature: (feature: string, slug: string) => boolean
    articleService: ArticleService
    navigation: Navigation
    setActiveSection: (section: string, header?: string) => void
    className?: string
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    viewMode: 'flat' | 'nested' | ''
}

export const dummyCategoryData: ProductListData = {
    id: null,
    title: { key: '', text: { en: '' } },
    description: null,
    timeSlotInfo: null,
    parentId: null,
    priority: 1,
    flow: null,
    confirmationFlow: 'payment_required',
    enabled: false,
    image: null,
    squareImage: null,
    imageType: null,
    timeSlotsEnabled: false,
    canEnableTimeSlots: false,
    enabledForResellers: false,
    visibleToAllResellers: true,
    visibleToSelectedResellers: [],
    showInNewOrder: false,
    showInCheckout: false,
    deeplinkUrl: '',
    bookingHorizon: null,
    releaseTime: null,
    cardOpenByDefault: true,
    quantityPickerType: null,
    isForGroupBookings: false,
    isLeaf: false,
}

const quantityPickerOptions = [
    { name: 'Unset', value: 'none' },
    { name: 'Select amount', value: 'select' },
    { name: 'Enter amount', value: 'input' },
]

function ProductListDetailForm(props: ProductListDetailFormProps) {
    const accountSlug = props.match.params.accountSlug
    const imagesService = React.useContext(ImagesServiceContext)
    const channelsService = React.useContext(ChannelsServiceContext)
    const [resellersOptions, setResellersOptions] = useState<SingleSelectOption[]>([])
    const [locales, setLocales] = useState<Locale[] | null>(null)
    const [categoryData, setCategoryData] = useState<ProductListData>(dummyCategoryData)
    const [loading, setLoading] = useState<boolean>(false)
    const [backendError, setBackendError] = useState<boolean>(false)
    const [localesWithMissingName, setLocalesWithMissingName] = useState<string[]>([])
    const [localesWithMissingDescription, setLocalesWithMissingDescription] = useState<string[]>([])
    const [localesWithMissingTimeSlotInfo, setLocalesWithMissingTimeSlotInfo] = useState<string[]>([])
    const [onSaveClicked, setOnSaveClicked] = useState<boolean>(false)
    const [activeLocaleIndex, setActiveLocaleIndex] = useState<number>(0)
    const [showBookingLimitsError, setShowBookingLimitsError] = useState<boolean>(false)

    async function getResellers() {
        const resellersList = await channelsService.getResellersList(accountSlug)
        const options = resellersList.map((reseller) => ({
            value: reseller.resellerId,
            name: reseller.name,
        }))
        setResellersOptions(options)
    }

    useEffect(() => {
        async function getCategoryDetails(id: string) {
            setBackendError(false)
            props.setActiveSection('productsList')
            try {
                const pl = await props.articleService.fetchProductList(accountSlug, id)
                setCategoryData(pl)
            } catch {
                props.replaceMessages('server_error', 'error', 'Oops! An unknown error occured.')
                setBackendError(true)
            }
        }
        async function getAccountCategoriesAndLocales() {
            setLoading(true)
            try {
                if (props.hasFeature('ResellersFeature', accountSlug)) {
                    await getResellers()
                }
                const accountLocales = await props.articleService.getAccountLocales(accountSlug)
                setLocales(accountLocales.locales)
                setLoading(false)
            } catch {
                setLoading(false)
                props.replaceMessages(
                    'server_error',
                    'error',
                    'Oops! Could not get some category data, please try again later.',
                )
            }
        }

        getAccountCategoriesAndLocales()
        const categoryId = props.match.params.id
        if (!!categoryId && categoryId !== 'new') {
            getCategoryDetails(categoryId)
        }
    }, [])

    const prevAccountSlug = usePrevious(accountSlug)
    useEffect(() => {
        if (!!prevAccountSlug && prevAccountSlug !== accountSlug) {
            props.history.push(`/account/${accountSlug}/products/home/${props.viewMode === 'flat' ? 'flat' : 'nested'}`)
        }
    }, [accountSlug])

    function onWideImageUploadSuccess(response: ImageUploadResponse) {
        const updatedCategoryData = cloneDeep(categoryData)
        updatedCategoryData.image = response.url
        setCategoryData(updatedCategoryData)
    }

    function onSquareImageUploadSuccess(response: ImageUploadResponse) {
        const updatedCategoryData = cloneDeep(categoryData)
        updatedCategoryData.squareImage = response.url
        setCategoryData(updatedCategoryData)
    }

    async function onWideImageUpload(file: File) {
        return await imagesService.uploadImage(file, {
            max_height: 300,
            max_width: 450,
        })
    }

    async function onSquareImageUpload(file: File) {
        const dimentions = await retrieveImgDimentions(file)
        if (!dimentions) {
            return
        }
        if (!!dimentions && dimentions.width !== dimentions.height) {
            return Promise.reject({
                message: 'The image you are trying to upload is not square.',
            })
        }
        return await imagesService.uploadImage(file, {
            max_height: 300,
            max_width: 300,
        })
    }

    function cleanupData(data: ProductListData) {
        if (isEmpty(data.description?.text)) {
            data.description = null
        }
        if (isEmpty(data.timeSlotInfo?.text)) {
            data.timeSlotInfo = null
        }
        if (data.visibleToAllResellers && data.visibleToSelectedResellers.length) {
            const cleanData = cloneDeep(data)
            cleanData.visibleToSelectedResellers = []
            return cleanData
        }

        return data
    }

    async function onSaveCategory(values: ProductListData) {
        if (!isFormValid()) {
            props.replaceMessages('validation', 'error', 'Please fix the validation errors.')
            return
        }
        const id = props.match.params.id
        setLoading(true)
        try {
            const cleandeupData = cleanupData({
                ...categoryData,
                ...values,
            })
            if (id === 'new') {
                const savedCategory = await props.articleService.createProductList(accountSlug, cleandeupData)
                const categoryId = savedCategory.id
                props.history.push(
                    `/account/${accountSlug}/products/crud/productList/${categoryId}?from=${props.viewMode}`,
                )
            } else {
                const savedCategory = await props.articleService.updateProductList(accountSlug, cleandeupData, id)
                setCategoryData(savedCategory)
            }
            props.replaceMessages('success', 'success', 'The category has been saved successfully.')
            setLoading(false)
            await delay(3000)
            props.removeAllMessages()
        } catch (e) {
            const errorMessage =
                e.message === 'cannot_enable_time_slots'
                    ? 'Oops! Could not save the category with the time slots enabled, because there are no time slot groups available.'
                    : e.message === 'invalid_for_group_booking'
                    ? 'Product list contains articles that cannot be set to group scannable.'
                    : 'Oops! Could not save the category, please try again later.'

            props.replaceMessages('server_error', 'error', errorMessage)
            if (id !== 'new') {
                const previousCategoryData = await props.articleService.fetchProductList(accountSlug, id)
                setCategoryData(previousCategoryData)
            }
            setLoading(false)
        }
    }

    function getLocaleIndex(localeWithMissingName: string) {
        let localeIndex = null
        locales?.map((locale, index) => {
            if (locale.code === localeWithMissingName) {
                localeIndex = index
            }
        })
        return localeIndex
    }

    function activateLocaleWithMissingValue(localeWithMissingValue: string) {
        const activeLocaleIndex = getLocaleIndex(localeWithMissingValue)
        if (activeLocaleIndex !== null) {
            setActiveLocaleIndex(activeLocaleIndex)
        }
    }

    function onActiveToggle(newValue: boolean) {
        const newData = cloneDeep(categoryData)
        newData.enabled = newValue
        setCategoryData(newData)
    }

    function onTimeslotsToggle(newValue: boolean) {
        const newData = cloneDeep(categoryData)
        newData.timeSlotsEnabled = newValue
        setCategoryData(newData)
    }

    function onIsForGroupBookingsToggle(newValue: boolean) {
        const newData = cloneDeep(categoryData)
        newData.isForGroupBookings = newValue
        setCategoryData(newData)
    }

    function onShowInNewOrderToggle(newValue: boolean) {
        const newData = cloneDeep(categoryData)
        newData.showInNewOrder = newValue
        setCategoryData(newData)
    }

    function onShowInCheckoutToggle(newValue: boolean) {
        const newData = cloneDeep(categoryData)
        newData.showInCheckout = newValue
        setCategoryData(newData)
    }

    function onQuantityPickerTypeSelect(newValue: string) {
        const newData = cloneDeep(categoryData)
        if (newValue === 'none') {
            newData.quantityPickerType = null
        } else {
            newData.quantityPickerType = newValue as QuantityPickerType
        }
        setCategoryData(newData)
    }

    function validateLocales() {
        if (!locales) {
            return false
        }

        setLocalesWithMissingName([])
        setLocalesWithMissingDescription([])
        setLocalesWithMissingTimeSlotInfo([])

        let missingTranslations = false

        const localesWithoutName = locales
            .filter((locale) => !categoryData.title.text[locale.code])
            .map((locale) => locale.code)

        if (localesWithoutName.length) {
            setLocalesWithMissingName(localesWithoutName)
            activateLocaleWithMissingValue(localesWithoutName[0])
            missingTranslations = true
        }

        const atLeastOneDescription = !!locales.find((locale) => !!categoryData.description?.text[locale.code])
        const localesWithoutDescription = locales
            .filter((locale) => !categoryData.description?.text[locale.code])
            .map((locale) => locale.code)

        if (atLeastOneDescription) {
            if (localesWithoutDescription.length) {
                setLocalesWithMissingDescription(localesWithoutDescription)
                if (!missingTranslations) {
                    activateLocaleWithMissingValue(localesWithoutDescription[0])
                    missingTranslations = true
                }
            }
        } else {
            categoryData.description = null
        }

        const atLeastOneTimeSlotInfo = !!locales.find((locale) => !!categoryData.timeSlotInfo?.text[locale.code])
        const localesWithoutTimeSlotInfo = locales
            .filter((locale) => !categoryData.timeSlotInfo?.text[locale.code])
            .map((locale) => locale.code)

        if (atLeastOneTimeSlotInfo) {
            if (localesWithoutTimeSlotInfo.length) {
                setLocalesWithMissingTimeSlotInfo(localesWithoutTimeSlotInfo)
                if (!missingTranslations) {
                    activateLocaleWithMissingValue(localesWithoutTimeSlotInfo[0])
                    missingTranslations = true
                }
            }
        } else {
            categoryData.timeSlotInfo = null
        }

        return !missingTranslations
    }

    const validateBookingLimits = () => {
        const bookingLimitsError =
            !!categoryData.bookingHorizon &&
            !!categoryData.releaseTime &&
            categoryData.bookingHorizon <= categoryData.releaseTime

        setShowBookingLimitsError(bookingLimitsError)
        return !bookingLimitsError
    }

    const validateResellersSelection = () => {
        const missingResellersSelection =
            categoryData.enabledForResellers &&
            !categoryData.visibleToAllResellers &&
            !categoryData.visibleToSelectedResellers.length

        return !missingResellersSelection
    }

    function isFormValid() {
        setOnSaveClicked(true)
        setTimeout(() => setOnSaveClicked(false), 500)

        return validateLocales() && validateResellersSelection() && validateBookingLimits()
    }

    const timeSlotsToggleDisabled =
        !categoryData.canEnableTimeSlots && !categoryData.timeSlotsEnabled && props.match.params.id !== 'new'

    const isForGroupBookingsToggleEnabled = categoryData.flow && ['dated', 'rtp'].includes(categoryData.flow)

    const internalDeepLinkHtmlCode = `<a href="${categoryData.deeplinkUrl.replace(/.*(?=#)/, '')}">Your text here</a>`
    const externalDeepLinkHtmlCode = `<a href="${categoryData.deeplinkUrl}">Your text here</a>`

    return (
        <FormWrapper
            enableReinitialize
            initialValues={categoryData}
            style={{ position: 'relative' }}
            formId="product-category-details-page"
            onSubmit={onSaveCategory}
        >
            {({ submitForm }) => {
                return (
                    <>
                        {loading && <PageLoader style={{ paddingBottom: '4em' }} />}
                        {!loading && !backendError && categoryData && locales && (
                            <ContainerBody style={{ flexDirection: 'column' }} id="product-list-form">
                                <Row>
                                    <Col span={LEFT_SIDE_SPAN}>
                                        <GeneralInfoSection
                                            accountSlug={accountSlug}
                                            activeLocaleIndex={activeLocaleIndex}
                                            setActiveLocaleIndex={setActiveLocaleIndex}
                                            localesWithMissingName={localesWithMissingName}
                                            localesWithMissingDescription={localesWithMissingDescription}
                                            localesWithMissingTimeSlotInfo={localesWithMissingTimeSlotInfo}
                                            showBookingLimitsError={showBookingLimitsError}
                                            locales={locales}
                                            categoryData={categoryData}
                                            updateData={setCategoryData}
                                            onWideImageUpload={onWideImageUpload}
                                            onSquareImageUpload={onSquareImageUpload}
                                            onWideImageUploadSuccess={onWideImageUploadSuccess}
                                            onSquareImageUploadSuccess={onSquareImageUploadSuccess}
                                            replaceMessages={props.replaceMessages}
                                            removeAllMessages={props.removeAllMessages}
                                            viewMode={props.viewMode}
                                            hasFeature={props.hasFeature}
                                        />
                                    </Col>
                                    <Col span={RIGHT_SIDE_SPAN}>
                                        <Row>
                                            <FormItem
                                                htmlFor="quantity-picker-type"
                                                style={{ position: 'relative', margin: '1em 0' }}
                                            >
                                                <FormItemName style={{ marginTop: 0 }}>
                                                    Quantity picker type
                                                </FormItemName>
                                                <SingleSelect
                                                    id="quantity-picker-type"
                                                    height="2.8em"
                                                    maxHeight="11em"
                                                    options={quantityPickerOptions}
                                                    selected={categoryData.quantityPickerType || 'none'}
                                                    onSelect={onQuantityPickerTypeSelect}
                                                />
                                            </FormItem>
                                        </Row>
                                        <Row>
                                            <FormTogglerCol span={12} style={{ marginTop: '1em' }}>
                                                <FormItemName>Time slots enabled</FormItemName>
                                                <DisabledItemHoverInfotip
                                                    active={timeSlotsToggleDisabled}
                                                    infotipText="There are no available time slots"
                                                    width="20em"
                                                >
                                                    <FormToggler
                                                        id="timeslots-enabled"
                                                        isOn={categoryData.timeSlotsEnabled}
                                                        onClick={onTimeslotsToggle}
                                                        disabled={timeSlotsToggleDisabled}
                                                    />
                                                </DisabledItemHoverInfotip>
                                            </FormTogglerCol>
                                        </Row>
                                        <Feature name="GroupBarcodeScanning" accountSlug={accountSlug}>
                                            <Row>
                                                <FormTogglerCol span={12} style={{ marginTop: '1.5em' }}>
                                                    <FormItemName>
                                                        Enable group scanning
                                                        <Infotip pointer="left" maxWidth="20em">
                                                            Make articles in this category group scannable, only
                                                            requiring one barcode scan for all tickets in the order.
                                                        </Infotip>
                                                    </FormItemName>
                                                    <FormToggler
                                                        id="is-for-group-bookings"
                                                        isOn={categoryData.isForGroupBookings}
                                                        onClick={onIsForGroupBookingsToggle}
                                                        disabled={!isForGroupBookingsToggleEnabled}
                                                    />
                                                </FormTogglerCol>
                                            </Row>
                                            {!isForGroupBookingsToggleEnabled && (
                                                <Row>
                                                    <InfoBlock>
                                                        <IconContainer
                                                            style={{ display: 'inline-block', marginRight: '5px' }}
                                                        >
                                                            <Icon
                                                                icon={faExclamationTriangle}
                                                                className="visible"
                                                                style={{ position: 'relative' }}
                                                            />
                                                        </IconContainer>
                                                        Group scanning can only be enabled for Dated and Calendar view
                                                        flows.
                                                    </InfoBlock>
                                                </Row>
                                            )}
                                        </Feature>
                                        <Row>
                                            <FormTogglerCol span={12} style={{ marginTop: '1.5em' }}>
                                                <FormItemName>
                                                    Activate or deactivate the category
                                                    <Infotip pointer="left" maxWidth="20em">
                                                        If this toggle is off, the category will not be visible at all.
                                                    </Infotip>
                                                </FormItemName>
                                                <FormToggler
                                                    id="enabled"
                                                    isOn={categoryData.enabled}
                                                    onClick={onActiveToggle}
                                                />
                                            </FormTogglerCol>
                                        </Row>
                                        <Feature name="ResellersFeature" accountSlug={accountSlug}>
                                            <ResellersSection
                                                accountSlug={accountSlug}
                                                categoryData={categoryData}
                                                resellersOptions={resellersOptions}
                                                setCategoryData={(data) => setCategoryData(data)}
                                                validate={onSaveClicked}
                                            />
                                        </Feature>
                                        <Row>
                                            <FormTogglerCol span={12} style={{ marginTop: '1.5em' }}>
                                                <FormItemName>
                                                    <span className={!categoryData.enabled ? 'disabled' : ''}>
                                                        Show in New Order
                                                    </span>
                                                    <Infotip pointer="left" maxWidth="20em">
                                                        Show this category in the New Order flow in the Control Panel
                                                    </Infotip>
                                                </FormItemName>
                                                <DisabledItemHoverInfotip
                                                    active={!categoryData.enabled}
                                                    infotipText="The category is disabled"
                                                    width="20em"
                                                >
                                                    <FormToggler
                                                        id="category-show-in-new-order"
                                                        isOn={categoryData.showInNewOrder}
                                                        onClick={onShowInNewOrderToggle}
                                                        className={!categoryData.enabled ? 'disabled' : ''}
                                                        disabled={!categoryData.showInNewOrder && !categoryData.enabled}
                                                    />
                                                </DisabledItemHoverInfotip>
                                            </FormTogglerCol>
                                        </Row>
                                        <Row>
                                            <FormTogglerCol span={12} style={{ marginTop: '1.5em' }}>
                                                <FormItemName>
                                                    <span className={!categoryData.enabled ? 'disabled' : ''}>
                                                        Show in Checkout
                                                    </span>
                                                    <Infotip pointer="left" maxWidth="20em">
                                                        If this toggle is off, the active category will only be
                                                        accessible via the deep link.
                                                    </Infotip>
                                                </FormItemName>
                                                <DisabledItemHoverInfotip
                                                    active={!categoryData.enabled}
                                                    infotipText="The category is disabled"
                                                    width="20em"
                                                >
                                                    <FormToggler
                                                        id="category-show-in-checkout"
                                                        isOn={categoryData.showInCheckout}
                                                        onClick={onShowInCheckoutToggle}
                                                        className={!categoryData.enabled ? 'disabled' : ''}
                                                        disabled={!categoryData.showInCheckout && !categoryData.enabled}
                                                    />
                                                </DisabledItemHoverInfotip>
                                            </FormTogglerCol>
                                        </Row>
                                        {categoryData.deeplinkUrl && (
                                            <Row>
                                                <FormTogglerCol span={12} style={{ marginTop: '1em' }}>
                                                    <InfoBlock>
                                                        <FormItemName>Category deep link</FormItemName>
                                                        URL:
                                                        <LinkWrapper>
                                                            <a
                                                                title={categoryData.deeplinkUrl}
                                                                href={categoryData.deeplinkUrl}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {categoryData.deeplinkUrl}
                                                            </a>
                                                            <CopyToClipboard text={categoryData.deeplinkUrl}>
                                                                <ActionButton
                                                                    size="small"
                                                                    kind="action"
                                                                    style={{ marginLeft: '0.5em' }}
                                                                >
                                                                    Copy
                                                                </ActionButton>
                                                            </CopyToClipboard>
                                                        </LinkWrapper>
                                                        HTML code:
                                                        <CodeWrapper>
                                                            {internalDeepLinkHtmlCode}
                                                            <CopyToClipboard text={internalDeepLinkHtmlCode}>
                                                                <ActionButton
                                                                    size="small"
                                                                    kind="action"
                                                                    style={{ marginLeft: '0.5em' }}
                                                                >
                                                                    Copy
                                                                </ActionButton>
                                                            </CopyToClipboard>
                                                        </CodeWrapper>
                                                        HTML code (external website):
                                                        <CodeWrapper>
                                                            {externalDeepLinkHtmlCode}
                                                            <CopyToClipboard text={externalDeepLinkHtmlCode}>
                                                                <ActionButton
                                                                    size="small"
                                                                    kind="action"
                                                                    style={{ marginLeft: '0.5em' }}
                                                                >
                                                                    Copy
                                                                </ActionButton>
                                                            </CopyToClipboard>
                                                        </CodeWrapper>
                                                    </InfoBlock>
                                                </FormTogglerCol>
                                            </Row>
                                        )}
                                    </Col>
                                    <PermissionRequired accountSlug={accountSlug} name="convious_admin">
                                        <Col span={12} style={{ margin: '1em 0' }}>
                                            <Commission commissionTooltipText="This field represents the default commission group assigned to products listed in the product list." />
                                        </Col>
                                    </PermissionRequired>
                                </Row>
                                <ButtonWrapper style={{ marginTop: '2em' }}>
                                    <ActionButton
                                        size="large"
                                        secondary
                                        onClick={() =>
                                            props.history.push(
                                                `/account/${accountSlug}/products/home/${props.viewMode}`,
                                            )
                                        }
                                    >
                                        Cancel
                                    </ActionButton>
                                    <ActionButton
                                        id="saveStatus"
                                        type="button"
                                        size="large"
                                        onClick={submitForm}
                                        style={{ marginLeft: '1.5em' }}
                                    >
                                        Save
                                    </ActionButton>
                                </ButtonWrapper>
                            </ContainerBody>
                        )}
                    </>
                )
            }}
        </FormWrapper>
    )
}

export default withNavigation(withFeatures(ProductListDetailForm))
