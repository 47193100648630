import React from 'react'
import { ChevronDownSolid } from '@convious/icons'
import './styles.scss'
import classNames from 'classnames'

const ExpandIcon = ({
    isExpanded,
    setIsExpanded,
}: {
    isExpanded: boolean
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}) => (
    <div className="expand-icon-container" onClick={() => setIsExpanded((prev) => !prev)}>
        <ChevronDownSolid className={classNames('expand-icon', { expanded: isExpanded })} />
    </div>
)

export default ExpandIcon
