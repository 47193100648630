import React from 'react'
import classNames from 'classnames'
import { Checkbox } from 'uiComponents/input'
import './paymentPlanInstallments.scss'

interface PaymentPlanInstallmentsProps {
    allowYearlyInstallments: boolean
    allowMonthlyInstallments: boolean
    setAllowYearlyInstallments: (allowYearlyInstallments: boolean) => void
    setAllowMonthlyInstallments: (allowMonthlyInstallments: boolean) => void
    error: string
}

const PaymentPlanInstallments = ({
    allowYearlyInstallments,
    allowMonthlyInstallments,
    setAllowYearlyInstallments,
    setAllowMonthlyInstallments,
    error,
}: PaymentPlanInstallmentsProps) => (
    <div className={classNames('payment-plan-installments', { error: error })}>
        <h6>Payment plans</h6>
        <div className="payment-plan-installments-explanation">
            Memberships are valid from the moment of purchase, for a full year. Select which payment plans your
            customers can choose between.
        </div>
        <div
            className={classNames('payment-plan-installment-option', { selected: allowYearlyInstallments })}
            onClick={() => {
                setAllowYearlyInstallments(!allowYearlyInstallments)
            }}
        >
            <Checkbox id="payment-plan-installment-yearly" checked={allowYearlyInstallments} />
            <div className="payment-plan-installment-option-label">
                <h6>Annual plan</h6>
                Allow customers to pay for the full year upfront.
            </div>
        </div>
        <div
            className={classNames('payment-plan-installment-option', { selected: allowMonthlyInstallments })}
            onClick={() => {
                setAllowMonthlyInstallments(!allowMonthlyInstallments)
            }}
        >
            <Checkbox id="payment-plan-installment-monthly" checked={allowMonthlyInstallments} />
            <div className="payment-plan-installment-option-label">
                <h6>Monthly plan</h6>
                Allow customers to pay in monthly installments.
            </div>
        </div>
        {error && <div className="error-message">{error}</div>}
    </div>
)

export default PaymentPlanInstallments
