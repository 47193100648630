import { AuthenticatedHttpService } from 'http/httpService'

export interface EmbedUrlParams {
    disable_mobile_view?: string
    lng?: string
    hide_folder_navigation?: boolean
    hide_menu?: boolean
    hide_run_as_recipient?: boolean
    hide_schedule?: boolean
    hide_send?: boolean
    hide_sheet_interactions?: boolean
    hide_tooltip?: boolean
    loading_bg?: string
    loading_text?: string
    menu_position?: 'top' | 'bottom' | 'none'
    responsive_height?: boolean
    show_footer?: boolean
    showUnderlyingData?: string
    theme?: string
}

export class SigmaService {
    constructor(private httpService: AuthenticatedHttpService, private backofficeEndpoint: string) {}

    async getEmbedUrl(accountSlug: string, reportId: string, urlParams?: EmbedUrlParams): Promise<string> {
        const embedUrlEndpoint = `${this.backofficeEndpoint}dashboard_api/reports/account/${accountSlug}/embed_url/`
        const body = JSON.stringify({
            report_id: reportId,
            urlParams,
        })

        const response = await this.httpService.fetch(embedUrlEndpoint, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        const payload = await response.json()
        return payload.url
    }
}
