import React, { useState, useEffect } from 'react'
import { NumberInput } from 'uiComponents/input'
import { WrapWithSymbol } from 'uiComponents/form/formElements'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import './paymentPlanPricing.scss'
import SliderInput from 'uiComponents/input/slider/sliderInput'
import classNames from 'classnames'

interface PaymentPlanPricingProps {
    totalPriceYearly: number
    totalPriceMonthly: number
    allowYearlyInstallments: boolean
    allowMonthlyInstallments: boolean
    initialPayment: number
    onTotalPriceYearlyChange: (value: number) => void
    onTotalPriceMonthlyChange: (value: number) => void
    onInitialPaymentChange: (value: number) => void
    errors: Record<string, string>
    accountSlug: string
    getCurrency: (accountSlug: string) => Currency
}

const PaymentPlanPricing = ({
    totalPriceYearly,
    totalPriceMonthly,
    allowYearlyInstallments,
    allowMonthlyInstallments,
    initialPayment,
    onTotalPriceYearlyChange,
    onTotalPriceMonthlyChange,
    onInitialPaymentChange,
    errors,
    accountSlug,
    getCurrency,
}: PaymentPlanPricingProps) => {
    const currency = getCurrency(accountSlug)
    const averageMonthlyPrice = Number((totalPriceMonthly / 12).toFixed(2))
    const [monthlyPayment, setMonthlyPayment] = useState(averageMonthlyPrice)

    useEffect(() => {
        const amountLeft = totalPriceMonthly - initialPayment
        setMonthlyPayment(Number((amountLeft / 11).toFixed(2)))
    }, [initialPayment])

    const onMonthlyPriceChange = (newMonthlyPrice: number) => {
        setMonthlyPayment(newMonthlyPrice)
        const newInitialPayment = totalPriceMonthly - newMonthlyPrice * 11
        onInitialPaymentChange(Number(newInitialPayment.toFixed(2)))
    }

    return (
        <div className="payment-plan-pricing">
            {allowYearlyInstallments && (
                <div
                    className={classNames('payment-plan-pricing-totals-yearly', {
                        error: errors.totalPriceYearly,
                    })}
                >
                    <h6>Annual plan total price</h6>
                    <WrapWithSymbol symbol={currency.symbol} position="left">
                        <NumberInput
                            id="total-price-yearly"
                            name="total-price-yearly"
                            maxLength={7}
                            max={9999}
                            value={totalPriceYearly || ''}
                            placeholder="200"
                            resetToZero={false}
                            onChange={(e) => onTotalPriceYearlyChange(Number(e.target.value))}
                        />
                    </WrapWithSymbol>
                    {errors.totalPriceYearly && <div className="error-message">{errors.totalPriceYearly}</div>}
                </div>
            )}
            {allowMonthlyInstallments && (
                <div
                    className={classNames('payment-plan-pricing-totals-monthly', {
                        error: errors.totalPriceMonthly,
                    })}
                >
                    <h6>Monthly plan total price</h6>
                    <WrapWithSymbol symbol={currency.symbol} position="left">
                        <NumberInput
                            id="total-price-monthly"
                            name="total-price-monthly"
                            maxLength={7}
                            min={totalPriceYearly}
                            max={9999}
                            value={totalPriceMonthly || ''}
                            placeholder="240"
                            autoCorrectInput={false}
                            resetToZero={false}
                            onChange={(e) => onTotalPriceMonthlyChange(Number(e.target.value))}
                        />
                    </WrapWithSymbol>
                    {errors.totalPriceMonthly && <div className="error-message">{errors.totalPriceMonthly}</div>}
                </div>
            )}
            {allowMonthlyInstallments && (
                <div className="payment-plan-pricing-initial-payment">
                    <div className="payment-plan-pricing-initial-payment-form">
                        <div
                            className={classNames('initial-payment', {
                                error: errors.initialPayment,
                            })}
                        >
                            <h6>First month</h6>
                            <WrapWithSymbol symbol={currency.symbol} position="left">
                                <NumberInput
                                    name="initial-payment"
                                    title="Initial monthly payment"
                                    maxLength={7}
                                    min={averageMonthlyPrice}
                                    max={totalPriceMonthly}
                                    placeholder="20"
                                    step={averageMonthlyPrice}
                                    value={initialPayment || ''}
                                    resetToZero={false}
                                    onChange={(e) => onInitialPaymentChange(Number(e.target.value))}
                                />
                            </WrapWithSymbol>
                        </div>
                        <div className="initial-payment-slider">
                            {/* Mirror the slider using negative values */}
                            <SliderInput
                                name="initial-payment"
                                min={-totalPriceMonthly + Math.min(11, averageMonthlyPrice)}
                                max={-averageMonthlyPrice}
                                step={5}
                                restrictSteps={true}
                                value={-initialPayment}
                                onChange={(e, value) => onInitialPaymentChange(-Number(value))}
                            />
                        </div>
                        <div
                            className={classNames('monthly-payment', {
                                error: errors.monthlyPayment,
                            })}
                        >
                            <h6>Remaining 11 months</h6>
                            <WrapWithSymbol symbol={currency.symbol} position="left">
                                <NumberInput
                                    name="monthly-payment"
                                    title="Recurring monthly payment"
                                    maxLength={7}
                                    min={0}
                                    max={averageMonthlyPrice}
                                    placeholder="20"
                                    step={1}
                                    value={monthlyPayment || ''}
                                    resetToZero={false}
                                    onChange={(e) => onMonthlyPriceChange(Number(e.target.value))}
                                />
                            </WrapWithSymbol>
                            {errors.monthlyPayment && <div className="error-message">{errors.monthlyPayment}</div>}
                        </div>
                    </div>
                    <div className="initial-payment-explanation">
                        Adjust the balance between the initial payment and recurring monthly payments.
                        <br />
                        The final monthly payment may vary slightly due to rounding.
                    </div>
                </div>
            )}
        </div>
    )
}

export default withCurrency(PaymentPlanPricing)
