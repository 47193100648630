import React, { useState, useEffect } from 'react'
import './paymentPlanRenewal.scss'
import { Radio, RadioWrapper } from 'uiComponents/input/radio/radio'
import classNames from 'classnames'

interface RenewalOption {
    id: string
    name: string
    description: string
    renewAutomatically: boolean
    renewIntoMonthly: boolean
}

const renewalOptions: RenewalOption[] = [
    {
        id: 'doNotRenew',
        name: 'Do not renew',
        description: 'Upon expiration, customers will have to purchase a new one through the ticket shop.',
        renewAutomatically: false,
        renewIntoMonthly: false,
    },
    {
        id: 'renewIntoSame',
        name: 'Renew into same plan',
        description: 'Upon expiration, the membership will be automatically renewed into the same payment plan.',
        renewAutomatically: true,
        renewIntoMonthly: false,
    },
    {
        id: 'renewIntoMonthly',
        name: 'Renew into monthly plan',
        description:
            'Upon expiration, the membership will be automatically renewed into a monthly payment plan, regardless of their current plan.',
        renewAutomatically: true,
        renewIntoMonthly: true,
    },
]

interface PaymentPlanRenewalProps {
    renewAutomatically: boolean
    renewIntoMonthly: boolean
    onRenewAutomaticallyChange: (value: boolean) => void
    onRenewIntoMonthlyChange: (value: boolean) => void
}

const PaymentPlanRenewal = ({
    renewAutomatically,
    renewIntoMonthly,
    onRenewAutomaticallyChange,
    onRenewIntoMonthlyChange,
}: PaymentPlanRenewalProps) => {
    const [selectedOption, setSelectedOption] = useState(
        () =>
            renewalOptions.find(
                (option) =>
                    option.renewAutomatically === renewAutomatically && option.renewIntoMonthly === renewIntoMonthly,
            ) ?? renewalOptions[0],
    )

    useEffect(() => {
        onRenewAutomaticallyChange(selectedOption.renewAutomatically)
        onRenewIntoMonthlyChange(selectedOption.renewIntoMonthly)
    }, [selectedOption, onRenewAutomaticallyChange, onRenewIntoMonthlyChange])

    const handleRenewalOptionChange = (optionId: string) => {
        const newOption = renewalOptions.find((option) => option.id === optionId)
        if (newOption) {
            setSelectedOption(newOption)
        }
    }

    return (
        <div className="payment-plan-renewal">
            <h6 className="payment-plan-renewal-title">Automatic renewal</h6>
            <div className="payment-plan-renewal-option-container">
                {renewalOptions.map((option) => (
                    <div
                        key={option.id}
                        className={classNames('payment-plan-renewal-option', {
                            selected: selectedOption.id === option.id,
                        })}
                    >
                        <RadioWrapper>
                            <Radio
                                name="renewal-option"
                                className="payment-plan-renewal-option-input"
                                value={option.id}
                                checked={selectedOption.id === option.id}
                                onChange={() => handleRenewalOptionChange(option.id)}
                            />
                            <div className="payment-plan-renewal-option-text">
                                <div className="payment-plan-renewal-option-title">{option.name}</div>
                                <div className="payment-plan-renewal-option-description">{option.description}</div>
                            </div>
                        </RadioWrapper>
                    </div>
                ))}
            </div>
            <div className="payment-plan-renewal-explanation">
                Always stay up to date on your local regulations and adjust these settings accordingly.
                <br />
                Members will be be notified of their upcoming expiry or renewal via email.
            </div>
        </div>
    )
}

export default PaymentPlanRenewal
