import {
    useDeleteCommentMutation,
    useLazyGetCartQuery,
    useLazyGetOrderDetailsByUuidQuery,
    usePatchCartMutation,
    usePatchOrderMutation,
} from 'orders/reduxQueries'
import { getActiveAccount } from 'preferences/selectors'
import { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'store/hooks'

type TypeMap = {
    order: {
        loadQuery: typeof useLazyGetOrderDetailsByUuidQuery
        patchMutation: typeof usePatchOrderMutation
        queryArgs: { uuid: string; include: string[] }
    }
    cart: {
        loadQuery: typeof useLazyGetCartQuery
        patchMutation: typeof usePatchCartMutation
        queryArgs: { uuid: string; accountSlug: string }
    }
}

type UseCommentsProps<T extends 'order' | 'cart'> = {
    type: T
    uuid: string
    loadQuery: TypeMap[T]['loadQuery']
    patchMutation: TypeMap[T]['patchMutation']
}

type CommentState = {
    content: string
    uuid?: string
}

export const useComments = <T extends 'order' | 'cart'>({
    type,
    uuid,
    loadQuery,
    patchMutation,
}: UseCommentsProps<T>): [
    CommentState | undefined,
    { isFetching: boolean; handleSetComment: (content: string) => Promise<void> },
] => {
    const accountSlug = useAppSelector(getActiveAccount)
    const [comment, setComment] = useState<CommentState | undefined>()
    const [remove] = useDeleteCommentMutation()
    const [patch] = patchMutation()

    const [query, { data, isFetching }] = loadQuery()

    const loadData = useCallback(() => {
        if (!accountSlug) throw new Error('No account')

        if (type === 'cart') {
            const cartQuery: ReturnType<TypeMap['cart']['loadQuery']>[0] = query
            const args: TypeMap[T]['queryArgs'] = { uuid, accountSlug }
            cartQuery(args)
        } else {
            const orderQuery: ReturnType<TypeMap['order']['loadQuery']>[0] = query
            const args: TypeMap[T]['queryArgs'] = { uuid, include: ['comment'] }
            orderQuery(args)
        }
    }, [accountSlug, uuid])

    useEffect(() => {
        loadData()
    }, [uuid, loadData, loadQuery])

    useEffect(() => {
        if (data?.comments?.length) {
            const { comments } = data
            const lastComment = comments[comments.length - 1]
            setComment(lastComment)
        }
    }, [data])

    const handleSetComment = useCallback(
        async (content) => {
            if (!accountSlug) throw new Error('No account')

            if (comment?.uuid && !content) {
                setComment(undefined)
                await remove({ accountSlug, commentId: comment.uuid })
            } else {
                setComment((prev) => ({ ...prev, content }))
                await patch({
                    accountSlug,
                    uuid,
                    overrides: {
                        comment: {
                            content,
                            ...(comment?.uuid ? { uuid: comment.uuid } : {}),
                        },
                    },
                })
            }

            loadData()
        },
        [comment, accountSlug, loadData, patch, remove, setComment],
    )

    return [comment, { handleSetComment, isFetching }]
}
