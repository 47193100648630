import * as React from 'react'
import classNames from 'classnames'
import { CancelRefundTableData, CancelRefundTableSection } from './types'
import { DataTable } from 'uiComponents/table'
import CancelRefundTableRow from './row'
import './styles.scss'

interface CancelRefundTableProps {
    accountSlug: string
    data: CancelRefundTableData
    onSelectItem: (parentId: string | null, id: string) => void
    onRefundFeeSelect: () => void
    onBookingFeeSelect: (parentId: string | null, id: string) => void
    confirmMode: boolean
}

const CancelRefundTable = ({
    data,
    onSelectItem,
    accountSlug,
    onRefundFeeSelect,
    confirmMode,
    onBookingFeeSelect,
}: CancelRefundTableProps) => {
    const renderSections = React.useCallback(
        (section: CancelRefundTableSection, index: number) => {
            return (
                <div
                    key={section.id}
                    className={classNames({
                        'border-bottom': section.borderBottom,
                        'border-top': section.borderTop,
                        'total-section': section.id === 'totalSection',
                    })}
                >
                    {section.rows.map((row) => {
                        return (
                            <CancelRefundTableRow
                                row={row}
                                onSelectItem={onSelectItem}
                                onRefundFeeSelect={onRefundFeeSelect}
                                isSelected={row.selected}
                                key={row.id}
                                accountSlug={accountSlug}
                                confirmMode={confirmMode}
                                onBookingFeeSelect={onBookingFeeSelect}
                            />
                        )
                    })}
                </div>
            )
        },
        [confirmMode, accountSlug, onSelectItem, onRefundFeeSelect],
    )

    return (
        <DataTable columnWidths={['45%', '40%', '15%']} allowOverflow className="cancel-refund-table">
            {data.sections.map((section, index) => renderSections(section, index))}
        </DataTable>
    )
}

export default CancelRefundTable
