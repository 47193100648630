import React, { FC } from 'react'
import { useLazyGetOrderDetailsByUuidQuery, usePatchOrderMutation } from 'orders/reduxQueries'
import Comment from 'uiComponents/comment/comment'
import { useComments } from 'uiComponents/comment/useComments'

type OrderCommentProps = {
    orderId: string
}

const OrderComment: FC<OrderCommentProps> = ({ orderId }) => {
    const [comment, { isFetching, handleSetComment }] = useComments({
        type: 'order',
        uuid: orderId,
        loadQuery: useLazyGetOrderDetailsByUuidQuery,
        patchMutation: usePatchOrderMutation,
    })

    return <Comment comment={comment} isFetching={isFetching} onHandleSetComment={handleSetComment} />
}

export default OrderComment
