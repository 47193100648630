import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sumBy } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import Big from 'big.js'

import { useLazyListCartsQuery } from 'orders/reduxQueries'
import { Cart as CartSchema } from 'orders/schema'
import { LoadDataParams, ReactTable, SortProps, TableColumn } from 'uiComponents/table/reactTable'
import { BaseRouteParams, useNavigation } from 'hocs'
import Money from 'uiComponents/money'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus'
import { pathUtils } from 'utils/pathnameFormatter'
import PaymentDeadlineCell from './paymentDeadlineCell'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { ORDER_PATHS } from 'orders/paths'
import { MarkCartAsPaidConfirmationDialog } from './modals/markCartAsPaidConfirmationDialog'
import TooltipWrapper from 'uiComponents/tooltip/tooltipWrapper'
import './cartTable.scss'
import { dateRangeFromQuery } from 'dateRanges'
import { format } from 'date-fns'
import { InlineEdit } from 'uiComponents/input'
import { DateFormats, formatISOString } from 'utils'
import CartTableCell from './cartTableCell'
import { Sorting } from 'uiComponents/table'
import { camelToSnakeCase } from 'utils/camelToSnake'
import CartTableFilters from './filters/cartTableFilter'
import { getFiltersFromUrl } from 'uiComponents/filter/filterHelpers'
import StatusLabel from 'orders/orders/commons/orderStatusLabel'
import { useHasFeature } from 'features'
import ConfirmCancelCartDialog from './modals/confirmCancelCartDialog'
import { Button } from '@mui/material'
import CartExportModal from './export/cartExportModal'

const emptyData = [] as any[]
const defaultPageSize = 10

const CartTable = () => {
    const [listCarts, { isFetching, data }] = useLazyListCartsQuery()
    const { accountSlug } = useParams<BaseRouteParams>()
    const navigation = useNavigation()
    const history = useHistory()
    const query = navigation.query()
    const [markAsPaidCartId, setMarkAsPaidCartId] = useState<string | null>(null)
    const initialSort = useMemo(() => ({ prop: '', direction: 'desc' } as Sorting), [])
    const [cartCancellationId, setCartCancellationId] = useState<string | null>(null)
    const [paginationState, setPaginationState] = useState<LoadDataParams | null>(null)
    const [sortingState, setSortingState] = useState<SortProps | null>(null)
    const [isExportModalShown, setIsExportModalShown] = useState(false)
    const canSeeSentEmails = useHasFeature('show_sent_emails')
    const areFiltersEnabled = useHasFeature('show_reservation_filters')
    const canCancelReservations = useHasFeature('can_cancel_reservations')
    const shouldShowOpenStatus = useHasFeature('should_show_open_status')
    const defaultStatusFilter = shouldShowOpenStatus ? 'open,pending,initiated' : 'pending,initiated'
    const reservationExportEnabled = useHasFeature('reservation_export_enabled')

    useEffect(() => {
        const statusFilter = getFiltersFromUrl('reservation_status')
        if (statusFilter !== '') return

        const searchParams = new URLSearchParams(location.search)
        searchParams.set('filter', `reservation_status:${defaultStatusFilter}`)

        history.replace({
            pathname: location.pathname,
            search: searchParams.toString(),
        })
    }, [navigation])

    const dateRangeParams = useMemo(() => {
        const { dateRangeType } = query
        const { from, to } = dateRangeFromQuery(query, 'last30days')
        const dateFilterKey = dateRangeType || 'created_at'

        return {
            date_from: format(from, 'yyyy-MM-dd'),
            date_to: format(to, 'yyyy-MM-dd'),
            filter_by_date: dateFilterKey,
        }
    }, [navigation])

    const statusFilter = useMemo(() => getFiltersFromUrl('reservation_status'), [navigation])
    const locationFilter = useMemo(() => getFiltersFromUrl('location_id'), [navigation])

    const onLoadData = useCallback(
        ({ token, pagination = { pageSize: defaultPageSize }, sort, ...rest }: LoadDataParams) => {
            const orderKey = camelToSnakeCase(sort.prop)
            const ordering = !orderKey ? undefined : sort.direction === 'asc' ? orderKey : `-${orderKey}`

            listCarts({
                accountSlug,
                query: pathUtils.formatSearchParams({
                    cursor: token,
                    page_size: pagination.pageSize || defaultPageSize,
                    search: query.q,
                    ordering,
                    status: statusFilter,
                    locations: locationFilter,
                    ...dateRangeParams,
                }),
            })
            setSortingState(sort)
            setPaginationState({ token, pagination, sort, ...rest })
        },
        [accountSlug, dateRangeParams, statusFilter],
    )

    const refetchData = useCallback(() => {
        if (!paginationState) return
        onLoadData(paginationState)
    }, [paginationState, onLoadData])

    const openSentEmails = useCallback(
        (id: string) => {
            history.push(`/account/${accountSlug}/orders/pending-reservations/${id}/sent_emails`)
        },
        [history, accountSlug],
    )

    const columns = useMemo(
        () =>
            [
                {
                    Header: () => null,
                    id: 'expander',
                    accessor: 'expander',
                    width: '3.5rem',
                    disableSortBy: true,
                    disableHideColumn: true,
                    Cell: ({ row }) => {
                        return (
                            <div {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? (
                                    <FontAwesomeIcon icon={faAngleUp as IconProp} className="opened-icon" />
                                ) : (
                                    <FontAwesomeIcon icon={faAngleDown as IconProp} className="closed-icon" />
                                )}
                            </div>
                        )
                    },
                },
                {
                    accessor: 'location.name',
                    Header: 'Location',
                    width: '7.3rem',
                    disableSortBy: true,
                    Cell: ({ value }) => <div title={value}>{value}</div>,
                },
                {
                    accessor: 'status',
                    Header: 'Status',
                    width: '6rem',
                    Cell: ({ value }) => <StatusLabel status={value} />,
                },
                {
                    accessor: 'referenceId',
                    Header: 'Reservation ID',
                    width: '10rem',
                    disableSortBy: true,
                    Cell: ({ value }) => <span title={value}>{value}</span>,
                },
                {
                    accessor: 'customer.email',
                    Header: 'Email',
                    width: '15.5rem',
                    disableSortBy: true,
                    Cell: ({ value }) => (
                        <InlineEdit id="email" value={value} maskData={true} maskIcon={true} disabled>
                            {value}
                        </InlineEdit>
                    ),
                },
                {
                    accessor: 'quantity',
                    Header: 'Qty',
                    width: '4rem',
                    disableSortBy: true,
                    Cell: ({ row: { original } }) => {
                        return sumBy(
                            original.items.filter((item) => !!item.amount),
                            (cartItem) => cartItem.amount,
                        )
                    },
                },
                {
                    accessor: 'total',
                    Header: 'Total',
                    width: '6rem',
                    disableSortBy: true,
                    Cell: ({ row: { original } }) => {
                        const total = Big(original.totalPriceAfterDiscount).toNumber()
                        return <Money amount={total} accountSlug={accountSlug} />
                    },
                },
                {
                    accessor: 'expiresAt',
                    Header: 'Payment deadline',
                    width: '10.5rem',
                    Cell: ({
                        value,
                        row: {
                            original: { id, timezone },
                        },
                    }) => <PaymentDeadlineCell value={value} cartId={id} timezone={timezone} />,
                },
                {
                    accessor: 'eventDate',
                    Header: 'Visit date',
                    width: '8rem',
                    Cell: ({ value }) => (value ? formatISOString(value, `${DateFormats.LONG_DATE}`) : '-'),
                },
                {
                    accessor: 'startTime',
                    Header: 'Visit time',
                    width: '6.25rem',
                    disableSortBy: true,
                    Cell: ({ value }) => {
                        if (!value) return '-'
                        const [hours, minutes, _seconds] = value.split(':')
                        const formattedTime = `${hours}:${minutes}`
                        return <span title={formattedTime}>{formattedTime}</span>
                    },
                },
                {
                    accessor: 'createdAt',
                    Header: 'Created on',
                    width: '9rem',
                    Cell: ({ value, row: { original } }) =>
                        value
                            ? formatISOString(value, `${DateFormats.LONG_DATE}, ${DateFormats.SHORT_TIME}`, {
                                  timeZone: original.timezone,
                              })
                            : '-',
                },
                {
                    accessor: 'updatedAt',
                    Header: 'Last edit',
                    width: '9rem',
                    disableSortBy: true,
                    Cell: ({ value, row: { original } }) =>
                        value
                            ? formatISOString(value, `${DateFormats.LONG_DATE}, ${DateFormats.SHORT_TIME}`, {
                                  timeZone: original.timezone,
                              })
                            : '-',
                },
                {
                    accessor: 'id',
                    align: 'right',
                    style: {
                        overflow: 'visible',
                    },
                    width: '9.5rem',
                    classNames: '',
                    disableSortBy: true,
                    Cell: ({ value, cell }) => {
                        const { status, urls = [] } = cell.row.original
                        const isReservationViewOnly = ['completed', 'cancelled'].includes(status)
                        const reservationViewOnlyTooltipText = `Reservation ${status}`

                        return (
                            <TooltipWrapper
                                hidden={!isReservationViewOnly}
                                text={reservationViewOnlyTooltipText}
                                tooltipStyle={{ zIndex: 1000 }}
                                delayShow={300}
                            >
                                <OnClickMenu
                                    title="Actions"
                                    kind="action"
                                    secondary
                                    usePortal
                                    disabled={isReservationViewOnly}
                                >
                                    <OnClickMenuItem>
                                        {!urls.find(({ rel }) => rel === 'resume')?.url ? (
                                            <TooltipWrapper text="Payment initiated" className="disabled-dropdown-item">
                                                Edit reservation
                                            </TooltipWrapper>
                                        ) : (
                                            <UnstyledLink
                                                to={pathUtils.populateParams(ORDER_PATHS.fullPaths.cartEditPage, {
                                                    accountSlug,
                                                    id: value,
                                                })}
                                            >
                                                Edit Cart
                                            </UnstyledLink>
                                        )}
                                    </OnClickMenuItem>
                                    <OnClickMenuItem onClick={() => setMarkAsPaidCartId(value)}>
                                        Mark as paid
                                    </OnClickMenuItem>
                                    {canCancelReservations &&
                                        !['completed', 'cancelled'].includes(cell.row.original.status) && (
                                            <OnClickMenuItem onClick={() => setCartCancellationId(value)}>
                                                Cancel reservation
                                            </OnClickMenuItem>
                                        )}
                                    {canSeeSentEmails && (
                                        <OnClickMenuItem onClick={() => openSentEmails(value)}>
                                            Sent emails
                                        </OnClickMenuItem>
                                    )}
                                </OnClickMenu>
                            </TooltipWrapper>
                        )
                    },
                },
            ] as TableColumn<CartSchema>[],
        [accountSlug],
    )

    const hideMarkAsPaidDialog = useCallback(() => {
        setMarkAsPaidCartId(null)
    }, [setMarkAsPaidCartId])

    const hideCancelCartDialog = useCallback(
        (type: 'close' | 'confirm') => () => {
            setCartCancellationId(null)

            if (type === 'confirm') {
                refetchData()
            }
        },
        [refetchData, setCartCancellationId],
    )

    return (
        <div className="cart-table-container">
            {areFiltersEnabled && (
                <div className="cart-table-header">
                    <CartTableFilters />
                    {reservationExportEnabled && (
                        <Button variant="outlined" onClick={() => setIsExportModalShown(true)}>
                            Export
                        </Button>
                    )}
                </div>
            )}
            <ReactTable
                sticky
                noResultsRow
                pagination={{
                    next: data?.next,
                    previous: data?.previous,
                }}
                sort={initialSort}
                expanded={CartTableCell}
                loading={isFetching}
                loadData={onLoadData}
                data={data?.results || emptyData}
                columns={columns}
                storeStateInQuery={false}
                elevation={true}
                tableProps={{ className: 'cart-table' }}
            />
            <MarkCartAsPaidConfirmationDialog
                accountSlug={accountSlug}
                cartId={markAsPaidCartId}
                onConfirm={hideMarkAsPaidDialog}
                onCancel={hideMarkAsPaidDialog}
            />
            <ConfirmCancelCartDialog
                cartId={cartCancellationId}
                onClose={hideCancelCartDialog('close')}
                onConfirm={hideCancelCartDialog('confirm')}
            />
            {isExportModalShown && <CartExportModal onClose={() => setIsExportModalShown(false)} sort={sortingState} />}
        </div>
    )
}

export default CartTable
