import { AuthenticatedHttpService } from 'http/httpService'
import { PricingType, ValidityException } from 'products/articleConfigurationService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'
import { BarcodePoolType, BarcodePoolFormat } from 'venue/bookingCodes/bookingCodesService'
import { ProductsMetaData, Article as FilterArticle } from 'uiComponents/filter/schema'
import { ConfigPayloadField, ConfigResponse } from 'studioAPF/schema'
import { format } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { generateRandomChars } from 'utils'
import { errorParser } from 'utils/errorHandler'
import cleanDeep from 'clean-deep'
import { UpdateArticleStatusResponse } from './types'
import type { IStoreService } from '../middleware/storeService'
var slugify = require('slugify')

export type ArticleBusinessType =
    | 'single_visit_ticket'
    | 'multi_visit_ticket'
    | 'visit_reservation'
    | 'upsell'
    | 'service'
    | 'service_date_change'
    | 'service_fee'
    | 'bundle'
    | 'season_pass'
    | 'membership'
    | 'voucher'
    | 'donation'
    | 'loyalty_card'
    | 'food_and_beverage'

export type ArticleImageType = 'square_image' | 'wide_image'
export type ProductListImageType = ArticleImageType | 'no_image'

export const CMS_KEY_PLACEHOLDER = 'cms.key'

export const nestedProductListQueryFragment = `
  ...productList
  children {
    ...productList
    children {
      ...productList
      children {
        ...productList
        children {
          ...productList
          children {
            ...productList
            children {
              ...productList
              children {
                ...productList
              }
            }
          }
        }
      }
    }
  }
`

function getTitleTextFromTranslations(translations: Translations) {
    return translations.text.en ? translations.text.en : translations.text[Object.keys(translations.text)[0]]
}
export function getCmsSlug(title: string) {
    const readable = slugify(title, {
        replacement: '_',
        remove: /[*+~.()'"!:@]/g,
        lower: true,
    })
    const random = generateRandomChars()
    return `${readable.substring(0, 120)}_${random}`
}

export function getNameFromCmsSlug(slug: string) {
    const lastUnderscoreIndex = slug.lastIndexOf('_')
    if (lastUnderscoreIndex !== -1) {
        slug = slug.substring(0, lastUnderscoreIndex)
    }

    slug = slug.replace(/_/g, ' ')
    slug = slug.charAt(0).toUpperCase() + slug.slice(1)
    return slug
}

export interface BarcodeReservoir {
    id: number
    name: string
    format: BarcodePoolFormat
    poolType: BarcodePoolType
}

export interface BarcodesPoolData {
    barcodePoolType: BarcodePoolType
    barcodePoolName: string | null
    barcodePoolFormat: BarcodePoolFormat | null
    barcodePoolId: string | null
}

export interface InventoryPool {
    poolId: string
    amountUsed: number
    productListId?: string
}

export interface Integration {
    slug: string
    name: string
}

export interface ExternalIdItem {
    integrationType: string | null
    externalId: string | null
}

export interface Translations {
    key: string
    text: {
        [lang: string]: string
    }
}

export interface ArticleData {
    productsListIds: string[] | null
    name: Translations
    description: Translations
    showFrom: Date
    showTo: Date
    validFrom: Date
    validTo: Date
    pricingType: PricingType
    priority: number
    businessType: ArticleBusinessType | null
    displayType: ProductDisplayType
}

export interface ArticleForTimeSlots {
    id: string
    name: string
    numericId: string
    pricingType: PricingType
    checkoutFlow: string[]
}

type ValidationError = 'validity_not_configured' | 'pricing_settings_not_configured' | 'article_must_be_single_use' | 'ouroboros_payment_plans_not_configured'
export type ProductDisplayType = 'REGULAR_TICKET' | 'SUBSCRIPTION'

export type ScanLimitType =
    | 'number_of_scans'
    | 'scan_period_selected_date'
    | 'scan_period_fixed'
    | 'scan_period_1st_scan'

export interface ScanningOptions {
    scanLimitType: ScanLimitType
    maxScans: number | null
    maxScansPerDay: number | null
    ticketDuration: string | null
}

export interface ArticleCrudData {
    id: string
    productsListIds: string[]
    name: Translations
    description: Translations | null
    showFrom: Date | null
    showTo: Date | null
    validFrom: Date | null
    validTo: Date | null
    pricingType: PricingType
    priority: number | null
    barcodePoolId: string | null
    enabled: boolean
    canBeEnabled: boolean
    articleValidationErrors: ValidationError[] | null
    image: string | null
    squareImage: string | null
    imageType: ArticleImageType | null
    usedResources: InventoryPool[]
    redeemable: boolean
    businessType: ArticleBusinessType | null
    numericId: string
    multipleProductLists: boolean | null
    taxConfigurationUuid?: string | null
    externalIds: ExternalIdItem[]
    reservedArticleId: string | null
    reservedArticleName: string | null
    ticketTemplateConfig: string | null
    ticketPrintTemplateConfig: string | null
    displayType: ProductDisplayType
    apfEnabled: boolean | null
    bookingHorizon: number | null
    releaseTime: number | null
    cardOpenByDefault: boolean
    articleOptions: string[]
    minTickets: number | null
    maxTickets: number | null
    dateChangedArticles: string[]
    scanningOptions: ScanningOptions | null
}

export interface Article {
    id: string
}

export interface ArticleListItem {
    id: string
    numericId: string
    priority: number
    name: string
    validityOverrides?: ValidityException[]
    validFrom: string
    validTo: string
    enabled: boolean
    pricingType: PricingType
    internalName: string
    multipleProductLists: boolean
    productListUuids?: string[]
}

export interface OrphanArticle {
    id: string
    numericId: string
    priority: number
    name: string
    validFrom: string
    validTo: string
    enabled: boolean
    pricingType: PricingType
    internalName: string
}

export interface Locale {
    code: string
    name: string
}

export interface LocalesInfo {
    locales: Locale[]
    defaultLocale: string
}

export interface ProductList {
    uuid: string
    name: string
    children: ProductList[]
    enabled: boolean
    hasChildren?: boolean
    childrenType?: 'LIST' | 'ARTICLES' | 'TIME_SLOTS' | 'CART'
    priority: number
    showInCheckout: boolean
    articles: ArticleListItem[]
    locationId: string
    pricingType?: PricingType
    id?: string
    parentId?: string
    isLeaf?: boolean
    title?: {
        key: string
        text: {
            en: string
            de?: string
            fr?: string
            nl?: string
        }
    }
}

export interface ProductListMinimum {
    uuid: string
    name: string
    enabled: boolean
    hasChildren: boolean
    priority: number
    showInCheckout: boolean
}

export interface ProductListMinimumWithId extends ProductListMinimum {
    id: string
}

export type CheckoutFlow = 'undated' | 'dated' | 'rtp' | 'date_change' | 'date_reservation' | 'membership'
export type ConfirmationFlow = 'payment_required' | 'reservation' | 'validity_extension' | 'ruhr_topcard_check'

export type QuantityPickerType = 'select' | 'input' | 'input-inline-checkout'

export interface ProductListData {
    id: string | null
    title: Translations
    description: Translations | null
    timeSlotInfo: Translations | null
    parentId: string | null
    flow: CheckoutFlow | null
    confirmationFlow: ConfirmationFlow | null
    priority: number | null
    enabled: boolean
    image: string | null
    squareImage: string | null
    imageType: ProductListImageType | null
    timeSlotsEnabled: boolean
    canEnableTimeSlots: boolean
    enabledForResellers: boolean
    visibleToAllResellers: boolean
    visibleToSelectedResellers: string[]
    showInNewOrder: boolean
    showInCheckout: boolean
    deeplinkUrl: string
    bookingHorizon: number | null
    releaseTime: number | null
    cardOpenByDefault: boolean
    quantityPickerType: QuantityPickerType | null
    isForGroupBookings: boolean
    isLeaf: boolean
}

export interface AccountProductListsAndLocales {
    productsLists: ProductsMetaData[]
    locales: Locale[]
}

export interface Upsell {
    id: string
    numericId: string
    name: string
    useInEmails: boolean
    emailImage: string
    enabled: boolean
    preEventEmailPriority: number | null
}

export interface ArticleForDiscountRules {
    id: string
    name: string
    numericId: string
    productListUuids: string[]
}

interface DiscountRulesQueryResponse {
    articles: ArticleForDiscountRules[]
    productsLists: ProductList[]
    locales: Locale[]
}

function cleanUpEmptyTranslations(descrObject: Translations | null) {
    let description = cloneDeep(descrObject)
    if (!!description) {
        Object.keys(description.text).forEach((code) => {
            if (!!description && !description.text[code]) {
                delete description.text[code]
            }
        })
    }
    return description
}

function getProductErrorMessage(body: any) {
    if (!body || !body?.error) {
        return 'Oops! Could not save the product, please try again later.'
    }
    if (body.error.message) {
        return body.error.message
    }
    if (body.error.errors) {
        return errorParser(body.error.errors)
    }
    return 'Oops! Could not save the product, please try again later.'
}

export class ArticleService {
    constructor(
        private httpService: AuthenticatedHttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
        private storeService: IStoreService,
    ) {}

    async createArticle(accountSlug: string, data: ArticleData | ArticleCrudData): Promise<Article> {
        const description = cleanUpEmptyTranslations(data.description)
        if (description) {
            const generated = getCmsSlug(getTitleTextFromTranslations(data.name))
            description.key = `article.${generated}_description`
        }

        const payload = {
            ...data,
            description: description,
            showFrom: data.showFrom ? format(data.showFrom, 'yyyy-MM-dd') : null,
            showTo: data.showTo ? format(data.showTo, 'yyyy-MM-dd') : null,
            validFrom: data.validFrom ? format(data.validFrom, 'yyyy-MM-dd') : null,
            validTo: data.validTo ? format(data.validTo, 'yyyy-MM-dd') : null,
        }

        const logEventType: EventType = 'product_created'
        const logEventData: ActionEventData = {
            category: 'products_article',
            payload,
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/articles/`,
            {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            let errorBody = {}
            try {
                errorBody = await response.json()
            } catch {
                this.loggingService.logResponseError(response, logEventType, logEventData)
                throw new Error('Oops! Could not save the product, please try again later.')
            }
            this.loggingService.logError(errorBody, logEventType, logEventData)
            const errorMessage = getProductErrorMessage(errorBody)
            throw new Error(errorMessage)
        }

        response = await this.httpService.fetch(response.headers.get('Location')!)

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to retrieve article')
        }

        const body = await response.json()

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, {
            ...logEventData,
            payload: { ...logEventData.payload, id: body.id },
        })
        return body
    }

    async updateArticle(accountSlug: string, data: ArticleCrudData, id: string): Promise<ArticleCrudData> {
        let nameObject = cloneDeep(data.name)
        if (!data.name.key) {
            nameObject.key = 'article.name'
        }
        const description = cleanUpEmptyTranslations(data.description)
        if (description && description.key === CMS_KEY_PLACEHOLDER) {
            const generated = getCmsSlug(getTitleTextFromTranslations(data.name))
            description.key = `article.${generated}_description`
        }

        const payload = {
            ...data,
            description: description,
            name: nameObject, // temporary hack, later remove this line
            showFrom: data.showFrom ?? null,
            showTo: data.showTo ?? null,
            validFrom: data.validFrom ?? null,
            validTo: data.validTo ?? null,
            productsListIds: !data.productsListIds ? [] : data.productsListIds,
        }

        const logEventType: EventType = 'product_updated'
        const logEventData: ActionEventData = {
            category: 'products_article',
            payload,
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/articles/${id}/`,
            {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            let errorBody = {}
            try {
                errorBody = await response.json()
            } catch {
                this.loggingService.logResponseError(response, logEventType, logEventData)
                throw new Error('Oops! Could not save the product, please try again later.')
            }
            this.loggingService.logError(errorBody, logEventType, logEventData)
            const errorMessage = getProductErrorMessage(errorBody)
            throw new Error(errorMessage)
        }

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async duplicateArticle(accountSlug: string, id: string): Promise<string> {
        const logEventType: EventType = 'product_duplicated'
        const logEventData: ActionEventData = {
            category: 'products_article',
            payload: { id },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/articles/${id}/duplicate/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            const error = await response.json()
            this.loggingService.logError(error, logEventType, logEventData)
            if (error.error.code) {
                throw new Error(error.error.code)
            } else {
                throw new Error('Unable to create product list')
            }
        }

        const pathParts = response.headers.get('Location')!.split('/')
        const productId = pathParts[pathParts.length - 2]

        response = await this.httpService.fetch(response.headers.get('Location')!)

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to retrieve article')
        }

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, logEventData)
        return productId
    }

    async updateArticlePriority(account: string, id: string, priority: number): Promise<void> {
        const query = `mutation ArticlePriority($account: String, $id: String, $priority: Int) {
      updateArticlePriority(account: $account, id: $id, priority: $priority) {
        ok
        errorCode
      }
    }`
        const body = JSON.stringify({
            query,
            variables: { account, id, priority },
        })

        const logEventType: EventType = 'product_priority_updated'
        const logEventData: ActionEventData = {
            category: 'products_article',
            payload: { id, priority },
        }

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (!payload.data.updateArticlePriority.ok) {
            this.loggingService.logError(payload, logEventType, logEventData)
            throw new Error(`Backoffice has returned error: ${payload}`)
        }

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, logEventData)
    }

    async updateArticleStatus(account: string, id: string, enabled: boolean): Promise<UpdateArticleStatusResponse> {
        const query = `mutation UpdateArticleStatus($account: String, $id: String, $enabled: Boolean) {
      updateArticleStatus(account: $account, id: $id, enabled: $enabled) {
        ok
        errorCode
      }
    }`

        const body = JSON.stringify({
            operationName: 'UpdateArticleStatus',
            query,
            variables: { account, id, enabled },
        })

        const logEventType: EventType = 'product_status_updated'
        const logEventData: ActionEventData = {
            category: 'products_article',
            payload: { id, enabled },
        }

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        const payload = await response.json()
        if (payload.data?.updateArticleStatus?.ok === false) {
            this.loggingService.logError(payload, logEventType, logEventData)
            throw new Error(payload.data?.updateArticleStatus?.errorCode)
        }

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, logEventData)

        return payload
    }

    async fetchArticle(accountSlug: string, id: string): Promise<ArticleCrudData> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/articles/${id}/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch article')
        }
        return await response.json()
    }

    async fetchArticleCategoryIds(accountSlug: string, id: string): Promise<string[]> {
        const query = `query Article($accountSlug: ID, $id: ID) {
      account(slug: $accountSlug) {
        article(id: $id) {
          productListUuids
        }
      }
    }`

        const body = JSON.stringify({
            operationName: 'Article',
            query,
            variables: {
                accountSlug,
                id: id,
            },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Failed fetching articles: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.account.article.productListUuids
    }

    async archiveArticle(accountSlug: string, id: string): Promise<void> {
        const logEventType: EventType = 'product_archived'
        const logEventData: ActionEventData = {
            category: 'products_article',
            payload: { id },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/articles/${id}/`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to archive article')
        }

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, logEventData)
    }

    async listArticles(accountSlug: string, pricingTypes: PricingType[] | null = null): Promise<ArticleListItem[]> {
        const query = `query Articles($accountSlug: ID, $pricingType: [String]) {
      account(slug: $accountSlug) {
        articles(pricingType: $pricingType) {
          id,
          numericId,
          priority,
          name,
          validFrom,
          validTo,
          enabled,
          pricingType,
          internalName,
          productListUuids,
          multipleProductLists,
          validityOverrides {
            id
          }
        }
      }
    }`

        const body = JSON.stringify({
            operationName: 'Articles',
            query,
            variables: {
                accountSlug,
                pricingType: pricingTypes || null,
            },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Failed fetching articles: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.account.articles
    }

    async listFlatArticles(accountSlug: string, pricingTypes: PricingType[] | null = null): Promise<ArticleListItem[]> {
        const query = `query Articles($accountSlug: ID, $pricingType: [String]) {
      account(slug: $accountSlug) {
        articles(pricingType: $pricingType) {
          id,
          numericId,
          priority,
          name,
          validFrom,
          validTo,
          enabled,
          pricingType,
          internalName,
          multipleProductLists,
        }
      }
    }`

        const body = JSON.stringify({
            operationName: 'Articles',
            query,
            variables: {
                accountSlug,
                pricingType: pricingTypes || null,
            },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Failed fetching articles: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.account.articles
    }

    async listOrphanArticles(accountSlug: string): Promise<OrphanArticle[]> {
        const query = `query Articles($accountSlug: ID) {
      account(slug: $accountSlug) {
        articles(inCategory: false) {
          id,
          numericId,
          priority,
          name,
          validFrom,
          validTo,
          enabled,
          pricingType,
          internalName
        }
      }
    }`

        const body = JSON.stringify({
            operationName: 'Articles',
            query,
            variables: {
                accountSlug,
            },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Failed fetching articles: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.account.articles
    }

    listProductList = async ({
        accountSlug,
        parentId,
    }: {
        accountSlug: string
        parentId?: string
    }): Promise<ProductList[]> => {
        const queryParams = new URLSearchParams(cleanDeep({ parent_id: parentId })).toString()

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/products_lists?${queryParams}`,
        )

        const data = await response.json()

        return data?.productsLists
    }

    async getProductLists(accountSlug: string): Promise<ProductList[]> {
        const query = `fragment productList on ProductsListType {
      uuid,
      name,
      enabled,
      hasChildren,
      priority,
      articles {
        id,
        numericId,
        name,
        priority,
        validFrom,
        validTo,
        enabled,
        pricingType,
        multipleProductLists
      }
    }
    query ProductLists($accountSlug: ID) {
      account(slug: $accountSlug) {
        productsLists {
          ${nestedProductListQueryFragment}
        }
      }
    }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductLists',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Failed fetching product lists: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.productsLists
    }

    async getProductListsWithArticles(accountSlug: string): Promise<ProductList[]> {
        const query = `fragment productList on ProductsListType {
      uuid,
      name,
      enabled,
      hasChildren,
      articles {
        id
      }
    }
    query ProductLists($accountSlug: ID) {
      account(slug: $accountSlug) {
        productsLists {
          ${nestedProductListQueryFragment}
        }
      }
    }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductLists',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.productsLists
    }

    async getProductListMinimalDetails(accountSlug: string): Promise<ProductListMinimum[]> {
        const query = `query ProductLists($accountSlug: ID) {
      account(slug: $accountSlug) {
        productsLists {
          uuid
          name
          enabled
          hasChildren
          priority
          showInCheckout,
          childrenType
        }
      }
    }`

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductLists',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.productsLists
    }

    async getProductListDescendants(accountSlug: string, uuid: string): Promise<ProductList> {
        const query = `fragment productList on ProductsListType {
      uuid,
      name,
      enabled,
      hasChildren,
      childrenType,
      priority,
      showInCheckout,
      articles {
        id
        numericId,
        name,
        priority,
        validFrom,
        validTo,
        enabled,
        pricingType,
        multipleProductLists,
        productListUuids
      }
    }
    query ProductList($accountSlug: ID, $uuid: ID!) {
      account(slug: $accountSlug) {
        productsList(uuid: $uuid) {
          ${nestedProductListQueryFragment}
        }
      }
    }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductList',
                query: query,
                variables: { accountSlug, uuid },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.productsList
    }

    async getProductListDescendantsOneLayer(accountSlug: string, uuid: string): Promise<ProductList> {
        const query = `fragment productList on ProductsListType {
                uuid,
                name,
                enabled,
                hasChildren,
                childrenType,
                priority,
                showInCheckout,
                articles {
                    id
                    numericId,
                    name,
                    priority,
                    validFrom,
                    validTo,
                    enabled,
                    pricingType,
                    multipleProductLists,
                    productListUuids
                    bundle
                }
            }
            query ProductList($accountSlug: ID, $uuid: ID!) {
            account(slug: $accountSlug) {
                productsList(uuid: $uuid) {
                    ...productList
                    children {
                        ...productList
                    }
                }
            }
            }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductList',
                query: query,
                variables: { accountSlug, uuid },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.productsList
    }

    async getProductListsAndLocales(accountSlug: string): Promise<AccountProductListsAndLocales> {
        const query = `fragment productList on ProductsListType {
      uuid,
      name,
      articles {
        id,
        name
      }
    }
    query ProductLists($accountSlug: ID) {
      account(slug: $accountSlug) {
        locales {
          code
          name
        }
        productsLists {
          ${nestedProductListQueryFragment}
        }
      }
    }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductLists',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account
    }

    async getMinimalSortedArticlesList(accountSlug: string): Promise<FilterArticle[]> {
        const query = `
      query ArticlesList($accountSlug: ID) {
        account(slug: $accountSlug) {
            articles(sortBy: name, sortDirection: asc) {
                id,
                name,
                numericId,
                apfEnabled,
            }
        }
    }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ArticlesList',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.articles
    }

    async getUpsellsList(accountSlug: string): Promise<Upsell[]> {
        const query = `
      query ArticlesList($accountSlug: ID) {
        account(slug: $accountSlug) {
            articles(sortBy: name, sortDirection: asc, pricingType: "upsell") {
                id,
                name,
                numericId,
                useInEmails,
                emailImage,
                preEventEmailPriority,
                enabled
            }
        }
    }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ArticlesList',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.articles
    }

    async getSortedArticlesWithCheckoutFlowList(accountSlug: string): Promise<ArticleForTimeSlots[]> {
        const query = `
      query ArticlesList($accountSlug: ID) {
        account(slug: $accountSlug) {
            articles(sortBy: name, sortDirection: asc) {
                id,
                name,
                numericId,
                pricingType,
                checkoutFlow,
            }
        }
    }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ArticlesList',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.articles
    }

    async getMetadataForDiscountRules(accountSlug: string): Promise<DiscountRulesQueryResponse> {
        const query = `fragment productList on ProductsListType {
      uuid,
      name,
      enabled,
      hasChildren,
      articles {
        id,
        numericId,
        name,
      }
    }
    query ProductLists($accountSlug: ID) {
      account(slug: $accountSlug) {
        productsLists {
          ${nestedProductListQueryFragment}
        }
        articles(sortBy: name, sortDirection: asc) {
          id
          name
          numericId
          productListUuids
        }
        locales{
          code
          name
        }
      }
    }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductLists',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account
    }

    async createProductList(accountSlug: string, data: ProductListData): Promise<{ id: string }> {
        const title = cloneDeep(data.title)
        const generated = getCmsSlug(getTitleTextFromTranslations(data.title))
        const description = cleanUpEmptyTranslations(data.description)
        const timeSlotInfo = cleanUpEmptyTranslations(data.timeSlotInfo)

        title.key = `category.${generated}_name`
        if (description) {
            description.key = `category.${generated}_description`
        }
        if (timeSlotInfo) {
            timeSlotInfo.key = `category.${generated}_time_slot_info`
        }

        const logEventType: EventType = 'product_list_created'
        const logEventData: ActionEventData = {
            category: 'products_product_list',
            payload: { ...data, title, description, timeSlotInfo },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/products_lists/`,
            {
                method: 'POST',
                body: JSON.stringify({
                    ...data,
                    title: title,
                    description,
                    timeSlotInfo,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            const error = await response.json()
            this.loggingService.logError(error, logEventType, logEventData)
            if (error.error.code) {
                throw new Error(error.error.code)
            } else {
                throw new Error('Unable to create product list')
            }
        }

        response = await this.httpService.fetch(response.headers.get('Location')!)
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to retrieve product list')
        }

        this.storeService.invalidatePLCache()
        const body = await response.json()
        this.loggingService.logAction(logEventType, {
            ...logEventData,
            payload: { ...logEventData.payload, id: body.id },
        })
        return body
    }

    async updateProductList(accountSlug: string, data: ProductListData, id: string): Promise<ProductListData> {
        const title = cloneDeep(data.title)
        const generated = getCmsSlug(getTitleTextFromTranslations(data.title))
        const description = cleanUpEmptyTranslations(data.description)
        const timeSlotInfo = cleanUpEmptyTranslations(data.timeSlotInfo)

        if (title.key === CMS_KEY_PLACEHOLDER) {
            title.key = `category.${generated}_name`
        }
        if (description && description.key === CMS_KEY_PLACEHOLDER) {
            description.key = `category.${generated}_description`
        }
        if (timeSlotInfo?.key === CMS_KEY_PLACEHOLDER) {
            timeSlotInfo.key = `category.${generated}_time_slot_info`
        }

        const logEventType: EventType = 'product_list_updated'
        const logEventData: ActionEventData = {
            category: 'products_product_list',
            payload: { ...data, title, description, timeSlotInfo },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/products_lists/${id}/`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    ...data,
                    title: title,
                    description: description,
                    timeSlotInfo: timeSlotInfo,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            const error = await response.json()
            this.loggingService.logError(error, logEventType, logEventData)
            if (error.error.code) {
                throw new Error(error.error.code)
            } else {
                throw new Error('Unable to create product list')
            }
        }

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async fetchProductList(accountSlug: string, id: string): Promise<ProductListData> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/products_lists/${id}/`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error('Unable to fetch product list')
        }

        return await response.json()
    }

    async archivePoductList(accountSlug: string, id: string): Promise<void> {
        const logEventType: EventType = 'product_list_archived'
        const logEventData: ActionEventData = {
            category: 'products_product_list',
            payload: { id },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/products_lists/${id}/`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to archive product list')
        }

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getProductListDetails(accountSlug: string, uuid: string): Promise<ProductList> {
        const query = `query ProductList($accountSlug: ID, $uuid: ID!) {
      account(slug: $accountSlug) {
        productsList (uuid: $uuid) {
          uuid,
          name,
          enabled,
          locationId,
          priority,
          childrenType
          articles {
            id,
            name,
            multipleProductLists,
            pricingType
            productListUuids
          }
        }
      }
    }`
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductList',
                query: query,
                variables: { accountSlug, uuid },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.productsList
    }

    async getProductListsNames(accountSlug: string, uuids: string[]): Promise<{ name: string }[]> {
        const query = `query ProductList($accountSlug: ID, $uuids: [ID!]) {
      account(slug: $accountSlug) {
        productsListsByUuids(uuids: $uuids) {
          name
        }
      }
    }
    `
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductList',
                query: query,
                variables: { accountSlug, uuids },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.productsListsByUuids
    }

    async updateProductListStatusAndPriority(
        account: string,
        id: string,
        enabled: boolean,
        priority: number,
    ): Promise<void> {
        const query = `mutation UpdateProductListStatusAndPriority
      ($account: String, $id: String, $enabled: Boolean, $priority: Int) {
      updateProductListStatusAndPriority(account: $account, id: $id, enabled: $enabled, priority: $priority) {
        ok
        errorCode
      }
    }`

        const logEventType: EventType = 'product_list_status_or_priority_updated'
        const logEventData: ActionEventData = {
            category: 'products_product_list',
            payload: { id, enabled, priority },
        }

        const body = JSON.stringify({
            operationName: 'UpdateProductListStatusAndPriority',
            query,
            variables: { account, id, enabled, priority },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (!payload.data.updateProductListStatusAndPriority.ok) {
            this.loggingService.logError(payload, logEventType, logEventData)
            throw new Error(`Backoffice has returned error: ${payload}`)
        }

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getAccountLocales(slug: string): Promise<LocalesInfo> {
        const query = `query AccountLocales($slug: ID) {
      account(slug: $slug) {
        locales{
          code
          name
        }
        defaultLocale
      }
    }`

        const body = JSON.stringify({
            operationName: 'AccountLocales',
            query,
            variables: { slug },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()

        if (payload.errors) {
            throw new Error(`Backoffice has returned an error: ${payload.errors[0].message}`)
        }
        return payload.data.account
    }

    async getBarcodeReservoirs(accountSlug: string): Promise<BarcodeReservoir[]> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/barcode_reservoirs/`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        return await response.json()
    }

    async getIntegrationsList(): Promise<Integration[]> {
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}api/v1/integration_types/`, {
            method: 'GET',
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        return await response.json()
    }

    async getAPFConfig(accountSlug: string): Promise<ConfigResponse> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/apf_config/`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        return await response.json()
    }

    async updateAPFConfig(
        accountSlug: string,
        fields: ConfigPayloadField[],
        enabledArticles: string[],
        disabledArticles: string[],
    ): Promise<void> {
        const logEventType: EventType = 'apf_config_updated'
        const logEventData: ActionEventData = {
            category: 'apf_studio',
            payload: {
                fields,
                enable_apf_for_articles: enabledArticles,
                disable_apf_for_articles: disabledArticles,
            },
        }
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/apf_config/`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    fields,
                    enable_apf_for_articles: enabledArticles,
                    disable_apf_for_articles: disabledArticles,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        this.storeService.invalidatePLCache()
        this.loggingService.logAction(logEventType, logEventData)
    }
}
