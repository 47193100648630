import * as React from 'react'
import Svg from 'svg'
import checkIcon from 'uiComponents/input/checkIcon.svg'
import bigTickIcon from 'uiComponents/input/bigTick.svg'
import classNames from 'classnames'
import './checkbox.scss'

export interface CheckboxProps {
    indeterminateState?: boolean
    markFocus?: boolean
    bordered?: boolean
    innerRef?: any
    disabled?: boolean
}

export const Checkbox = ({
    innerRef,
    style,
    className,
    ...inputProps
}: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>) => (
    <div className={classNames('checkbox-container', className)} style={style}>
        <div className="checkbox-wrapper">
            <input
                {...inputProps}
                type="checkbox"
                ref={innerRef}
                className={classNames('checkbox-input', {
                    'mark-focus': inputProps.markFocus,
                    bordered: inputProps.bordered,
                    disabled: inputProps.disabled,
                })}
                onChange={inputProps.onChange ? inputProps.onChange : () => {}}
            />
            {inputProps.indeterminateState && <span className="dash-mark">&ndash;</span>}
            {!inputProps.indeterminateState && <Svg className="check-mark" src={checkIcon} />}
        </div>
    </div>
)

export const BigCheckbox = ({
    innerRef,
    style,
    className,
    ...inputProps
}: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>) => (
    <div className={classNames('checkbox-container', className)} style={style}>
        <div className="checkbox-wrapper">
            <input type="checkbox" className="checkbox-input big" {...inputProps} ref={innerRef} />
            <Svg className="check-mark big" src={bigTickIcon} />
        </div>
    </div>
)
